import React from 'react';
import './wizardButtonRow.scss';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@itwin/itwinui-react';

export interface WizardButtonRowProps {
  cancelHandle: () => void;
  skipHandle?: () => void;
  backHandle?: (...args: any) => void;
  skipTooltip?: string;
  primaryButtonHandle: any;
  primaryButtonLabel: string;
  primaryDisabled: boolean;
  skipHandleDisabled?: boolean;
}

export const WizardButtonRow = (props: WizardButtonRowProps) => {
  const {
    backHandle,
    cancelHandle,
    skipHandle,
    skipTooltip,
    primaryButtonHandle,
    primaryButtonLabel,
    primaryDisabled,
    skipHandleDisabled,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="wizard-button-row">
      {backHandle && (
        <div className="wizard-button">
          <Tooltip content={t('BackButton_ToolTip')}>
            <div>
              <Button onClick={backHandle} data-testid={'wizard-back-button'}>
                {t('Back_Button_Label')}
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
      <div className="wizard-button">
        <Tooltip content={t('NextButton_ToolTip')}>
          <div>
            <Button
              styleType="cta"
              onClick={primaryButtonHandle}
              disabled={primaryDisabled}
            >
              {primaryButtonLabel}
            </Button>
          </div>
        </Tooltip>
      </div>
      {skipHandle && skipTooltip != null && (
        <div className="wizard-button">
          <Tooltip content={t('SkipButton_ToolTip')}>
            <div>
              <Button
                onClick={skipHandle}
                disabled={skipHandleDisabled}
                data-testid={'wizard-skip-button'}
              >
                {t('SkipBtn_Label')}
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
      <div className="wizard-button">
        <Tooltip content={t('CancelButton_ToolTip')}>
          <div>
            <Button onClick={cancelHandle}>{t('CancelBtn_Label')}</Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

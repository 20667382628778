import React, {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
} from 'react';
import { useBentleyContext } from '../bentleyContext/bentleyContext';
import { ResponseData } from '../../hooks/useDataApi/requestData';
import { useGetSpatialRoot } from '../../hooks/useGetSpatialRoot/useGetSpatialRoot';
import { SpatialRoot } from '../../entities/spatialRootSetting';

export interface ISpatialRootsContext {
  spatialRoots: SpatialRoot[];
  areSpatialRootsLoading: boolean;
  spatialRootsError: any;
  getSpatialRoots: (
    projectId: string,
    iModelId: string
  ) => Promise<ResponseData<SpatialRoot[]>>;
}

export const SpatialRootsContext = createContext<ISpatialRootsContext>({
  spatialRoots: [],
  areSpatialRootsLoading: true,
  spatialRootsError: {},
  getSpatialRoots: async (projectId: string, iModelId: string) => {
    return {} as ResponseData<SpatialRoot[]>;
  },
});

export interface SpatialRootsContextProviderProps
  extends Omit<ProviderProps<ISpatialRootsContext>, 'value'> {}

export const SpatialRootsContextProvider = ({
  ...props
}: SpatialRootsContextProviderProps) => {
  const { projectId, iModelId } = useBentleyContext();

  const [
    spatialRoots,
    areSpatialRootsLodaing,
    spatialRootsError,
    getSpatialRoot,
  ] = useGetSpatialRoot();

  useEffect(() => {
    const getSpatialRootsAsync = async () => {
      if (projectId !== undefined && iModelId !== undefined) {
        await getSpatialRoot(projectId, iModelId);
      }
    };

    getSpatialRootsAsync();
  }, [projectId, iModelId]);

  return (
    <SpatialRootsContext.Provider
      value={{
        spatialRoots: spatialRoots,
        areSpatialRootsLoading: areSpatialRootsLodaing,
        spatialRootsError: spatialRootsError,
        getSpatialRoots: getSpatialRoot,
      }}
      {...props}
    />
  );
};

export const useSpatialRootsContext = () => {
  const context = useContext(SpatialRootsContext);
  if (context == null) {
    throw new Error('useSpatialRootsContext must be used inside provider');
  }
  return context;
};

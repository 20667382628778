import { iModelHubAppId } from '../../constants/constants';
import {
  productSettingsJobsNamespace,
  JobDefinition,
  ApiVersion,
} from '../../entities/jobDefinition/jobDefinition';
import { useCallback } from 'react';
import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { useGetJobSchedule } from '../useGetJobSchedule/useGetJobSchedule';
import { useLogger } from '../../context/loggerContext/loggerContext';
import { useDeleteJobSchedule } from '../useDeleteJobSchedule/useDeleteJobSchedule';
import { useDeleteLocks } from '../useDeleteLock/useDeleteLock';
import { useDeleteBriefcasesByDocumentMapping } from '../useDeleteBriefcasesByDocumentMapping/useDeleteBriefcasesByDocumentMapping';
import { useGetInputFiles } from '../useGetInputFiles/useGetInputFiles';
import { useDeleteFileFromImodel } from '../useDeleteFileFromImodel/useDeleteFileFromImodel';
import { useFlagConnectionForUnmap } from './useFlagConnectionForUnmap';
import { useRunJob } from '../useRunJob/useRunJob';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useDeleteJob = (
  projectId: string,
  iModelId: string
): [
  null,
  boolean,
  any,
  (
    job: JobDefinition,
    deleteModels: boolean,
    deleteBriefcases?: boolean
  ) => Promise<ResponseData<any>>
] => {
  const { buddiUrls } = useBuddiContext();
  const { logFeature } = useUsageLogger();
  const { releaseLockOnDelete } = useFeatureToggleContext();
  const { logError } = useLogger();

  const useDataSettings = { initialData: null, initialIsLoading: false };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);
  const [, , , getJobSchedule] = useGetJobSchedule(projectId, iModelId);
  const [, , , deleteLocks] = useDeleteLocks(iModelId, projectId);
  const [, , , deleteJobSchedule] = useDeleteJobSchedule(projectId, iModelId);
  const [cleanUpBriefcases] = useDeleteBriefcasesByDocumentMapping(
    projectId,
    iModelId
  );

  const [, , , fetchInputFiles] = useGetInputFiles(projectId);
  const [, deleteFileFromImodel] = useDeleteFileFromImodel(projectId, iModelId);
  const [, markForDelete] = useFlagConnectionForUnmap(projectId, iModelId);
  const [, , , runJob] = useRunJob(projectId, iModelId);

  const deleteJobV1 = useCallback(
    async (
      job: JobDefinition,
      deleteModels: boolean,
      deleteBriefcases: boolean
    ) => {
      if (deleteModels) {
        for (const file of job.files) {
          await deleteFileFromImodel(file, job);
        }
      }

      const requestData: RequestData<any> = {
        url: ' /api/ProductSettings/DeleteJobDefinition',
        // user: user!,
        method: 'DELETE',
        body: JSON.stringify({
          projectId,
          iModelId,
          applicationId: iModelHubAppId,
          namespace: productSettingsJobsNamespace,
          name: job.uniqueSettingName,
        }),
      };

      const scheduleResponse = await getJobSchedule(job);
      if (scheduleResponse && !scheduleResponse.error) {
        try {
          await deleteJobSchedule(job.id);
        } catch (e) {
          logError(
            'Could not delete job schedule after deleting job: {error}',
            [e]
          );
        }
      }
      if (releaseLockOnDelete && deleteBriefcases) {
        try {
          await deleteLocks(job);
          await cleanUpBriefcases(
            job.files.map(f => f.file.id),
            job.userId as string
          );
        } catch (e) {
          logError('Could not delete locks: {error}', [e]);
        }
      }

      logFeature(Features.DeleteJob, [
        { name: 'jobDefinitionId', value: job.id },
        { name: 'version', value: 'v1' },
      ]);
      return fetchData(requestData);
    },
    [
      projectId,
      iModelId,
      getJobSchedule,
      releaseLockOnDelete,
      logFeature,
      fetchData,
      deleteFileFromImodel,
      deleteJobSchedule,
      logError,
      deleteLocks,
      cleanUpBriefcases,
    ]
  );

  const deleteJobV2 = useCallback(
    async (job: JobDefinition, deleteModels: boolean) => {
      if (deleteModels) {
        const response = await markForDelete(job.id);
        await runJob(job, false, false);
        return response;
      } else {
        const requestData: RequestData<null> = {
          url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${job.id}')?contextId=${projectId}&iModelId=${iModelId}`,
          method: 'DELETE',
        };

        if (releaseLockOnDelete) {
          try {
            await deleteLocks(job);
            const inputFiles = await fetchInputFiles(job);
            if (inputFiles != null && inputFiles.data != null) {
              await cleanUpBriefcases(
                inputFiles?.data?.map(f => f.file.id),
                job.userId as string
              );
            }
          } catch (e) {
            logError('Could not delete locks: {error}', [e]);
          }
        }

        logFeature(Features.DeleteJob, [
          { name: 'jobDefinitionId', value: job.id },
          { name: 'version', value: 'v2' },
        ]);
        return fetchData(requestData);
      }
    },
    [
      markForDelete,
      runJob,
      buddiUrls,
      projectId,
      iModelId,
      releaseLockOnDelete,
      logFeature,
      fetchData,
      deleteLocks,
      fetchInputFiles,
      cleanUpBriefcases,
      logError,
    ]
  );

  const deleteJob = async (
    job: JobDefinition,
    deleteModels: boolean,
    deleteBriefcases = true
  ) =>
    job.apiVersion === ApiVersion.v2
      ? deleteJobV2(job, deleteModels)
      : deleteJobV1(job, deleteModels, deleteBriefcases);

  return [data, isLoading, error, deleteJob];
};

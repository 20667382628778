import React, { useState, useEffect } from 'react';
import {
  WizardButtonRow,
  WizardButtonRowProps,
} from '../wizardButtonRow/wizardButtonRow';
import { RepositoryTile } from '../../radioTile/radioTile';
import { RepositoryType, Repository } from '../../../entities/repository';
import './datasourceSelectionStep.scss';
import { useTranslation } from 'react-i18next';
import { PwdiConnection } from '../../pwdiConnection/pwdiConnection';
import last from 'lodash/last';
import { useFeatureToggleContext } from '../../../context/featureToggleContext/featureToggleContext';
import { RecentPwdiConnection } from '../../pwdiConnection/recentPwdiConnections';
import { useRecentPwdiConnections } from '../../../hooks/useRecentPwdiConnections/useRecentPwdiConnections';
import { ExecutionStrategy } from '../../../entities/jobDefinition/jobDefinitionDTOv2';
import { LabeledToggle } from '../../labeledToggle/labeledToggle';
import { Text, RadioTileGroup } from '@itwin/itwinui-react';
import {
  Pw365Connection,
  Pw365Connections,
} from '../../pw365Connections/pw365Connections';

export interface DataSourceSelectionStepProps {
  projectId: string;
  executionStrategy: ExecutionStrategy;
  setExecutionStrategy: (executionStrategy: ExecutionStrategy) => void;
  predefinedRepositoryType?: RepositoryType;
}

export const DataSourceSelectionStep = (
  props: DataSourceSelectionStepProps & WizardButtonRowProps
) => {
  const {
    backHandle,
    cancelHandle,
    primaryButtonLabel,
    primaryButtonHandle,
    projectId,
    executionStrategy,
    setExecutionStrategy,
    predefinedRepositoryType = null,
  } = props;
  const { t } = useTranslation();
  const {
    projectWise,
    projectWiseLegacy,
    hostNameInput,
    fileBridgeAssignment,
    autoAffinity,
    pw365,
  } = useFeatureToggleContext();

  const [selectedTile, selectTile] = useState<RepositoryType | null>(
    predefinedRepositoryType
  );
  const [repository, setRepository] = useState<Repository | null>(null);
  const [isLoading, setIsDataloading] = useState<boolean>(false);
  const [isRecentConnectionSelected, setIsRecentConnectionSelected] =
    useState<boolean>(false);

  const toggleTile = (key: RepositoryType) => () => {
    if (
      (key === RepositoryType.PWDI || key === RepositoryType.PWDI_LEGACY) &&
      isRecentConnectionSelected &&
      repository
    ) {
      setRepo(repository.location, key, repository?.hostName);
    }

    selectTile(selectedTile !== key ? key : null);
  };

  const setRepo = (
    location: string,
    repoType: RepositoryType,
    hostName?: string
  ) => {
    if (repoType === RepositoryType.PROJECTSHARE) {
      const repository: Repository = {
        id: location,
        location: location,
        type: repoType,
      };
      setRepository(repository);
    } else if (
      (repoType === RepositoryType.PWDI ||
        repoType === RepositoryType.PWDI_LEGACY ||
        repoType === RepositoryType.PW365) &&
      location !== ''
    ) {
      const id = last(location.split('/'))!;
      const repository: Repository = {
        id: id,
        location: location,
        hostName: hostName,
        type: repoType,
      };
      setRepository(repository);
    } else {
      setRepository(null);
    }
  };

  const [currentConnection, setCurrentConnection] = useState<
    RecentPwdiConnection | undefined
  >(undefined);

  const [, , , addConnectionToLocalStorage] = useRecentPwdiConnections();
  const [selectedConnection, setSelectedConnection] =
    useState<Pw365Connection>();

  const isPw365Enabled =
    pw365 || predefinedRepositoryType === RepositoryType.PW365;

  return (
    <>
      <div
        className="create-connection-container"
        onWheel={() => {
          const a = document.activeElement as HTMLElement;
          a.blur();
        }}
      >
        {!predefinedRepositoryType && (
          <>
            <Text variant="subheading" as="h3">
              {t('AvailableDatasources_Title')}
            </Text>
            <div className="create-connection-tile-container">
              <RadioTileGroup>
                <RepositoryTile
                  repositoryType={RepositoryType.PROJECTSHARE}
                  selectedRepository={selectedTile}
                  onClick={() => {
                    toggleTile(RepositoryType.PROJECTSHARE)();
                    setRepo(projectId, RepositoryType.PROJECTSHARE);
                    setExecutionStrategy(ExecutionStrategy.NonAffinity);
                  }}
                  data-testid="projectshare-tile"
                />
                {projectWise && (
                  <RepositoryTile
                    repositoryType={RepositoryType.PWDI}
                    selectedRepository={selectedTile}
                    onClick={() => {
                      toggleTile(RepositoryType.PWDI)();
                      setExecutionStrategy(ExecutionStrategy.NonAffinity);
                    }}
                    data-testid="projectWise-tile"
                  />
                )}
                {projectWiseLegacy && (
                  <RepositoryTile
                    repositoryType={RepositoryType.PWDI_LEGACY}
                    selectedRepository={selectedTile}
                    onClick={() => {
                      toggleTile(RepositoryType.PWDI_LEGACY)();
                      setExecutionStrategy(ExecutionStrategy.NonAffinity);
                    }}
                    data-testid="projectWiseLegacy-tile"
                  />
                )}
                {isPw365Enabled && (
                  <RepositoryTile
                    repositoryType={RepositoryType.PW365}
                    selectedRepository={selectedTile}
                    onClick={() => {
                      toggleTile(RepositoryType.PW365)();
                      setExecutionStrategy(ExecutionStrategy.NonAffinity);
                    }}
                    data-testid={'projectWise365-tile'}
                  />
                )}
              </RadioTileGroup>
            </div>
          </>
        )}
        {/* Obsolete */}
        {fileBridgeAssignment && false && (
          <LabeledToggle
            title={t('PortalAffinityToggle_Title')}
            explanation={t('PortalAffinityToggle_Description')}
            onChange={() => {
              setExecutionStrategy(ExecutionStrategy.NonAffinity);
            }}
            isOn={executionStrategy === ExecutionStrategy.ExistingAffinity}
          />
        )}
        {/*  */}
        {autoAffinity &&
          (selectedTile === RepositoryType.PWDI ||
            selectedTile === RepositoryType.PWDI_LEGACY) && (
            <LabeledToggle
              title={t('AffinityToggle_Title')}
              explanation={t('AffinityToggle_Description')}
              onChange={() => {
                setExecutionStrategy(ExecutionStrategy.NonAffinity);
              }}
              isOn={executionStrategy === ExecutionStrategy.Affinity}
            />
          )}
        {(selectedTile === RepositoryType.PWDI ||
          selectedTile === RepositoryType.PWDI_LEGACY) && (
          <>
            <div className="pwdi-connection-inputs">
              <PwdiConnection
                repoType={selectedTile}
                onDataLocationChange={(
                  location: string,
                  currentConnection?: RecentPwdiConnection,
                  hostName?: string
                ) => {
                  setRepo(location, selectedTile, hostName);
                  if (currentConnection) {
                    setCurrentConnection(currentConnection);
                  }
                }}
                onRecentConnectionSelection={(
                  recentConnection?: RecentPwdiConnection
                ) => {
                  if (recentConnection) {
                    setRepo(
                      `${recentConnection.serverUrl}/ws/v${recentConnection.datasource.wsgVersion}/repositories/${recentConnection.datasource.id}/PW_WSG/Project/${recentConnection.workArea.id}`,
                      selectedTile,
                      hostNameInput ? recentConnection.hostName : undefined
                    );
                    setIsRecentConnectionSelected(true);
                  } else {
                    setRepository(null);
                    setIsRecentConnectionSelected(false);
                  }
                }}
                onLoadingStateChange={setIsDataloading}
              />
            </div>
          </>
        )}
        {isPw365Enabled && selectedTile === RepositoryType.PW365 && (
          <Pw365Connections
            onSelect={(selected: Pw365Connection) => {
              setRepo(selected.url, selectedTile);
              setSelectedConnection(selected);
            }}
            projectId={projectId}
            onClose={() => {
              setSelectedConnection(undefined);
            }}
            selectedConnection={selectedConnection}
          />
        )}
      </div>
      <WizardButtonRow
        backHandle={backHandle}
        cancelHandle={cancelHandle}
        primaryButtonHandle={() => {
          primaryButtonHandle(repository);

          if (currentConnection) {
            addConnectionToLocalStorage(currentConnection);
          }
        }}
        primaryButtonLabel={primaryButtonLabel}
        primaryDisabled={
          selectedTile == null || repository == null || isLoading
        }
      />
    </>
  );
};

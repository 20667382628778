import { useGraphQl } from '../useGraphQL/useGraphQL';
import { ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { Repository } from '../../entities/repository';
import { oneDay } from '../../constants/constants';
import { useUserContext } from '../../context/userContext/userContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

interface CachedItem {
  time: number;
  isValid: boolean;
}

export const useGetWorkspace = (): [
  any,
  boolean,
  any,
  any,
  (
    application: string,
    location: string,
    documentId: string
  ) => Promise<ResponseData<any>>
] => {
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();

  const [data, isLoading, error, fetchData] = useGraphQl(x => x, {
    initialData: [],
    initialIsLoading: true,
  });

  const areWorkspacesSupported = async (
    repository: Repository,
    documentId: string,
    setWorkspacesLoading: any
  ) => {
    const localStorageKey = `workspaces-cache_${repository.location}_${
      user!.profile.sub
    }_${buddiUrls.region}`;

    const cachedItem = localStorage.getItem(localStorageKey);
    const parsedCachedItem: CachedItem = cachedItem
      ? JSON.parse(cachedItem)
      : null;

    const now = Date.now();
    const timeDiff = now - (parsedCachedItem ? parsedCachedItem.time : 0);

    if (timeDiff > oneDay || !parsedCachedItem) {
      const getWorkspaces = async () => {
        setWorkspacesLoading(true);
        const response = await fetchWorkspace(
          'Microstation',
          repository.location,
          documentId
        );

        setWorkspacesLoading(false);

        return response.ok &&
          response?.data?.errors &&
          response?.data?.errors[0]?.message ===
            "Class 'WorkSpace' was not found in schema 'PW_WSG'."
          ? false
          : true;
      };

      const response = await getWorkspaces();
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({ time: now.toString(), isValid: response })
      );
      return response;
    } else {
      return parsedCachedItem.isValid;
    }
  };

  const fetchWorkspace = useCallback(
    (application: string, location: string, documentId: string) => {
      const query = getItemsGraphQLQuery(application, location, documentId);
      return fetchData(query, false);
    },
    [fetchData]
  );

  return [data, isLoading, error, areWorkspacesSupported, fetchWorkspace];
};

const getItemsGraphQLQuery = (
  application: string,
  location: string,
  documentId: string
) => {
  return `query { pwWorkspaces (application: "${application}", location: "${location}", documentId: "${documentId}") { id content relatedDocuments { id name relativeDirectory downloadUrl } } }`;
};

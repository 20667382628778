import React, { useState, ProviderProps } from 'react';
import { Project } from '../../externalComponents/entities/project';

export interface ProjectContextProps {
  currentProject: Project;
  setCurrentProject: (iModel: Project) => void;
}

interface ProjectContextProviderProps
  extends Omit<ProviderProps<ProjectContextProps>, 'value'> {}

export const ProjectContext = React.createContext<ProjectContextProps>({
  currentProject: {} as Project,
  setCurrentProject: () => {},
});

export const ProjectContextProvider = (props: ProjectContextProviderProps) => {
  const [currentProject, setCurrentProject] = useState<Project>({} as Project);
  return (
    <ProjectContext.Provider
      value={{ currentProject, setCurrentProject }}
      {...props}
    />
  );
};

export const useProjectContext = () => {
  const context = React.useContext(ProjectContext);
  if (context == null) {
    throw new Error('navigationContext must be used inside provider');
  }
  return context;
};

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@itwin/itwinui-react';
import {
  getWrappedTextCell,
  getTextColumnIUI,
} from '../../services/uiUtils/tableColumnWrappers';
import {
  FileRun,
  FileRunState,
  JobRunState,
} from '../../entities/jobRunStatus/jobRunStatus';
import {
  utcTimeStringToFormattedDateTime,
  getTotalTimeDifferenceOfFromStringValues,
} from '../../services/uiUtils/dateFormatter';
import { useFormatters } from '../../services/uiUtils/jobStatusFormatters';
import {
  humanizedTimeFormatFunction,
  humanizedTitleForTimeFunction,
} from '../../services/uiUtils/timeFormatter';
import { ContextMenu } from '../contextMenu/contextMenu';
import { useToast } from '../../context/toastContext/toastContext';
import copy from 'copy-text-to-clipboard';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { SvgCopy, SvgReports, SvgSave } from '@itwin/itwinui-icons-react';
import { TruncatedText } from '../../components/truncatedText/TruncatedText';
import { MenuAction } from '../../components/jobView/toolbarActions';
import { CellProps, Column } from 'react-table';
import './fileRunTable.scss';
import { navigate } from '@reach/router';
import { useModal } from '../../hooks/useModal/useModal';
import { SynchronizationReportModal } from '../modals/badgersModal/badgersModal';

export const FileRunStatusTable = (props: {
  iModelId: string;
  projectId: string;
  projectName?: string;
  iModelName?: string;
  fileRuns: FileRun[];
  jobDefinition?: JobDefinition;
  jobRunId: string;
  isJobRunning: boolean;
  areJobActivitiesLoading?: boolean;
  enableDownloadLogsOption?: boolean;
  jobRunStatus?: JobRunState;
}) => {
  const {
    projectId,
    iModelId,
    projectName,
    iModelName,
    fileRuns,
    areJobActivitiesLoading,
    enableDownloadLogsOption,
    jobDefinition,
    jobRunId,
    jobRunStatus,
  } = props;
  const { t } = useTranslation();
  const { showCopyJobId } = useFeatureToggleContext();
  const [selectedFileRun, setSelectedFileRun] = useState<FileRun>();
  const { enableSyncReportFullPage } = useFeatureToggleContext();

  const openSynchronizationReport = (
    jobDefinitionId: string,
    fileRun: FileRun
  ) => {
    if (enableSyncReportFullPage) {
      navigate(`${jobDefinitionId}/${fileRun.id}`, {
        state: {
          jobRunStatus: jobRunStatus,
          fileRunStatus: fileRun.status,
          runId: jobRunId
        },
      });
    } else {
      setSelectedFileRun(fileRun);
      showSynchronizationReportModal();
    }
  };

  const { fileRunStatusToFormattedText } = useFormatters();

  const {
    modalProps: synchronizationReportModalProps,
    openModal: showSynchronizationReportModal,
    closeModal: hideSynchronizationReportModal,
  } = useModal();

  const { toastError, toastSuccess } = useToast();
  const copyIdToClipboard = (value: string) => {
    const copyToClipboardSuccessful = copy(value);
    if (!copyToClipboardSuccessful) {
      toastError(t('CopyIdToClipboardFailure_Toast'));
      return;
    }
    toastSuccess(t('CopyIdToClipboardSuccess_Toast'));
  };

  const downloadLogs = async (original: FileRun) => {
    if (original.sasUrl !== null) window.open(original.sasUrl, '_blank');
    else toastError('Not Found');
  };

  const getOptions = (original: FileRun) => {
    const options: MenuAction[] = [];
    const fileRunStatus =
      original.status === FileRunState.Succeeded ||
      original.status === FileRunState.Fail ||
      original.status === FileRunState.Canceled ||
      original.status === FileRunState.CompletedWithIssues;

    if (enableDownloadLogsOption) {
      options.push({
        title: t('Downoad_Log_Btn'),
        onClick: () => downloadLogs(original),
        icon: <SvgSave />,
      });
    }

    options.push({
      title: t('SyncReport_ContextMenu_Btn'),
      onClick: () =>
        openSynchronizationReport(props.jobDefinition!.id, original),
      disabled: !fileRunStatus,
      icon: <SvgReports />,
    });

    options.push({
      title: t('CopyIdBtn_Label'),
      onClick: () => copyIdToClipboard(original.id),
      icon: <SvgCopy />,
    });

    if (showCopyJobId && original.batchJobRunId) {
      options.push({
        title: t('CopyJobId_Btn_Label'),
        onClick: () => {
          copyIdToClipboard(original.batchJobRunId!);
        },
        icon: <SvgCopy />,
      });
    }

    return options;
  };

  const columns = useMemo(
    (): Column<FileRun>[] => [
      {
        ...getTextColumnIUI<FileRun, 'inputFile'>(t('inputFile'), 'inputFile'),
        Cell: ({ row: { original } }: CellProps<FileRun>) => {
          const inputData = original.inputFile || '';
          return <TruncatedText>{inputData}</TruncatedText>;
        },
        minWidth: 10,
      },
      {
        ...getTextColumnIUI<FileRun, 'status'>(t('status'), 'status'),
        Cell: ({ row: { original } }: CellProps<FileRun>) => {
          return fileRunStatusToFormattedText(
            original.status,
            original.action,
            original.errorCode,
            original.details,
            original.knownIssueArticleLink,
            () => openSynchronizationReport(props.jobDefinition!.id, original)
          );
        },
        minWidth: 10,
      },
      {
        ...getTextColumnIUI<any, ''>(t('Duration'), ''),
        Cell: ({ row: { original } }: CellProps<FileRun>) => {
          const totalTime = getTotalTimeDifferenceOfFromStringValues(
            original.startTime,
            original.endTime
          );
          if (!totalTime) return getWrappedTextCell('', '');
          return getWrappedTextCell(
            humanizedTimeFormatFunction(totalTime),
            humanizedTitleForTimeFunction(totalTime)
          );
        },
        minWidth: 10,
        maxWidth: 200,
      },
      getTextColumnIUI<FileRun, 'startTime'>(
        '',
        'startTime',
        utcTimeStringToFormattedDateTime,
        utcTimeStringToFormattedDateTime,
        10,
        200
      ),
      getTextColumnIUI<FileRun, 'endTime'>(
        t('JobRunEndTimeColumnHeader_Label'),
        'endTime',
        utcTimeStringToFormattedDateTime,
        utcTimeStringToFormattedDateTime,
        10,
        200
      ),
      getTextColumnIUI<FileRun, 'bridgeType'>(
        '',
        'bridgeType',
        undefined,
        undefined,
        10,
        200
      ),
      {
        accessor: 'id',
        cellClassName: 'iui-slot',
        maxWidth: 42,
        minWidth: 42,
        Cell: ({ row: { original } }: CellProps<FileRun>) => {
          return <ContextMenu menuOptions={getOptions(original)} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <Table<{ [P in keyof FileRun]: FileRun[P] }>
        className="file-run-status-table"
        data={fileRuns ? fileRuns : []}
        emptyTableContent={
          areJobActivitiesLoading ? null : t('NoFileConversionFound_Label')
        }
        columns={columns}
        density="condensed"
      />

      {selectedFileRun && (
        <>
          <SynchronizationReportModal
            {...synchronizationReportModalProps}
            iModelId={iModelId}
            projectId={projectId}
            projectName={projectName}
            iModelName={iModelName}
            fileRun={selectedFileRun}
            jobDefinition={jobDefinition}
            jobRunId={jobRunId}
            closeModal={hideSynchronizationReportModal}
          />
        </>
      )}
    </>
  );
};

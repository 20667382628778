import React from 'react';
import {
  FilesTable,
  ProblemsTable,
  Report,
  ReportBanner,
  ReportDebugIds,
  ReportHeaderBannerWrapper,
  ReportInfoPanel,
  ReportInfoPanelWrapper,
  ReportTableSelect,
  ReportTableSelectWrapper,
  ReportTableView,
  ReportTimestamp,
  ReportTitle,
  ReportTitleWrapper,
} from '@itwin/synchronization-report-react';
import '@itwin/synchronization-report-react/dist/style.css';
import './synchronization-report-react-default.scss';
import { SvgWindowPopout } from '@itwin/itwinui-icons-react';
import { Button, Label } from '@itwin/itwinui-react';

interface SynchronizationReportDefaultProps {
  report: any;
  handleServiceRequestClick: any;
}

export const SynchronizationReportDefault = (
  props: SynchronizationReportDefaultProps
) => {
  const {
    report,
    handleServiceRequestClick,
  } = props;
  return (
    <Report data={report!}>
      <ReportTitleWrapper>
        <ReportTitle />
        <ReportDebugIds>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              styleType="high-visibility"
              startIcon={<SvgWindowPopout />}
              onClick={handleServiceRequestClick}
            >
              Create Service Report
            </Button>
          </div>
        </ReportDebugIds>
      </ReportTitleWrapper>
      <ReportHeaderBannerWrapper>
        <ReportTimestamp />
        <ReportBanner />
      </ReportHeaderBannerWrapper>
      <ReportTableSelectWrapper>
        <Label as="span">Issues Found</Label>
        <ReportTableSelect />
      </ReportTableSelectWrapper>
      <ReportInfoPanelWrapper>
        <ReportTableView>
          <FilesTable />
          <ProblemsTable />
        </ReportTableView>
        <ReportInfoPanel />
      </ReportInfoPanelWrapper>
    </Report>
  );
};
import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { AffinityReport } from '../../entities/affinityReport';

export const useGetAffinityReport = (): [
  AffinityReport | null,
  boolean,
  (jobDefId: string) => Promise<ResponseData<AffinityReport>>
] => {
  const useDataSettings = {
    initialData: {} as AffinityReport,
    initialIsLoading: true,
  };
  const [report, isReportLoading, , fetchData] = useDataApi<AffinityReport>(
    useDataSettings
  );

  const getReport = useCallback(
    (jobDefId: string) => {
      const requestData: RequestData<AffinityReport> = {
        method: 'GET',
        url: `/api/AffinityReport/GetAffinity?Id=${jobDefId}`,
        map: (response: any): AffinityReport => {
          return response;
        },
      };

      return fetchData(requestData);
    },
    [fetchData]
  );

  return [report, isReportLoading, getReport];
};

import { Item } from '../../entities/item';
import { getFileExtension } from '../../services/bridgeLogic/bridgeLogic';

export const getAutoAffinitySupportedFiles = (
  item: Item,
  isAutoAffinityEnabled: boolean,
  autoAffinityDgnOnly: boolean
) => {
  return isAutoAffinityEnabled && autoAffinityDgnOnly
    ? getFileExtension(item.name) === 'dgn' ||
        getFileExtension(item.name) === 'i.dgn'
    : true;
};

import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { RequestData, ResponseData } from '../../hooks/useDataApi/requestData';
import { useCallback } from 'react';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import { useUserContext } from '../../context/userContext/userContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useCreateJobSchedule = (
  projectId: string,
  iModelId: string
): [
  any,
  boolean,
  any,
  (jobDefinitionId: string, schedule: string) => Promise<ResponseData<null>>
] => {
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();

  const { logFeature } = useUsageLogger();

  const useDataSettings = { initialIsLoading: false, initialData: null };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const createJobSchedule = useCallback(
    (jobDefinitionId: string, schedule: string) => {
      const userId = user!.profile.sub;
      const url = `${buddiUrls.iModelBridgeSchedulerUrl}/api/JobSchedule`;
      const body = getCreateJobScheduleBody(
        projectId,
        iModelId,
        jobDefinitionId,
        userId,
        schedule
      );
      const requestData: RequestData<any> = {
        url: url,
        map: (x: any) => x,
        method: 'POST',
        body: body,
      };

      if (schedule) {
        logFeature(Features.CreateJobSchedule);
      }
      return fetchData(requestData);
    },
    [user, buddiUrls, projectId, iModelId, logFeature, fetchData]
  );

  return [data, isLoading, error, createJobSchedule];
};

export const getCreateJobScheduleBody = (
  projectId: string,
  iModelId: string,
  jobDefinitionId: string,
  userId: string,
  schedule: string
) => {
  return JSON.stringify({
    jobdefId: jobDefinitionId,
    userId: userId,
    iModelId: iModelId,
    contextId: projectId,
    schedule: schedule,
  });
};

import React, { useState } from 'react';
import '../../schedulev1/jobScheduleSelectionV1/jobScheduleSelection.scss';
import { useFeatureToggleContext } from '../../../../context/featureToggleContext/featureToggleContext';
import { useTranslation } from 'react-i18next';
import { TimePicker } from '../../../timePicker/timePicker';
import { WeeklyJobScheduleSelectionV2 } from '../weeklyJobScheduleV2/weeklyJobScheduleV2';
import { InputGroup, Radio, Select } from '@itwin/itwinui-react';

export enum ScheduleIntervalV2 {
  InitiatedManually = 0,
  EveryMinute = 60,
  Hourly = 3600,
  Every4Hours = 14400,
  Daily = 86400,
  Weekly = 604800,
}

interface JobScheduleSelectionV2Props {
  onScheduleChange: (schedule: string, nextSchedule?: string) => void;
  initialScheduleInterval: string;
  initialStart?: Date | null;
  currentSchedule?: Date | null;
}

export const JobScheduleSelectionV2 = (props: JobScheduleSelectionV2Props) => {
  const {
    onScheduleChange,
    initialScheduleInterval,
    initialStart,
    currentSchedule,
  } = props;

  const scheduleLoopInterval = Number.parseInt(initialScheduleInterval);
  const [scheduleInterval, setScheduleInterval] = useState<number>(
    initialScheduleInterval ? scheduleLoopInterval : 0
  );

  const [selectedMinuteInterval, setSelectedMinuteInterval] = useState(5);

  const { t } = useTranslation();
  const { minuteSchedule } = useFeatureToggleContext();

  const [selectedHourInterval, setSelectedHourInterval] = useState(0);

  return (
    <div className="schedule-sync-container" data-testid="schedule-v2">
      <InputGroup label={t('JobIModelUpdateSchedule_Title')}>
        <div className="schedule-option">
          <Radio
            label={t('JobScheduleInitiatedManually_Label')}
            name="schedule-sync"
            onChange={() => {
              setScheduleInterval(ScheduleIntervalV2.InitiatedManually);
              onScheduleChange(ScheduleIntervalV2.InitiatedManually.toString());
            }}
            checked={scheduleInterval === ScheduleIntervalV2.InitiatedManually}
          />
        </div>
        <div className="schedule-option">
          <Radio
            label={t('JobScheduleEach4Hours_Label')}
            name="schedule-sync"
            onChange={() => {
              setScheduleInterval(ScheduleIntervalV2.Every4Hours);
              onScheduleChange(ScheduleIntervalV2.Every4Hours.toString());
            }}
            checked={scheduleInterval === ScheduleIntervalV2.Every4Hours}
          />
        </div>

        {minuteSchedule && (
          <>
            <div className="schedule-option">
              <Radio
                label={t('JobScheduleEachMinute_Label')}
                name="schedule-sync"
                onChange={() => {
                  setScheduleInterval(ScheduleIntervalV2.EveryMinute);
                  onScheduleChange(
                    (
                      ScheduleIntervalV2.EveryMinute * selectedMinuteInterval
                    ).toString()
                  );
                }}
                checked={
                  scheduleInterval === ScheduleIntervalV2.EveryMinute ||
                  (scheduleInterval > 0 && scheduleInterval < 3600)
                }
              />
              <div className="day-time-picker">
                <Select
                  className="time-select-long"
                  options={[
                    { value: 5, label: '5 min' },
                    { value: 10, label: '10 min' },
                    { value: 15, label: '15 min' },
                    { value: 20, label: '20 min' },
                    { value: 30, label: '30 min' },
                  ]}
                  value={selectedMinuteInterval}
                  onChange={value => {
                    setSelectedMinuteInterval(value);

                    setScheduleInterval(ScheduleIntervalV2.EveryMinute);
                    onScheduleChange(
                      (ScheduleIntervalV2.EveryMinute * value).toString()
                    );
                  }}
                  disabled={
                    (scheduleInterval !== ScheduleIntervalV2.EveryMinute &&
                      scheduleInterval < 1) ||
                    scheduleInterval >= 3600
                  }
                />
              </div>
            </div>

            <div className="schedule-option">
              <Radio
                label={t('JobScheduleHourly_Label')}
                name="schedule-sync"
                onChange={() => {
                  setScheduleInterval(ScheduleIntervalV2.Hourly);
                  onScheduleChange(ScheduleIntervalV2.Hourly.toString());
                }}
                checked={scheduleInterval === ScheduleIntervalV2.Hourly}
              />
            </div>
          </>
        )}

        <div className="schedule-option">
          <Radio
            label={t('JobScheduleDaily_Label')}
            name="schedule-sync"
            onChange={() => {
              setScheduleInterval(ScheduleIntervalV2.Daily);
              const start = new Date();
              const h = selectedHourInterval;
              if (h <= start.getHours() || h - start.getHours() === 1) {
                start.setDate(start.getDate() + 1);
              }
              start.setHours(h);
              start.setMinutes(0);
              onScheduleChange(
                ScheduleIntervalV2.Daily.toString(),
                start.toISOString()
              );
            }}
            checked={scheduleInterval === ScheduleIntervalV2.Daily}
          />
          <TimePicker
            disable={scheduleInterval !== ScheduleIntervalV2.Daily}
            onTimeChanged={hours => {
              const start = new Date();
              const h = parseInt(hours);
              setSelectedHourInterval(h);
              if (h <= start.getHours() || h - start.getHours() === 1) {
                start.setDate(start.getDate() + 1);
              }

              start.setHours(h);
              start.setMinutes(0);

              onScheduleChange(
                ScheduleIntervalV2.Daily.toString(),
                start.toISOString()
              );
            }}
            hourIn24HrFormat={
              currentSchedule &&
              Number.parseInt(initialScheduleInterval) ===
                ScheduleIntervalV2.Daily
                ? currentSchedule.getHours().toString()
                : '0'
            }
          />
        </div>

        <div className="schedule-option">
          <WeeklyJobScheduleSelectionV2
            isSelected={scheduleInterval === ScheduleIntervalV2.Weekly}
            initialStart={initialStart}
            initialScheduleInterval={initialScheduleInterval}
            currentSchedule={currentSchedule}
            setSchedule={(nextStart: string) => {
              setScheduleInterval(ScheduleIntervalV2.Weekly);
              onScheduleChange(ScheduleIntervalV2.Weekly.toString(), nextStart);
            }}
          />
        </div>
      </InputGroup>
    </div>
  );
};

import React from 'react';
import { CellProps } from 'react-table';
import { TruncatedText } from '../../components/truncatedText/TruncatedText';

export const getColumnHeaderIUI = <T extends object, K extends keyof T>(
  title: string,
  propertyName: K
) => {
  return {
    id: propertyName,
    Header: title,
  };
};

export const getTextColumnIUI = <T extends object, K extends keyof T>(
  title: string,
  propertyName: K,
  valueFormatFunction: (value: T[K]) => any = (value: any) => {
    return value ? value : '';
  },
  titleFormatFunction: (value: T[K]) => any = (value: any) => {
    return value ? value.toString() : '';
  },
  minWidth?: number | undefined,
  maxWidth?: number | undefined
) => {
  return {
    ...getColumnHeaderIUI<T, K>(title, propertyName),
    ...(minWidth ? { minWidth } : {}),
    ...(maxWidth ? { maxWidth } : {}),
    Cell: ({ row: { original } }: CellProps<T, any>) => {
      const rawValue = original[propertyName];

      const formattedValue = valueFormatFunction(rawValue);
      const formattedTitle = titleFormatFunction(rawValue);

      return getWrappedTextCell(formattedValue, formattedTitle);
    },
  };
};

export const getWrappedTextCell = (text: string, title: string) => {
  return <TruncatedText>{text}</TruncatedText>;
};

import { useEffect, useState } from 'react';
import { Repository, RepositoryType } from '../../entities/repository';
import { useBreadcrumbs } from '../useBreadcrumbs/useBreadcrumbs';
import { useGetItems } from '../useGetFolderItems/useGetFolderItems';
import { useGetSearchFolderItems } from '../useGetSearchFolderItems/useGetSearchFolderItems';
import { Item } from '../../entities/item';
import { useToast } from '../../context/toastContext/toastContext';
import { useTranslation } from 'react-i18next';

interface UseFilePickerBreadcrumbsAndSearchResult {
  items: Item[];
  areItemsLoading: boolean;
  breadcrumbProps: {
    depth: number;
    labels: string[];
    values: string[];
    jumpTo: (_jumpToId: string, jumpToIndex: number) => () => void;
  };
  navigateTo: (item: Item) => void;
  setSearchText: (text: string) => any;
  searchText: string;
}

export const useFilePickerBreadcrumbsAndSearch = (repository: Repository) => {
  const { toastError } = useToast();
  const { t } = useTranslation();
  const rootBreadcrumb = {
    label: t('HomeBreadcrumb_Label'),
    value: repository.id,
  };

  const {
    lastBreadcrumbValue,
    breadcrumbs,
    addBreadcrumb,
    ...breadcrumbProps
  } = useBreadcrumbs([rootBreadcrumb]);

  const [
    currentFolderItems,
    isCurrentFolderItemsLoading,
    ,
    refetchCurrentFolder,
  ] = useGetItems(repository);

  const [searchText, setSearchText] = useState('');
  const [
    searchResult,
    isSearchLoading,
    ,
    refetchSearch,
  ] = useGetSearchFolderItems(repository);

  useEffect(() => {
    const refetchItems = async () => {
      const result = searchText
        ? await refetchSearch(lastBreadcrumbValue!, searchText)
        : await refetchCurrentFolder(lastBreadcrumbValue!);

      if (!result.ok) {
        if (repository.type === RepositoryType.PROJECTSHARE) {
          toastError(t('FailedToFetchiTwinFiles_Toast'));
        } else if (
          repository.type === RepositoryType.PWDI ||
          repository.type === RepositoryType.PWDI_LEGACY
        ) {
          toastError(t('FailedToFetchProjectwiseFiles_Toast'));
        }
      }
    };

    refetchItems();
  }, [
    searchText,
    lastBreadcrumbValue
  ]);

  const navigateTo = (item: Item) => {
    if (searchText) {
      setSearchText('');
    }
    addBreadcrumb(item.name, item.id);
  };

  return {
    items: searchText ? searchResult : currentFolderItems,
    areItemsLoading: searchText ? isSearchLoading : isCurrentFolderItemsLoading,
    breadcrumbProps: breadcrumbProps,
    navigateTo: navigateTo,
    setSearchText: setSearchText,
    searchText: searchText,
  } as UseFilePickerBreadcrumbsAndSearchResult;
};

import { FileRunState } from './jobRunStatus/jobRunStatus';

export interface JobLastRunStatus {
  jobId: string;
  status: FileRunState;
  runId?: string;
  startTime?: Date;
  modifiedTime?: Date;
}

export interface JobLastRunStatusDto {
  jobDefId: string;
  state: string;
  activityId?: string;
  startTime?: string;
  modifiedTime?: string;
}

export const jobLastRunStatusFromDtoMap = (statusDto: JobLastRunStatusDto) => {
  return {
    ...statusDto,
    jobId: statusDto.jobDefId,
    status: statusDto.state as FileRunState,
    startTime: statusDto.startTime && new Date(statusDto.startTime),
    modifiedTime: statusDto.modifiedTime && new Date(statusDto.modifiedTime),
  } as JobLastRunStatus;
};

export interface JobLastRunStatusResponse {
  jobs: JobLastRunStatusDto[];
}

export const jobLastRunsStatusesFromResponse = (
  response: JobLastRunStatusResponse
) => {
  return response.jobs.map(jobLastRunStatusFromDtoMap);
};

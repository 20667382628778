import { useCallback } from 'react';
import { useDocumentMapping } from '../useGetDocumentMapping/useGetDocumentMapping';
import {
  Briefcase,
  useGetUserBriefcases,
} from '../useGetUserBriefcases/useGetUserBriefcases';
import { useUpdateDocumentMapping } from '../useUpdateDocumentMapping/useUpdateDocumentMapping';
import { useDeleteBriefcases } from '../useDeleteBriefcases/useDeleteBriefcases';

export const useDeleteBriefcasesByDocumentMapping = (
  projectId: string,
  iModelId: string
) => {
  const [, , , fetchDataMapping] = useDocumentMapping(iModelId, projectId);
  const [, , , fetchUserBriefcases] = useGetUserBriefcases(iModelId);
  const [, , , updateDocumentMapping] = useUpdateDocumentMapping(
    projectId,
    iModelId
  );
  const [, , , deleteBriefcases] = useDeleteBriefcases(iModelId);

  const deleteBriefcasesForFiles = useCallback(
    async (fileIds: string[], userId: string) => {
      const briefcaseResponse = await fetchUserBriefcases(userId);
      if (
        !briefcaseResponse.ok ||
        briefcaseResponse.data == null ||
        briefcaseResponse.data.length === 0
      ) {
        return true;
      }
      const briefcasesOwnedByUser: Briefcase[] = briefcaseResponse.data.filter(
        b => b.userOwned === userId
      );
      const mapping = await fetchDataMapping();
      if (!mapping.ok || (mapping.ok && mapping.data == null)) {
        // not found is good to reassign
        return mapping.response?.status === 404;
      }
      const documentMapping = mapping.data.properties.documents;
      const documentMappingForUser = documentMapping?.filter(
        (dm: any) =>
          fileIds.find(fid => fid === dm.id) &&
          briefcasesOwnedByUser.find(b => b.briefcaseId === dm.briefcaseId)
      );
      if (documentMappingForUser != null && documentMappingForUser.length > 0) {
        const updatedDocuments = mapping.data.properties.documents.filter(
          (m: any) => {
            return (
              documentMappingForUser.find((dm: any) => dm.id === m.id) == null
            );
          }
        );

        const updatedMapping = {
          ...mapping.data,
          properties: {
            ...mapping.data.properties,
            documents: [...updatedDocuments],
          },
        };
        await deleteBriefcases(
          documentMappingForUser
            .map((dm: any) => dm.briefcaseId)
            .filter((id: string) => id != null)
        );
        await updateDocumentMapping(updatedMapping);
        return true;
      } else {
        return true;
      }
    },
    [
      deleteBriefcases,
      fetchDataMapping,
      fetchUserBriefcases,
      updateDocumentMapping,
    ]
  );
  return [deleteBriefcasesForFiles];
};

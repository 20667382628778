import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { Repository, RepositoryType } from '../../entities/repository';
import { JobFiles } from '../../entities/jobDefinition/jobDefinition';
import {
  RepositoryTypeV2,
  ConnectionDefinitionV2Dto,
  InputFileV2Dto,
  ExecutionStrategy,
} from '../../entities/jobDefinition/jobDefinitionDTOv2';
import {
  getRepositoryTypeV2,
  getInputFileLocation,
} from '../../services/bridgeLogic/bridgeLogic';
import { RequestData, ResponseData } from '../../hooks/useDataApi/requestData';
import { useCallback } from 'react';
import { BridgeType } from '../../entities/bridge';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import {
  useFeatureToggleContext,
  FeatureToggles,
} from '../../context/featureToggleContext/featureToggleContext';
import { Item } from '../../entities/item';
import { BridgeArg } from '../../entities/jobDefinition/jobDefintionDTO';
import { MasterContext } from '../useGetMasterReferencesTree/useGetMasterReferencesTree';
import { useUserContext } from '../../context/userContext/userContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { parse } from 'query-string';

export const useCreateJobV2 = (
  projectId: string,
  iModelId: string
): [
  ConnectionDefinitionV2Dto,
  boolean,
  any,
  (
    name: string,
    jobFiles: JobFiles,
    bridge: BridgeType,
    schedule: string,
    repository: Repository,
    bridgeArgs: BridgeArg,
    mastersContext: MasterContext[] | null,
    executionStrategy: ExecutionStrategy,
    nextSchedule?: Date | null,
    ownerId?: string
  ) => Promise<ResponseData<ConnectionDefinitionV2Dto>>
] => {
  const { logFeature } = useUsageLogger();
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();
  const features = useFeatureToggleContext();

  const useDataSettings = { initialIsLoading: false, initialData: null as any };
  const [
    data,
    isLoading,
    error,
    fetchData,
  ] = useDataApi<ConnectionDefinitionV2Dto>(useDataSettings);

  const createJob = useCallback(
    (
      name: string,
      jobFiles: JobFiles,
      bridge: BridgeType,
      schedule: string,
      repository: Repository,
      bridgeArgs: BridgeArg,
      mastersContext: MasterContext[] | null,
      executionStrategy: ExecutionStrategy,
      nextSchedule?: Date | null,
      ownerId?: string
    ) => {
      const spatialMaster = jobFiles.spatialMaster;
      const masters = jobFiles.masters;
      const sheets = jobFiles.sheets;
      const files = [
        ...(spatialMaster ? [spatialMaster] : []),
        ...masters,
        ...sheets,
      ];
      const spatialRootId = spatialMaster
        ? files.findIndex(x => x.id === spatialMaster.id)
        : -1;
      const body = getCreateConnectionV2Body(
        files,
        spatialRootId,
        repository,
        name,
        bridge,
        schedule,
        ownerId ? ownerId : (user?.profile.sub as string),
        features,
        mastersContext,
        bridgeArgs,
        executionStrategy,
        nextSchedule
      );

      let requestData: RequestData<any> = {} as RequestData<any>;

      requestData = {
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions?contextId=${projectId}&iModelId=${iModelId}`,
        map: (x: any) => x,
        method: 'POST',
        body: body,
      };

      logFeature(Features.CreateJob, [
        { name: 'jobName', value: name },
        {
          name: 'bridgeType',
          value: bridge,
        },
        {
          name: 'repositoryType',
          value: repository.type,
        },
        {
          name: 'fileCount',
          value: files.length.toString(),
        },
        {
          name: 'schedule',
          value: schedule,
        },
      ]);
      return fetchData(requestData);
    },
    [projectId, iModelId, logFeature, fetchData]
  );

  return [data, isLoading, error, createJob];
};

export const getGPRID = (): number => {
  const windowQueryParams = parse(window.location?.search);
  const app = (windowQueryParams?.application as string) || 'InstantOn';
  switch (app) {
    case 'PW365':
      return 3014;
    case 'OPENTOWER':
      return 3061;
    case 'SYNCHRO':
    case 'SYNCHROPWIC':
      return 2772;
    default:
      return 2996;
  }
};

export const getCreateConnectionV2Body = (
  files: Item[],
  spatialRootIndex: number,
  repository: Repository,
  jobName: string,
  bridge: BridgeType,
  scheduleInfo: string,
  userId: string,
  featuresToggles: FeatureToggles,
  mastersContext: MasterContext[] | null,
  bridgeArgs: BridgeArg,
  executionStrategy: ExecutionStrategy,
  nextSchedule?: Date | null
) => {
  let repoType = getRepositoryTypeV2(repository.type);
  if (
    featuresToggles.forceGeoSpatialBridgeAsLegacy &&
    bridge === BridgeType.Geospatial &&
    repository.type === RepositoryType.PWDI
  ) {
    repoType = RepositoryTypeV2.ProjectWiseLegacy;
  }

  const inputFiles: InputFileV2Dto[] = files.map((file, index) => {
    return {
      isSpatialRoot: index === spatialRootIndex,
      iModelBridgeType:
        mastersContext == null
          ? bridge
          : (mastersContext.find(mc => mc.master.id === file.id)?.mastersMap[
              file.id
            ].bridgeSelection[file.id].selectedBridge as BridgeType),
      fileId: file.id,
      lastKnownFileName: file.name,
    };
  });

  return JSON.stringify({
    name: jobName,
    isScheduled: Number.parseInt(scheduleInfo) > 0,
    ownerId: userId,
    scheduleLoopIntervalInSeconds: Number.parseInt(scheduleInfo),
    ...(nextSchedule != null
      ? { scheduleStartTimeUtc: nextSchedule.toISOString() }
      : {}),
    inputFiles: inputFiles,
    repositoryType: repoType,
    gprId: getGPRID(),
    bridgeParameters: bridgeArgs,
    ...getInputFileLocation(repository, repoType),
    executionStrategy,
  } as ConnectionDefinitionV2Dto);
};

import React, { createContext, ProviderProps, useContext } from 'react';

export interface IBentleyContext {
  projectId: string;
  iModelId: string;
}

export const BentleyContext = createContext<IBentleyContext>({
  projectId: '',
  iModelId: '',
});

export interface BentleyContextProviderProps
  extends Omit<ProviderProps<IBentleyContext>, 'value'> {
  projectId: string;
  iModelId: string;
}

export const BentleyContextProvider = ({
  ...props
}: BentleyContextProviderProps) => {
  const { projectId, iModelId } = props;

  return (
    <BentleyContext.Provider
      value={{
        projectId,
        iModelId,
      }}
      {...props}
    />
  );
};

export const useBentleyContext = () => {
  const context = useContext(BentleyContext);
  if (context == null) {
    throw new Error('useBentleyContext must be used inside provider');
  }
  return context;
};

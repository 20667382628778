import { Repository } from '../../entities/repository';
import { Item } from '../../entities/item';
import { ResponseData } from '../useDataApi/requestData';
import { useGraphQl } from '../useGraphQL/useGraphQL';
import { useCallback } from 'react';
import { getDocumentServiceRepositoryType } from '../../services/bridgeLogic/bridgeLogic';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

export const useGetItemsByIds = (): [
  Item[],
  boolean,
  any,
  (fileIds: string[], repository: Repository) => Promise<ResponseData<Item[]>>
] => {
  const [data, isLoading, error, fetchData] = useGraphQl(responseToItems, {
    initialData: [],
    initialIsLoading: false,
  });
  const { t } = useTranslation();

  const fetchItemsFromIds = useCallback(
    async (fileIds: string[], repository: Repository) => {
      if (fileIds.length === 0) {
        return { data: [] as Item[] } as ResponseData<Item[]>;
      }
      const query = getItemsGraphQLQuery(fileIds, repository);
      const result = await fetchData(query);
      if (!result.ok) {
        return result;
      }

      const inputFiles = manageMissingFiles(result, fileIds, t);
      return { ...result, data: inputFiles };
    },
    [fetchData, t]
  );

  return [data, isLoading, error, fetchItemsFromIds];
};

const getItemsGraphQLQuery = (fileIds: string[], repository: Repository) => {
  const repoType = getDocumentServiceRepositoryType(repository.type);
  const fileIdsString = fileIds.join('", "');
  return `{ items(input: { ids: ["${fileIdsString}"], type: ${repoType}, location: "${repository.location}"}) { id, name, itemType, isFolder, parentId, version} }`;
};

const responseToItems = (response: any): any => {
  return response.data != null && response.data.items != null
    ? (response.data?.items as Item[])
    : [];
};

export const manageMissingFiles = (
  result: any,
  fileIds: string[],
  t: TFunction
) => {
  if (result.data == null) {
    return [];
  }
  const inputFiles: JobDefinition[] = [...result.data!];
  const inputFileIds = inputFiles.map(f => f.id);
  const missingFileIds = fileIds.filter(x => {
    return !inputFileIds.includes(x);
  });

  const deletedFileName =
    result.status === 401
      ? t('DataSource_AccessForbidden')
      : t('DataSource_DeletedFile');

  missingFileIds.forEach(x => {
    const deletedFile = {
      id: x,
      name: deletedFileName,
    } as JobDefinition;
    inputFiles.push(deletedFile);
  });
  return inputFiles;
};

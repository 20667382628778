import React from 'react';
import { CopyToClipboardButton } from '../copyToClipboardButton/copyToClipboardButton';
import { useTranslation } from 'react-i18next';
import './reportIssueModal.scss';
import { ModalProps } from '../../hooks/useModal/useModal';
import { LabeledTextarea, Modal } from '@itwin/itwinui-react';

export const ReportIssueModal = (props: {
  automaticallyCollectedIssueData: any;
  modalProps: ModalProps;
}) => {
  const { automaticallyCollectedIssueData } = props;
  const { t } = useTranslation();
  const dataToInclude = JSON.stringify(
    automaticallyCollectedIssueData,
    null,
    4
  );

  return (
    <Modal
      {...props.modalProps}
      title={t('ReportIssueModal_Title')}
      className="report-issue-modal"
    >
      <LabeledTextarea
        label={t('ReportIssueModal_Label')}
        value={dataToInclude}
        onChange={() => null} // Ignore
      />
      <CopyToClipboardButton value={dataToInclude} />
    </Modal>
  );
};

import { useDataApi } from '../useDataApi/useDataApi';
import { useCallback } from 'react';
import { RequestData } from '../useDataApi/requestData';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useDeleteBriefcases = (
  iModelId: string
): [any, boolean, any, (briefcaseIds: string[]) => Promise<any>] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialData: [], initialIsLoading: false };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const deleteBriefcases = useCallback(
    async (briefcaseIds: string[]) => {
      const deleteBriefcase = (briefcaseId: string) => {
        const briefcaseRequestData: RequestData<any> = {
          url: `${buddiUrls.iModelHubUrl}/sv1.1/Repositories/iModel--${iModelId}/iModelScope/Briefcase/${briefcaseId}`,
          method: 'DELETE',
        };
        return fetchData(briefcaseRequestData);
      };
      return Promise.all(briefcaseIds.map((id: string) => deleteBriefcase(id)));
    },
    [iModelId, buddiUrls, fetchData]
  );

  return [data, isLoading, error, deleteBriefcases];
};

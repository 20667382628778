import React, { useEffect, useMemo, useState } from 'react';
import './repositoryFileSelection.scss';
import { Repository, RepositoryType } from '../../entities/repository';
import { Item } from '../../entities/item';
import { useTranslation } from 'react-i18next';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { useFilePickerBreadcrumbsAndSearch } from '../../hooks/useFilePickerBreadcrumbsAndSearch/useFilePickerBreadcrumbsAndSearch';
import { BreadCrumbs } from '../breadcrumbs/breadcrumbs';
import {
  getColumnHeaderIUI,
  getTextColumnIUI,
} from '../../services/uiUtils/tableColumnWrappers';
import { NavigatableCellIUI } from '../navigatableCell/navigatableCell';
import { getItemIcon } from '../itemIcon/itemIcon';
import cx from 'classnames';
import { Entity } from '../../entities/entity';
import { FlexTableWrapper } from '../flexTableWrapper/flexTableWrapper';
import {
  sortItemComparator,
  sortItems,
} from '../../services/uiUtils/sortItems';
import { Searchbar } from '../searchBar/searchBar';
import { getAutoAffinitySupportedFiles } from './getAutoAffinitySupportedFiles';
import { Checkbox, Table, Tooltip } from '@itwin/itwinui-react';
import { withConditionalWrapper } from '../../services/uiUtils/withConditionalWrapper';
import { Column } from 'react-table';

interface RepositoryFileSelectionProps {
  repository: Repository;
  isItemSelectable: (item: Item) => boolean;
  onSelection: (item: Item) => void;
  isSelected: (obj: Entity) => boolean;
  filesUsedByAnotherUser: string[];
  isAutoAffinityEnabled?: boolean;
}

export const RepositoryFileSelection: React.FC<RepositoryFileSelectionProps> = (
  props: RepositoryFileSelectionProps
) => {
  const {
    repository,
    isItemSelectable,
    onSelection,
    isSelected,
    filesUsedByAnotherUser,
    isAutoAffinityEnabled,
  } = props;

  const { t } = useTranslation();

  const { items, areItemsLoading, breadcrumbProps, navigateTo, setSearchText } =
    useFilePickerBreadcrumbsAndSearch(repository);

  const checkIfUsed = (item: Item) => {
    return filesUsedByAnotherUser.some(x => item.id === x);
  };

  const { autoAffinityDgnOnly } = useFeatureToggleContext();

  const isAutoAffinitySupported = (item: Item) =>
    getAutoAffinitySupportedFiles(
      item,
      isAutoAffinityEnabled!,
      autoAffinityDgnOnly
    );

  const ContidionalTooltip = withConditionalWrapper(Tooltip);

  const [sortedItems, setSortedItems] = useState<Item[]>([]);

  //This is a workaround because files are not being sorted the right way at the beginning
  useEffect(() => {
    setSortedItems(
      sortItems(areItemsLoading ? [] : items).map(x => ({
        ...x,
      }))
    );
  }, [areItemsLoading]);

  const columns = useMemo(
    (): Column<Item>[] => [
      {
        Cell: ({ row: { original } }) => (
          <div className="center-content master-model-checkbox">
            <ContidionalTooltip
              content={t('FileAlreadyUsed_Tooltip')}
              isWrapperDisplayed={checkIfUsed(original)}
            >
              <div>
                <Checkbox
                  checked={isSelected(original)}
                  onChange={() => {
                    onSelection(original);
                  }}
                  disabled={
                    !isItemSelectable(original) ||
                    checkIfUsed(original) ||
                    !isAutoAffinitySupported(original)
                  }
                  data-testid={'file-selection-checkbox'}
                />
              </div>
            </ContidionalTooltip>
          </div>
        ),
        minWidth: 40,
        maxWidth: 40,
        accessor: 'id',
        disableSortBy: true,
      },
      {
        ...getColumnHeaderIUI<Item, 'name'>(
          t('NameColumnHeader_Label'),
          'name'
        ),
        Cell: ({ row: { original } }) => (
          <NavigatableCellIUI
            name={original.name}
            onNavigate={
              original.isFolder ? () => navigateTo(original) : undefined
            }
            leftIcon={getItemIcon(original, 'table-cell-icon')}
          />
        ),
        minWidth: 150,
        accessor: 'name',
        sortDescFirst: true,
        sortType: ({ original: a }, { original: b }, _, desc?) =>
          sortItemComparator(a, b, desc),
      },
      ...(repository.type === RepositoryType.PWDI ||
      repository.type === RepositoryType.PWDI_LEGACY ||
      repository.type === RepositoryType.PW365
        ? [
            {
              ...getTextColumnIUI<Item, 'description'>(
                t('DescriptionColumnHeader_Label'),
                'description'
              ),
              disableSortBy: true,
            },
            {
              ...getTextColumnIUI<Item, 'version'>(
                t('VersionColumnHeader_Label'),
                'version'
              ),
              disableSortBy: true,
            },
          ]
        : []),
    ],
    [isSelected]
  );

  return (
    <div className="project-wise-file-selection-container">
      <div className="table-toolbar">
        <BreadCrumbs disableBreadcrumbsNavigation={areItemsLoading} {...breadcrumbProps}/>
        <div className="flex-spacer" />
        <Searchbar onChange={setSearchText} debounce />
      </div>
      <div className={cx('filepicker-wrapper')}>
        <FlexTableWrapper>
          <Table<{ [P in keyof Item]: Item[P] }>
            className="iui-condensed"
            data={sortedItems}
            emptyTableContent={areItemsLoading ? '' : t('NoItemsFound_Message')}
            isLoading={areItemsLoading && sortedItems.length === 0}
            isSortable={true}
            disableSortRemove={true}
            columns={columns}
            density="condensed"
          />
        </FlexTableWrapper>
      </div>
    </div>
  );
};

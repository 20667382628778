import { useDataApi } from '../useDataApi/useDataApi';
import { useCallback } from 'react';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export interface Briefcase {
  briefcaseId: number;
  userOwned: string;
}
export const useGetUserBriefcases = (
  iModelId: string
): [
  Briefcase[],
  boolean,
  any,
  (userId: string) => Promise<ResponseData<Briefcase[]>>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialData: [], initialIsLoading: false };
  const [data, isLoading, error, fetchData] =
    useDataApi<Briefcase[]>(useDataSettings);

  const getUserBriefcases = useCallback(
    async (userId: string) => {
      const briefcaseRequestData: RequestData<Briefcase[]> = {
        url: `${buddiUrls.iModelHubUrl}/sv1.1/Repositories/iModel--${iModelId}/iModelScope/Briefcase?filter=UserOwned eq '${userId}'`,
        method: 'GET',
        map: response =>
          response.instances.map((i: any) => ({
            fileId: i.properties.FileId,
            briefcaseId: i.properties.BriefcaseId,
            userOwned: i.properties.UserOwned,
          })),
      };
      return fetchData(briefcaseRequestData);
    },
    [iModelId, buddiUrls, fetchData]
  );

  return [data, isLoading, error, getUserBriefcases];
};

import React, { useEffect, useMemo } from 'react';
import { NavigatableCellIUI } from '../navigatableCell/navigatableCell';
import { FlexTableWrapper } from '../flexTableWrapper/flexTableWrapper';
import { Item } from '../../entities/item';
import { getItemIcon } from '../itemIcon/itemIcon';
import { getColumnHeaderIUI } from '../../services/uiUtils/tableColumnWrappers';
import { useTranslation } from 'react-i18next';
import { SpatialRootIcon } from '../spatialRootIcon/spatialRootIcon';
import {
  ApiVersion,
  ExecutionAction,
} from '../../entities/jobDefinition/jobDefinition';
import { SvgDeletePendingIcon } from '../icons/SvgDeletePending';
import { Table, Tooltip } from '@itwin/itwinui-react';
import { Icon } from '../icons/icon';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { CellProps, Column } from 'react-table';
import { ConnectionInputFileTableProps } from '../../typedef';
import { useInputFilesContext } from '../../context/inputFilesContext/inputFilesContext';
import { useSpatialRootsContext } from '../../context/spatialRootsContext/spatialRootsContext';
import { useConnectionDefintionsContext } from '../../context/connectionDefinitionsContext/connectionsDefintionsContext';

export const ConnectionInputFileTable = (
  props: ConnectionInputFileTableProps
) => {
  const { connectionDefinitionId } = props;
  const { projectId, iModelId } = useBentleyContext();
  const {
    connectionsDefintions,
    areConnectionDefintionsLoading,
    fetchConnectionDefintions,
  } = useConnectionDefintionsContext();
  const { connectionToFilesMap, fetchInputFiles, areInputFilesLoading } =
    useInputFilesContext();
  const { spatialRoots, getSpatialRoots } = useSpatialRootsContext();
  const connection = useMemo(
    () => connectionsDefintions.find(j => j.id === connectionDefinitionId),
    [connectionDefinitionId, connectionsDefintions]
  );
  const spatialRootId = useMemo(
    () =>
      spatialRoots?.length > 0 &&
      spatialRoots[0].jobDefinitionId === connection?.id
        ? spatialRoots[0].id
        : '',
    [connection, spatialRoots]
  );
  const inputFiles = useMemo(() => {
    return connection ? connectionToFilesMap[connection.id] : null;
  }, [connection, connectionToFilesMap]);

  const { t } = useTranslation();

  useEffect(() => {
    if (!connectionsDefintions) {
      fetchConnectionDefintions();
    }
    if (!spatialRoots) {
      getSpatialRoots(projectId, iModelId);
    }
  }, [
    connectionsDefintions,
    fetchConnectionDefintions,
    getSpatialRoots,
    iModelId,
    projectId,
    spatialRoots,
  ]);

  useEffect(() => {
    if (
      connection?.apiVersion === ApiVersion.v2 &&
      !connectionToFilesMap[connection.id]
    ) {
      fetchInputFiles(connection);
    }
  }, [connection, connectionToFilesMap, fetchInputFiles]);

  const remapedInputFiles = () => {
    if (!connection) {
      return [];
    }

    return connection.apiVersion === ApiVersion.v2
      ? inputFiles?.map(f => f.file) ?? []
      : connection.files.map(f => ({
          ...f.file,
          isFolder: false,
        }));
  };

  const isTableLoading =
    areConnectionDefintionsLoading ||
    (connection?.apiVersion === ApiVersion.v2 && areInputFilesLoading);

  const tableColumns = useMemo(
    (): Column<Item>[] => [
      {
        ...getColumnHeaderIUI<Item, 'name'>(
          t('NameColumnHeader_Label'),
          'name'
        ),
        Cell: ({ row: { original } }: CellProps<Item>) => (
          <NavigatableCellIUI
            name={original.name}
            leftIcon={getItemIcon(original, 'table-cell-icon')}
          />
        ),
        minWidth: 150,
        accessor: 'name',
      },
      {
        Cell: ({ row: { original } }: CellProps<Item>) =>
          original.id === spatialRootId ? (
            <SpatialRootIcon
              toolTipText={t('SpatialIcon_BridgAssigment_Tooltip')}
            />
          ) : inputFiles &&
            inputFiles.find(f => f.file.id === original.id)?.executionAction ===
              ExecutionAction.UnMap ? (
            <Tooltip content={t('DeleteFilePending_Title')}>
              <div className={`job-files-unlink-mapping-flag`}>
                <Icon icon={SvgDeletePendingIcon} color="default" />
              </div>
            </Tooltip>
          ) : null,
        minWidth: 40,
        maxWidth: 40,
        accessor: 'id',
      },
    ],
    []
  );

  return (
    <FlexTableWrapper>
      <Table<{ [P in keyof Item]: Item[P] }>
        data={remapedInputFiles()}
        isLoading={isTableLoading}
        emptyTableContent={t('NoItemsFound_Message')}
        columns={tableColumns}
        density="condensed"
      />
    </FlexTableWrapper>
  );
};

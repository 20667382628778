import { useDataApi } from '../useDataApi/useDataApi';
import {
  JobDefinition,
  JobDefinitionFile,
} from '../../entities/jobDefinition/jobDefinition';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback, useState } from 'react';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Item } from '../../entities/item';
import { mapInputFileDtoToJobDefinitionFile } from '../../entities/jobDefinition/jobDefinitionMappings';
import { BridgeType } from '../../entities/bridge';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useCreateInputFile = (
  projectId: string,
  iModelId: string
): [
  JobDefinitionFile,
  boolean,
  any,
  (
    job: JobDefinition,
    file: Item,
    bridgeType: BridgeType,
    isSpatial: boolean
  ) => Promise<ResponseData<JobDefinitionFile>>
] => {
  const { logFeature } = useUsageLogger();
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = {
    initialIsLoading: false,
    initialData: {} as JobDefinitionFile,
  };
  const [, , error, fetchData] = useDataApi<JobDefinitionFile>(useDataSettings);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<JobDefinitionFile>({} as JobDefinitionFile);

  const createInputFile = useCallback(
    async (
      job: JobDefinition,
      file: Item,
      bridgeType: BridgeType,
      isSpatial: boolean
    ) => {
      const requestData: RequestData<JobDefinitionFile> = {
        method: 'POST',
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${job.id}')/InputFiles?contextId=${projectId}&iModelId=${iModelId}`,
        body: JSON.stringify(
          getCreateInputFileBody(file, bridgeType, isSpatial)
        ),
        map: response =>
          mapInputFileDtoToJobDefinitionFile({ value: [response] })[0],
      };

      setIsLoading(true);

      const result = await fetchData(requestData);
      if (!result.ok) {
        return result;
      }

      const jobDefinitionFile = result.data;
      jobDefinitionFile!.file = file;

      setIsLoading(false);
      setData(jobDefinitionFile!);

      return { ...result, data: jobDefinitionFile };
    },
    [projectId, iModelId, logFeature, fetchData]
  );

  return [data, isLoading, error, createInputFile];
};

export const getCreateInputFileBody = (
  file: Item,
  bridgeType: BridgeType,
  isSpatial: boolean
) => ({
  isSpatialRoot: isSpatial,
  iModelBridgeType: bridgeType,
  fileId: file.id,
  lastKnownFileName: file.name,
});

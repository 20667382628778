import React, { ReactNode } from 'react';

export const withConditionalWrapper = <T extends object>(
  Wrapper: React.ComponentType<T>
) => {
  const ConditionalWrapper: React.FC<
    T & {
      isWrapperDisplayed: boolean;
      children: ReactNode;
    }
  > = props => {
    const { children, isWrapperDisplayed, ...rest } = props;

    return (
      <>
        {isWrapperDisplayed ? (
          <Wrapper {...(rest as T)}>{children}</Wrapper>
        ) : (
          children
        )}
      </>
    );
  };

  return ConditionalWrapper;
};

import React, { useState, useRef, useEffect } from 'react';
import { FullPageLoader } from '../../fullPageLoader/fullPageLoader';
import { Repository, RepositoryType } from '../../../entities/repository';
import { BridgeType } from '../../../entities/bridge';
import { useTranslation } from 'react-i18next';
import { useInputField } from '../../../hooks/useInputField/useInputField';
import { useJobNameValidation } from '../../../hooks/useJobNameValidation/useJobNameValidation';
import { JobScheduleSelection } from '../../schedule/schedulev1/jobScheduleSelectionV1/jobScheduleSelection';
import { useFeatureToggleContext } from '../../../context/featureToggleContext/featureToggleContext';
import { useGetWorkspace } from '../../../hooks/useGetWorkspace/useGetWorkspace';
import {
  SelectDataAdapter,
  useBridgeForFile,
} from '../../../services/bridgeLogic/bridgeLogic';
import {
  JobFiles,
  JobDefinition,
} from '../../../entities/jobDefinition/jobDefinition';
import './createConnectionContent.scss';
import { JobScheduleSelectionV2 } from '../../schedule/scheduleV2/jobScheduleSelectionv2/jobScheduleSelectionV2';
import { MasterContext } from '../../../hooks/useGetMasterReferencesTree/useGetMasterReferencesTree';
import { LabeledInput, LabeledSelect } from '@itwin/itwinui-react';

export interface ConnectionConfiguration {
  jobName: string;
  bridge: BridgeType;
  schedule: string;
  repository: Repository;
  masterContext: MasterContext[] | null;
  nextSchedule?: Date | null;
}

interface CreateConnectionContentProps {
  bridge?: BridgeType;
  jobs: JobDefinition[];
  jobFiles: JobFiles;
  masterContext: MasterContext[] | null;
  repository: Repository;
  isJobBeingCreated: boolean;
  isAffinityUIEnabled: boolean;
  onConnectionConfigurationChange: (
    configuration: ConnectionConfiguration
  ) => void;
  onDataValidationStateChange: (isLoading: boolean) => void;
}

export const CreateConnectionContent = (
  props: CreateConnectionContentProps
) => {
  const {
    isJobBeingCreated,
    jobs,
    masterContext,
    onConnectionConfigurationChange,
    onDataValidationStateChange,
    isAffinityUIEnabled,
  } = props;
  const { defineBridgeStepEnabled, createV2Connection } =
    useFeatureToggleContext();
  const { t } = useTranslation();
  const { pwLegacyCheckbox, obdBridgeOnlyPWLegacy } = useFeatureToggleContext();
  const isPwLegacyEnabled =
    pwLegacyCheckbox && props.repository.type === RepositoryType.PWDI;

  const [schedule, setSchedule] = useState('');
  const [nextSchedule, setNextSchedule] = useState<Date | null>(null);
  const [jobName, setJobName] = useState('');
  const [files] = useState([
    ...(props.jobFiles.spatialMaster ? [props.jobFiles.spatialMaster] : []),
    ...props.jobFiles.masters,
  ]);

  const [repository, setRepository] = useState<Repository>(props.repository);
  const [
    jobNameStatus,
    jobNameErrorMessage,
    isJobNameInputInvalid,
    setJobNameInputField,
  ] = useInputField();
  const { validate } = useJobNameValidation(
    jobs.map(job => job.name),
    setJobNameInputField
  );
  const { getAllowedBridgeTypesForFile, getBridgeSelectOptions } =
    useBridgeForFile();
  const [bridge, setBridge] = useState(
    props.bridge
      ? props.bridge
      : getAllowedBridgeTypesForFile(files[0].name, repository)[0]
  );
  const [, , , areWorkspacesSupported] = useGetWorkspace();
  const [areWorkspacesLoading, setWorkspacesLoading] = useState(false);

  useEffect(() => {
    onConnectionConfigurationChange({
      jobName,
      bridge,
      schedule,
      repository,
      masterContext,
      nextSchedule,
    });
  }, [jobName, bridge, schedule, repository, nextSchedule]);

  useEffect(() => {
    onDataValidationStateChange(
      (isPwLegacyEnabled && areWorkspacesLoading) ||
        isJobNameInputInvalid ||
        isJobBeingCreated
    );
  }, [
    isPwLegacyEnabled,
    areWorkspacesLoading,
    isJobNameInputInvalid,
    isJobBeingCreated,
  ]);

  const checkIfSelectionContainsBridges = (bridgeList: BridgeType[]) => {
    if (masterContext !== null) {
      // If any of the selected files are set to be processed with Open Buildings
      for (const context of masterContext) {
        const allBridgeSelections = Object.keys(
          context.mastersMap[context.master.id].bridgeSelection
        );

        for (const bridgeSelectionId of allBridgeSelections) {
          if (
            bridgeList.includes(
              context.mastersMap[context.master.id].bridgeSelection[
                bridgeSelectionId
              ].selectedBridge
            )
          ) {
            return true;
          }
        }
      }

      return false;
    }
  };

  useEffect(() => {
    if (
      obdBridgeOnlyPWLegacy &&
      props.repository.type === RepositoryType.PWDI
    ) {
      if (checkIfSelectionContainsBridges([BridgeType.OBD])) {
        setRepository({
          ...repository,
          type: RepositoryType.PWDI_LEGACY,
        });
        return;
      }
    }

    if (
      isPwLegacyEnabled &&
      checkIfSelectionContainsBridges([
        BridgeType.Microstation,
        BridgeType.Civil,
        BridgeType.OBD,
      ])
    ) {
      areWorkspacesSupported(
        repository,
        files[0].id,
        setWorkspacesLoading
      ).then((response: boolean) => {
        response
          ? setRepository({ ...props.repository })
          : setRepository({ ...repository, type: RepositoryType.PWDI_LEGACY });
      });
    } else {
      setRepository({ ...props.repository });
    }
  }, [bridge]);

  const jobNameInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (jobNameInputRef?.current != null) {
      jobNameInputRef.current.focus();
    }
  }, []);

  return (
    <div className="create-connection-container">
      {isJobBeingCreated ? (
        <FullPageLoader />
      ) : (
        <div className="create-connection-content-container">
          <LabeledInput
            label={t('JobNameInputField_Label')}
            value={jobName}
            data-testid="jobName-input"
            onChange={(e: any) => {
              validate(e.target.value);
              setJobName(e.target.value);
            }}
            status={jobNameStatus}
            message={jobNameErrorMessage}
            ref={jobNameInputRef}
          />
          {!defineBridgeStepEnabled && (
            <LabeledSelect<BridgeType>
              data-testid="bridge-select"
              value={bridge}
              label={t('ConnectorTypeDropDownInput_Label')}
              options={getBridgeSelectOptions(
                files[0].name,
                repository,
                SelectDataAdapter.iTwinUI
              )}
              onChange={value => {
                setBridge(value);
              }}
            />
          )}
          {!createV2Connection ? (
            <JobScheduleSelection
              onScheduleChange={setSchedule}
              initialSchedule={schedule}
            />
          ) : (
            <JobScheduleSelectionV2
              onScheduleChange={(
                schedule: string,
                newNextSchedule?: string
              ) => {
                setSchedule(schedule);
                setNextSchedule(
                  newNextSchedule != null ? new Date(newNextSchedule) : null
                );
              }}
              initialScheduleInterval={schedule}
            />
          )}
        </div>
      )}
    </div>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileRun } from '../../entities/jobRunStatus/jobRunStatus';
import { useToast } from '../../context/toastContext/toastContext';
import { ApiVersion } from '../../entities/jobDefinition/jobDefinition';
import { getJobTokenType } from '../../services/bridgeLogic/bridgeLogic';
import { useOrchestratorAuthenticateUser } from '../../hooks/useOrchestratorAuthenticateUser/useOrchestratorAuthenticateUser';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { useRunJob } from '../../hooks/useRunJob/useRunJob';
import { useGetJobStatus } from '../../hooks/useGetJobRunStatus/useGetJobRunStatus';
import { useGetJobsLastStatuses } from '../../hooks/useGetJobsLastStatuses/useGetJobsLastStatuses';
import { useConnectionDefintionsContext } from '../../context/connectionDefinitionsContext/connectionsDefintionsContext';
import { useGetItemsByIds } from '../../hooks/useGetItemsByIds/useGetItemsByIds';
import { useGetJobSchedule } from '../../hooks/useGetJobSchedule/useGetJobSchedule';
import { ConnectionDefinitionRunStatusTableProps } from '../../typedef';
import { NonIdealState } from '@itwin/itwinui-react';
import { RunInformationStatusTable } from '../runInformationStatusTable/runInformationStatusTable';
import { useInputFilesContext } from '../../context/inputFilesContext/inputFilesContext';
import { Svg404 } from '@itwin/itwinui-illustrations-react';

export interface FileMap {
  [key: string]: string;
}

export const ConnectionDefinitionRunStatusTable = (
  props: ConnectionDefinitionRunStatusTableProps
) => {
  const {
    orchAuthRedirectUrl,
    connectionDefinitionId,
    currentProjectName,
    currentiModelName,
    setRefetchStatusesMethod,
    onRunStatusUpdated,
  } = props;

  const { t } = useTranslation();
  const { toastError } = useToast();
  const {
    connectionsDefintions: jobDefs,
    areConnectionDefintionsLoading: areJobsLoading,
  } = useConnectionDefintionsContext();
  const { projectId, iModelId } = useBentleyContext();

  const [, , , orchestratorAuthenticateUser] =
    useOrchestratorAuthenticateUser();
  const [, , , runJob] = useRunJob(projectId, iModelId);

  const [jobStatuses, areJobStatusesLoading, , fetchJobStatuses] =
    useGetJobStatus();
  const [, , , fetchJobLastStatuses] = useGetJobsLastStatuses();
  const [, , , fetchItemsFromIds] = useGetItemsByIds();
  const { connectionToFilesMap, fetchInputFiles } = useInputFilesContext();
  const [jobSchedule, , ,] = useGetJobSchedule(projectId, iModelId);

  const [showSpinner, setShowSpinner] = useState(true);

  const jobDefinition = useMemo(() => {
    return jobDefs.find(x => x.id === connectionDefinitionId);
  }, [jobDefs]);

  const runJobAndHandle = async () => {
    const jobTokenType = getJobTokenType(jobDefinition!.repositoryType);
    const authenticated = await orchestratorAuthenticateUser(
      jobTokenType,
      orchAuthRedirectUrl
    );

    if (!authenticated) {
      return;
    }
    await runJob(jobDefinition!, true);
    await fetchJobLastStatuses(jobDefs.map(jd => jd.id));
    await fetchJobStatuses(jobDefinition!);
  };

  const [fileMap, setFileMap] = useState<FileMap | null>(null);
  const [, setFileNamesLoading] = useState<boolean>(false);

  const getFileMap = useCallback(
    async (forceRefetch?: boolean) => {
      if ((fileMap == null && !areJobStatusesLoading) || forceRefetch) {
        const map: FileMap = {};
        setFileNamesLoading(true);
        const inputf =
          connectionToFilesMap[jobDefinition!.id] ||
          (await fetchInputFiles(jobDefinition!));

        const ids: string[] = [];
        inputf?.forEach(x => {
          if (x.file.name != null) {
            map[x.file.id] = x.file.name;
          } else {
            ids.push(x.file.id);
          }
        });

        jobStatuses.forEach(status => {
          status.fileRuns.forEach(fr => {
            if (
              fr.inputFile == null &&
              ids.indexOf(fr.inputFileId) === -1 &&
              map[fr.inputFileId] == null
            ) {
              ids.push(fr.inputFileId);
            } else if (fr.inputFileId != null && fr.inputFile != null) {
              map[fr.inputFileId] = fr.inputFile;
            }
          });
        });

        setFileMap(map);
        setFileNamesLoading(false);
      }
    },
    [
      fileMap,
      areJobStatusesLoading,
      connectionToFilesMap,
      jobDefinition,
      fetchInputFiles,
      jobStatuses,
      projectId,
      fetchItemsFromIds,
      t,
    ]
  );

  const runStatusesWithFileNames = useMemo(() => {
    return jobDefinition
      ? jobDefinition.apiVersion === ApiVersion.v2
        ? jobStatuses.map(js => ({
            ...js,
            fileRuns: js.fileRuns.map((fr: FileRun) => ({
              ...fr,
              inputFile: fileMap != null ? fileMap[fr.inputFileId] : null,
            })),
          }))
        : jobStatuses
      : [];
  }, [jobDefinition, fileMap, jobStatuses]);

  useEffect(() => {
    const fetchJobStatusesAsync = async () => {
      if (jobDefinition) {
        setShowSpinner(true);
        await fetchJobStatuses(jobDefinition);
        setShowSpinner(false);
      }
    };

    fetchJobStatusesAsync();
  }, [fetchJobStatuses, jobDefinition]);

  useEffect(() => {
    const getFileMapAsync = async () => {
      if (
        jobDefinition &&
        jobStatuses.length > 0 &&
        jobDefinition.apiVersion === ApiVersion.v2
      ) {
        await getFileMap();
      }
    };

    getFileMapAsync();
  }, [getFileMap, jobDefinition, jobStatuses]);

  const fetchStatusesAndHandle = useCallback(async () => {
    if (jobDefinition) {
      const result = await fetchJobStatuses(jobDefinition);
      if (!result.ok && result?.response?.status !== 404) {
        toastError(t('FailedToGetJobStatuses_Toast'));
      }
    }
  }, [jobDefinition, fetchJobStatuses, t, toastError]);

  useEffect(() => {
    if (jobDefinition) {
      setRefetchStatusesMethod(fetchStatusesAndHandle);
    }
  }, [jobDefinition]);

  useEffect(() => {
    onRunStatusUpdated(jobStatuses);
  }, [jobStatuses]);

  if (!jobDefinition && !areJobsLoading) {
    return (
      <div className="fit-to-parent">
        <NonIdealState
          svg={<Svg404 />}
          heading={t('FullPageError_ConnectionNotFound_Message')}
        />
      </div>
    );
  }

  return (
    <>
      <RunInformationStatusTable
        iModelId={iModelId}
        projectId={projectId}
        currentProjectName={currentProjectName}
        currentiModelName={currentiModelName}
        jobStatuses={runStatusesWithFileNames}
        jobDefinition={jobDefinition!}
        jobSchedule={jobSchedule}
        showSpinner={showSpinner}
        runJobAndHandle={runJobAndHandle}
      />
    </>
  );
};

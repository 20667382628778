import { Text } from '@itwin/itwinui-react';
import React, { useRef, useState } from 'react';
import './TruncatedText.scss';
import cx from 'classnames';

export type TruncatedTextProps = {
  children: string;
  isMuted?: boolean;
  isSkeleton?: boolean;
} & React.HTMLAttributes<HTMLParagraphElement>;

export const TruncatedText = (props: TruncatedTextProps) => {
  const { children, className, title, ...rest } = props;

  const pRef = useRef<HTMLParagraphElement | null>(null);
  const [showTitle, setShowTitle] = useState(false);

  const isTruncated = () => {
    const p = pRef.current;
    if (!p) return false;
    return p.scrollWidth > p.offsetWidth;
  };

  return (
    <Text
      variant="body"
      {...rest}
      className={cx('truncated-text-wrapper', className)}
      ref={pRef}
      onMouseEnter={() => {
        setShowTitle(isTruncated());
      }}
      title={showTitle ? title || children : ''}
    >
      {children}
    </Text>
  );
};

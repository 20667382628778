export enum Features {
  PortalUsage = 'PortalUsage',
  CopyConnection = 'CopyConnection',
  CreateIModel = 'CreateIModel',
  CreateJobSchedule = 'CreateJobSchedule',
  DeleteJobSchedule = 'DeleteJobSchedule',
  EditJobSchedule = 'EditJobSchedule',
  DeleteJob = 'DeleteJob',
  RenameJob = 'RenameJob',
  ViewCreateJobPage = 'ViewCreateJobPage',
  ViewIModelsPage = 'ViewIModelsPage',
  ViewJobsPage = 'ViewJobsPage',
  JobRunAuthenticationRedirect = 'JobRunAuthenticationRedirect',
  ViewJobConfigurationPage = 'ViewJobConfigurationPage',
  JobRunStartClicked = 'JobRunStartClicked',
  CreateJob = 'CreateJob',
  SetSpatial = 'SetSpatial',
  TotalAffinityDataSetSize = 'TotalAffinityDataSetSize',
  ChangeOwner = 'ChangeOwner',
}

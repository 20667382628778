import React, { useEffect } from 'react';
import {
  Repository,
  COMMUNITIES_CREATECONNECTION_LINK,
} from '../../../entities/repository';
import { Item } from '../../../entities/item';
import { useTranslation } from 'react-i18next';
import { useItemListWithToggle } from '../../../hooks/useItemListWithToggle/useItemListWithToggle';
import {
  WizardButtonRow,
  WizardButtonRowProps,
} from '../wizardButtonRow/wizardButtonRow';
import './fileSelectionStep.scss';
import { RepositoryFileSelection } from '../../repositoryFileSelection/repositoryFileSelection';
import { Explanation } from '../../explanation/explanation';

export interface FileSelectionStepProps {
  id: string;
  repository: Repository;
  isItemSelectable: (item: Item) => boolean;
  preSelectedItems?: Item[];
  filesUsedByAnotherUser: string[];
  isAutoAffinityEnabled: boolean;
  selectedSpatialRoot: Item | null;
  removeSpatialRoot: () => void;
}
export const FileSelectionStep = (
  props: FileSelectionStepProps & WizardButtonRowProps
) => {
  const {
    id,
    repository,
    isItemSelectable,
    backHandle,
    cancelHandle,
    primaryButtonHandle,
    primaryButtonLabel,
    skipHandle,
    skipTooltip,
    preSelectedItems = [],
    filesUsedByAnotherUser,
    isAutoAffinityEnabled,
    selectedSpatialRoot,
    removeSpatialRoot,
  } = props;
  const { t } = useTranslation();

  const { selection, isSelected, setSelected, toggleIsSelected } =
    useItemListWithToggle();

  const select = (item: Item) => {
    if (selectedSpatialRoot !== null && item.id === selectedSpatialRoot.id) {
      removeSpatialRoot();
    }
    toggleIsSelected(item);
  };

  useEffect(() => {
    setSelected(preSelectedItems);
  }, [preSelectedItems]);

  const isCompositeSelectionStep = id === 'select-composite-models';
  return (
    <>
      <div className="create-connection-container">
        <Explanation
          text={t(
            isCompositeSelectionStep
              ? 'ReadMoreAboutCompositeLimitations_Text'
              : 'ReadMoreAboutSpatialLimitations_Text'
          )}
          linkText={t('ReadMoreAboutLimitations_Link')}
          linkUrl={COMMUNITIES_CREATECONNECTION_LINK}
        />
        <RepositoryFileSelection
          repository={repository}
          isItemSelectable={isItemSelectable}
          isSelected={isSelected}
          onSelection={select}
          filesUsedByAnotherUser={filesUsedByAnotherUser}
          isAutoAffinityEnabled={isAutoAffinityEnabled}
        />
        <WizardButtonRow
          backHandle={backHandle}
          cancelHandle={cancelHandle}
          primaryButtonHandle={() => {
            primaryButtonHandle(selection);
          }}
          primaryButtonLabel={primaryButtonLabel}
          primaryDisabled={selection.length < 1}
          skipHandle={skipHandle}
          skipTooltip={skipTooltip}
        />
      </div>
    </>
  );
};

import React from 'react';
import { SvgWindowPopout } from '@itwin/itwinui-icons-react';
import { Button, Label } from '@itwin/itwinui-react';
import {
  IssueArticleOpenEventDataType,
  ProblemsTable,
  Report,
  ReportBanner,
  ReportDebugIds,
  ReportHeaderBannerWrapper,
  ReportInfoPanel,
  ReportInfoPanelWrapper,
  ReportTableSelect,
  ReportTableSelectWrapper,
  ReportTimestamp,
  ReportTitle,
  ReportTitleWrapper,
  SyncReportOpenedEventDataType,
  issueArticleOpenTelemetryDataType,
  syncReportOpenTelemetryDataType,
} from '@itwin/synchronization-report-react-dev';
import '@itwin/synchronization-report-react-dev/dist/style.css';
import { useAppContext } from '../../../context/appContext/appContext';

interface SynchronizationReportDevProps {
  report: any;
  handleServiceRequestClick: any;
  syncReportOpenTelemtry?: syncReportOpenTelemetryDataType;
  issueArticleTelemtry?: issueArticleOpenTelemetryDataType;
  setIssueId?: (issueId: string) => void;
  setIsIssueFeedbackOpen?: (isOpen: boolean) => void;
}

export const SynchronizationReportDev = (
  props: SynchronizationReportDevProps
) => {
  const {
    report,
    handleServiceRequestClick,
    syncReportOpenTelemtry,
    issueArticleTelemtry,
    setIssueId,
    setIsIssueFeedbackOpen,
  } = props;

  const { configuration } = useAppContext();
  const [syncReportOpenEventProps, setSyncReportOpenEventProps] =
    React.useState<SyncReportOpenedEventDataType | undefined>(undefined);
  const [issueArticleEventProps, setIssueArticleEventProps] = React.useState<
    IssueArticleOpenEventDataType | undefined
  >(undefined);

  React.useEffect(() => {
    if (
      issueArticleTelemtry != undefined &&
      syncReportOpenTelemtry != undefined
    ) {
      setSyncReportOpenEventProps({
        syncReportOpenTelemetry: syncReportOpenTelemtry,
      });

      setIssueArticleEventProps({
        issueArticleOpenTelemetry: issueArticleTelemtry,
        onIssueArticleOpenEventPerform: (issueId: string) => {
          setIssueId?.(issueId);
          setIsIssueFeedbackOpen?.(true);
        },
      });
    }
  }, [syncReportOpenTelemtry, issueArticleTelemtry]);

  return issueArticleEventProps && syncReportOpenEventProps ? (
    <Report
      data={report!}
      applicationInsightInstrumentationKey={
        configuration.appInsightsInstrumentationKey
      }
      SyncReportOpenedEventProps={syncReportOpenEventProps}
      issueArticleOpenEventProps={issueArticleEventProps}
      className="sync-report"
    >
      <ReportTitleWrapper>
        <ReportTitle />
        <ReportDebugIds>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              styleType="high-visibility"
              startIcon={<SvgWindowPopout />}
              onClick={handleServiceRequestClick}
            >
              Create Service Report
            </Button>
          </div>
        </ReportDebugIds>
      </ReportTitleWrapper>
      <ReportTimestamp />
      <ReportHeaderBannerWrapper>
        <Label as="span">Issues found by severity</Label>
        <ReportBanner />
      </ReportHeaderBannerWrapper>
      <ReportTableSelectWrapper>
        <ReportTableSelect />
      </ReportTableSelectWrapper>
      <ReportInfoPanelWrapper className="sync-report-table">
        <ProblemsTable />
        <ReportInfoPanel />
      </ReportInfoPanelWrapper>
    </Report>
  ) : (
    <></>
  );
};

import React, { useEffect } from 'react';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { useAppContext } from '../../context/appContext/appContext';
import { useTranslation } from 'react-i18next';

export const CompleteSignOut: React.FunctionComponent<any> = props => {
  const { userManager } = useAppContext();
  const { t } = useTranslation();
  useEffect(() => {
    userManager.signoutRedirectCallback().then(res => {
      userManager.signinRedirect({ ...res });
    });
  }, []);
  return (
    <FullPageLoader>{t('FullPage_CompletingSignOut_Message')}</FullPageLoader>
  );
};

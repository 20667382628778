import React from 'react';
import {
  FileRunState,
  JobRunState,
  JobRunTriggerType,
} from '../../entities/jobRunStatus/jobRunStatus';
import { assertUnreachable } from './assertUnreachable';
import './column.scss';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ExecutionAction } from '../../entities/jobDefinition/jobDefinition';
import SvgDeletePendingIcon from '../../components/icons/SvgDeletePending';
import {
  SvgInfoCircular,
  SvgStatusError,
  SvgStatusRunning,
  SvgStatusSuccess,
  SvgStatusWarning,
  SvgStatusPending,
} from '@itwin/itwinui-icons-react';
import { SvgStatusPass } from '@itwin/itwinui-icons-color-react';
import { Icon } from '../../components/icons/icon';
import { TruncatedText } from '../../components/truncatedText/TruncatedText';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';

export const useFormatters = () => {
  const { t } = useTranslation();
  const { showNewJobStatusLabel } = useFeatureToggleContext();
  const fileRunStatusToFormattedText = (
    status: FileRunState,
    action?: ExecutionAction,
    errorCode?: string,
    details?: string,
    wikiLink?: string,
    moreOnClick?: () => void
  ) => {
    const failStateText =
      details != null && details !== ''
        ? details
        : action === ExecutionAction.UnMap
        ? t('UnmapFailState_Label')
        : t('JobRunStateFailed_Label');
    switch (status) {
      case FileRunState.Fail:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusError}
              color="negative"
              className="status-icon"
            />
            <TruncatedText>{failStateText}</TruncatedText>

            {wikiLink ? (
              <a
                className="jobStatusMore iui-anchor"
                href={wikiLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('ConnectionStatusMoreInfo_Label')}
              </a>
            ) : (
              errorCode === 'TAE_BRG_1000' && (
                <a
                  className="jobStatusMore iui-anchor"
                  onClick={moreOnClick}
                >
                  {t('ConnectionStatusMoreInfo_Label')}
                </a>
              )
            )}
          </span>
        );
      case FileRunState.InProgress:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusRunning}
              color="primary"
              className="status-icon"
            />
            <TruncatedText>{t('JobStateInProgress_Label')}</TruncatedText>
          </span>
        );
      case FileRunState.NotStarted:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusPending}
              color="primary"
              className="status-icon"
            />
            <TruncatedText>{t('FileRunStateInTheQueue_Label')}</TruncatedText>
          </span>
        );
      case FileRunState.Succeeded: {
        const text =
          action === ExecutionAction.UnMap
            ? 'Deleted'
            : details != null && details !== 'Success'
            ? details
            : t(
                showNewJobStatusLabel
                  ? 'NewJobStateSucceeded_Label'
                  : 'JobStateSucceeded_Label'
              );
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusSuccess}
              color="positive"
              className="status-icon"
            />
            <TruncatedText>{text}</TruncatedText>
          </span>
        );
      }
      case FileRunState.Timeout:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusError}
              color="negative"
              className="status-icon"
            />
            <TruncatedText>{t('FileRunStateTimeout_Label')}</TruncatedText>
          </span>
        );
      case FileRunState.Canceled:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusWarning}
              color="warning"
              className="status-icon"
            />
            <TruncatedText>{t('FileRunStateCanceled_Label')}</TruncatedText>
          </span>
        );
      case FileRunState.WaitingToRetry:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusPending}
              color="primary"
              className="status-icon"
            />
            <TruncatedText>
              {t('FileRunStateWaitingToRetry_Label')}
            </TruncatedText>
          </span>
        );
      case FileRunState.WaitingToExecute:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusPending}
              color="primary"
              className="status-icon"
            />
            <TruncatedText>
              {t('FileRunStateWaitingToExecute_Label')}
            </TruncatedText>
          </span>
        );
      case FileRunState.Skipped:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusPass}
              color="primary"
              className="status-icon"
            />
            <TruncatedText>{t('FileRunStateSkipped_Label')}</TruncatedText>
          </span>
        );
      case FileRunState.Paused:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusWarning}
              color="warning"
              className="status-icon"
            />
            <TruncatedText>{t('FileRunStatePaused_Label')}</TruncatedText>
          </span>
        );
      case FileRunState.CompletedWithIssues:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgInfoCircular}
              color="primary"
              className="status-icon"
            />
            <TruncatedText>
              {details ??
                t(
                  showNewJobStatusLabel
                    ? 'NewJobRunStateCompletedWithIssues_Label'
                    : 'JobRunStateCompletedWithIssues_Label'
                )}
            </TruncatedText>
            {details && (
              <a
                className="jobStatusMore iui-anchor"
                onClick={moreOnClick}
              >
                {t('ConnectionStatusMoreInfo_Label')}
              </a>
            )}
          </span>
        );
      case FileRunState.NotFound:
        return <span></span>;
      default:
        assertUnreachable(status);
        return <span></span>;
    }
  };
  const fileRunStatusToFormattedTitle = (
    status: FileRunState,
    details?: string
  ) => {
    switch (status) {
      case FileRunState.Fail:
        return details ? details : 'Failed';
      case FileRunState.InProgress:
        return 'In progress';
      case FileRunState.NotStarted:
        return 'In a queue';
      case FileRunState.Succeeded:
        return 'Success';
      case FileRunState.Timeout:
        return 'Timeout';
      case FileRunState.Canceled:
        return 'Canceled';
      case FileRunState.WaitingToRetry:
        return 'Waiting to retry';
      case FileRunState.NotFound:
        return '';
      case FileRunState.Skipped:
        return 'Skipped';
      case FileRunState.Paused:
        return 'Paused';
      case FileRunState.WaitingToExecute:
        return 'Waiting to execute';
      case FileRunState.CompletedWithIssues:
        return 'Succeeded with issues';
      default:
        assertUnreachable(status);
        return '';
    }
  };
  const jobRunStatusToFormattedText = (
    status: JobRunState,
    isDeleting: boolean = false
  ) => {
    switch (status) {
      case JobRunState.Failed:
        return (
          <span className="center-vertically job-status">
            {isDeleting ? (
              <Icon
                icon={SvgDeletePendingIcon}
                color="default"
                className="status-icon"
              />
            ) : (
              <Icon
                icon={SvgStatusError}
                color="negative"
                className="status-icon"
              />
            )}
            <TruncatedText>{t('JobRunStateFailed_Label')}</TruncatedText>
          </span>
        );
      case JobRunState.NotStarted:
        return (
          <span className="center-vertically job-status">
            {isDeleting ? (
              <Icon
                icon={SvgDeletePendingIcon}
                color="default"
                className="status-icon"
              />
            ) : (
              <Icon
                icon={SvgStatusPending}
                color="primary"
                className="status-icon"
              />
            )}
            <TruncatedText>{t('JobRunStatePending_Label')}</TruncatedText>
          </span>
        );
      case JobRunState.Queued:
        return (
          <span className="center-vertically job-status">
            {isDeleting ? (
              <Icon
                icon={SvgDeletePendingIcon}
                color="default"
                className="status-icon"
              />
            ) : (
              <Icon
                icon={SvgStatusPending}
                color="primary"
                className="status-icon"
              />
            )}
            <TruncatedText>{t('JobRunStateQueued_Label')}</TruncatedText>
          </span>
        );
      case JobRunState.InProgress:
        return (
          <span className="center-vertically job-status">
            {isDeleting ? (
              <Icon
                icon={SvgDeletePendingIcon}
                color="default"
                className="status-icon"
              />
            ) : (
              <Icon
                icon={SvgStatusRunning}
                color="primary"
                className="status-icon"
              />
            )}
            <TruncatedText>{t('JobStateInProgress_Label')}</TruncatedText>
          </span>
        );
      case JobRunState.Paused:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusWarning}
              color="warning"
              className="status-icon"
            />
            <TruncatedText>{t('JobStatePaused_Label')}</TruncatedText>
          </span>
        );
      case JobRunState.PartiallyCompleted:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusWarning}
              color="warning"
              className="status-icon"
            />
            <TruncatedText>
              {t('JobStatePartiallyCompleted_Label')}
            </TruncatedText>
          </span>
        );
      case JobRunState.Completed:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusSuccess}
              color="positive"
              className="status-icon"
            />
            <TruncatedText>
              {t(
                showNewJobStatusLabel
                  ? 'NewJobStateSucceeded_Label'
                  : 'JobStateSucceeded_Label'
              )}
            </TruncatedText>
          </span>
        );
      case JobRunState.Skipped:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusPass}
              color="primary"
              className="status-icon"
            />
            <TruncatedText>{t('JobStateSkipped_Label')}</TruncatedText>
          </span>
        );
      case JobRunState.Canceled:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusWarning}
              color="warning"
              className="status-icon"
            />
            <TruncatedText>{t('JobRunStateCanceled_Label')}</TruncatedText>
          </span>
        );
      case JobRunState.CompletedWithIssues:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusSuccess}
              color="positive"
              className="status-icon"
            />
            <TruncatedText>
              {t(
                showNewJobStatusLabel
                  ? 'NewJobRunStateCompletedWithIssues_Label'
                  : 'JobRunStateCompletedWithIssues_Label'
              )}
            </TruncatedText>
          </span>
        );
      case JobRunState.CompletedWithPaused:
        return (
          <span className="center-vertically job-status">
            <Icon
              icon={SvgStatusSuccess}
              color="positive"
              className="status-icon"
            />
            <TruncatedText>
              {t('JobRunStateCompletedWithPaused_Label')}
            </TruncatedText>
          </span>
        );
      case JobRunState.None:
        return <span>Unknown</span>;
      default:
        assertUnreachable(status);
        return <span>Unknown</span>;
    }
  };
  const jobRunStatusToFormattedTitle = (status: JobRunState) => {
    switch (status) {
      case JobRunState.Failed:
        return 'Failed';
      case JobRunState.NotStarted:
        return 'Starting';
      case JobRunState.Queued:
        return 'In a queue';
      case JobRunState.InProgress:
        return 'In progress';
      case JobRunState.PartiallyCompleted:
        return 'Partially succeeded';
      case JobRunState.Completed:
        return 'Success';
      case JobRunState.Skipped:
        return 'Skipped';
      case JobRunState.None:
        return '';
      case JobRunState.Canceled:
        return 'Canceled';
      case JobRunState.CompletedWithIssues:
        return 'Succeeded with issues';
      case JobRunState.CompletedWithPaused:
        return 'Succeeded with files paused';
      case JobRunState.Paused:
        return 'Paused';
      default:
        assertUnreachable(status);
        return '';
    }
  };
  const jobRunTriggerTypeToFormattedText = (
    triggerType: JobRunTriggerType,
    t: TFunction
  ) => {
    switch (triggerType) {
      case JobRunTriggerType.Scheduled:
        return t('JobTriggerType_Scheduled_Label');
      case JobRunTriggerType.Manual:
        return t('JobTriggerType_Manual_Label');
      default:
        assertUnreachable(triggerType);
        return '';
    }
  };
  return {
    fileRunStatusToFormattedText,
    fileRunStatusToFormattedTitle,
    jobRunStatusToFormattedText,
    jobRunStatusToFormattedTitle,
    jobRunTriggerTypeToFormattedText,
  };
};

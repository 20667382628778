import * as React from 'react';

export const SvgFiletypeIfc = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      fill="#9f116e"
      d="m6.5 6.4l-.3-.2c-.6-.5-1.6-.5-2.2 0l-3.2 3.2c-.6.6-.6 1.6 0 2.2l3.9 3.9c.6.6 1.5.6 2.1 0l1.3-1.3.1-.1-1.3-1.3-1.1 1.1-3.4-3.4m4.4-1.1l3 3 .2-.2c.6-.6.6-1.5 0-2.1-.6-.7-1.3-1.3-2-2"
    />
    <path
      fill="#18879c"
      d="m14.3 7.9l-1.3 1.3.1.1.9.9c.1.1.1.1 0 .2l-3.2 3.2c-.1.1-.1.1-.2 0l-3.7-3.8c-.1 0-.2-.2-.3-.2-.1 0-.2.1-.2.2-.6.6-.6 1.5 0 2.1l3.2 3.2c.6.6 1.6.6 2.1 0l3.9-3.9.1-.1c.5-.6.4-1.5-.1-2.1l-1.3-1.1m-4.7 1.3l3-3-.3-.3c-.6-.5-1.4-.5-2 .1l-1.9 1.8-.1.1 1.3 1.3"
    />
    <path
    fill="#d41726"
      d="m10.5 1.9l3.4 3.4-4.1 4.1.2.2c.6.6 1.6.6 2.2 0l3.2-3.2c.6-.6.6-1.6 0-2.2l-3.8-3.8c-.6-.6-1.5-.6-2.1 0-.5.4-1 .8-1.4 1.3l1.3 1.3m-1.3 4.7l1.3-1.3-3-3-.2.2c-.6.6-.7 1.6-.1 2.2 0 0 0 0 .1.1l1.9 1.8"
    />
    <path
      fill="#0b4386"
      d="m9.6 6.3l.3-.4c.5-.6.5-1.5-.1-2.1l-3.2-3.2c-.6-.6-1.5-.6-2.1 0-1.4 1.3-2.7 2.6-4 3.9-.6.6-.6 1.5 0 2.1.5.4 1 .9 1.4 1.3l1.3-1.3-1.1-1.1.1-.1 3.2-3.2c.1-.1.1-.1.2 0l3.9 3.9c0 .1.1.1.1.2m-6 3.3l.3.3c.4.4.9.5 1.4.4.3-.1.6-.2.8-.5l1.8-1.8-1.3-1.4-3 3"
    />
  </svg>
);
export default SvgFiletypeIfc;
  


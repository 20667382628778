import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import {
  JobLastRunStatus,
  jobLastRunsStatusesFromResponse,
} from '../../entities/jobLastRunStatus';
import { useCallback } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useGetJobsLastStatuses = (): [
  JobLastRunStatus[],
  boolean,
  any,
  (jobIds: string[]) => Promise<ResponseData<JobLastRunStatus[]>>
] => {
  const { buddiUrls } = useBuddiContext();
  const useDataSettings = { initialData: [], initialIsLoading: false };

  const [data, isLoading, error, fetchData] = useDataApi<JobLastRunStatus[]>(
    useDataSettings
  );

  const fetchJobsStatuses = useCallback(
    (jobIds: string[]) => {
      const body = JSON.stringify({ jobDefIds: jobIds });
      const requestData: RequestData<JobLastRunStatus[]> = {
        map: jobLastRunsStatusesFromResponse,
        method: 'POST',
        url: `${buddiUrls.orchestrationServiceUrl}/api/jobstatus`,
        body: body,
      };
      return fetchData(requestData);
    },
    [buddiUrls, fetchData]
  );

  return [data, isLoading, error, fetchJobsStatuses];
};

import { useDataApi } from '../useDataApi/useDataApi';
import { useCallback } from 'react';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useDocumentMapping = (
  iModelId: string,
  projectId: string
): [any, boolean, any, () => Promise<ResponseData<any>>] => {
  const { buddiUrls } = useBuddiContext();
  const url = `${buddiUrls.productSettingsServiceUrl}/v1.0/Application/2661/Context/${projectId}/iModel/${iModelId}/Setting/DocumentMapping/Documents`;

  const useDataSettings = { initialData: [], initialIsLoading: false };
  const [data, isLoading, error, fetchData] = useDataApi<any>(useDataSettings);

  const getDocumentMapping = useCallback(async () => {
    const briefcaseRequestData: RequestData<any> = {
      url: url,
      method: 'GET',
      map: response => response,
    };

    return fetchData(briefcaseRequestData);
  }, [url, fetchData]);

  return [data, isLoading, error, getDocumentMapping];
};

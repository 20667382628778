import { maxCharactersAllowedInJobName } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import { InputStatus } from '../useInputField/useInputField';

export const useJobNameValidation = (
  jobNames: string[],
  setInputField: (status: InputStatus, message: string) => void
) => {
  const { t } = useTranslation();
  const validate = (jobName: string) => {
    const trimedJobName = jobName.trim();
    if (!trimedJobName) {
      setInputField('negative', t('JobName_ErrorMessage_Empty'));
    } else if (jobName.length > maxCharactersAllowedInJobName) {
      setInputField(
        'negative',
        t('JobNameExceedsMaximumCharacters_ErrorMessage', {
          maxCharactersAllowedInJobName: maxCharactersAllowedInJobName,
        })
      );
    } else if (jobNames.indexOf(trimedJobName) !== -1) {
      setInputField('negative', t('ExistingJobName_ErrorMessage'));
    } else {
      setInputField(undefined, '');
    }
  };
  return { validate };
};

import React from 'react';
import './fullPageLoader.scss';
import { ProgressRadial } from '@itwin/itwinui-react';

interface FullPageLoaderProps {
  children?: React.ReactNode;
}

export const FullPageLoader = (props: FullPageLoaderProps) => {
  const { children } = props;
  return (
    <div className="full-page-loader center-content">
      <div className="loader">
        <ProgressRadial indeterminate />
      </div>
      {children}
    </div>
  );
};

import React from 'react';
import { Svg404 } from '@itwin/itwinui-illustrations-react';
import { NonIdealState } from '@itwin/itwinui-react';
import {
  ReportData,
  issueArticleOpenTelemetryDataType,
  syncReportOpenTelemetryDataType,
} from '@itwin/synchronization-report-react-dev';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { SynchronizationReportDefault } from '../synchronization-report-react-default/synchronization-report-react-default';
import { SynchronizationReportDev } from '../synchronization-report-react-dev/synchronization-report-react-dev';

type SynchronizationReportProps = {
  report: ReportData | null;
  syncReportOpenTelemtry?: syncReportOpenTelemetryDataType;
  issueArticleTelemtry?: issueArticleOpenTelemetryDataType;
  setIssueId?: (issueId: string) => void;
  setIsIssueFeedbackOpen?: (isOpen: boolean) => void;
};

export const SynchronizationReport = (props: SynchronizationReportProps) => {
  const { t } = useTranslation();
  const {
    report,
    syncReportOpenTelemtry,
    issueArticleTelemtry,
    setIssueId,
    setIsIssueFeedbackOpen,
  } = props;
  const { syncReportUIv3 } = useFeatureToggleContext();

  const handleServiceRequestClick = useCallback(() => {
    window.open('https://apps.bentley.com/srmanager/ProductSupport', '_blank');
  }, []);

  return report != null ? (
    syncReportUIv3 ? (
      <SynchronizationReportDev
        report={report}
        handleServiceRequestClick={handleServiceRequestClick}
        syncReportOpenTelemtry={syncReportOpenTelemtry}
        issueArticleTelemtry={issueArticleTelemtry}
        setIssueId={setIssueId}
        setIsIssueFeedbackOpen={setIsIssueFeedbackOpen}
      />
    ) : (
      <SynchronizationReportDefault
        report={report}
        handleServiceRequestClick={handleServiceRequestClick}
      />
    )
  ) : (
    <NonIdealState
      svg={<Svg404 />}
      heading={t('FullPageError_CouldNotGetReport_Message')}
    />
  );
};

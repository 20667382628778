import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './confirmDeleteFileModal.scss';
import { FullPageLoader } from '../fullPageLoader/fullPageLoader';
import { ModalProps } from '../../hooks/useModal/useModal';
import {
  Text,
  Button,
  Modal,
  ModalButtonBar,
  ModalProps as ModalPropsITwinUI,
  Radio,
} from '@itwin/itwinui-react';

export enum ConfirmationType {
  DeleteJob = 'DeleteJob',
  DeleteFiles = 'DeleteFiles',
}

type ConfirmActionModalProps = ModalProps & {
  title: ModalPropsITwinUI['title'];
  onConfirm: (deleteModels: boolean) => any;
  onCancel: () => any;
  type: ConfirmationType;
  doesJobHasSyncs?: boolean;
  noOfItemstoBeRemoved?: number;
};

export const ConfirmDeleteFilesModal = (props: ConfirmActionModalProps) => {
  const {
    onConfirm,
    onCancel,
    doesJobHasSyncs = true,
    noOfItemstoBeRemoved = 1,
    type,
    ...modalProps
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [shouldDelete, setShouldDelete] = useState(true);
  const isDeleteJob = type === ConfirmationType.DeleteJob;
  const confirmDeleteMessageForEmptyConnections = isDeleteJob
    ? noOfItemstoBeRemoved > 1
      ? t('ConfirmDeleteJobModal_MultipleDeleteDescription')
      : t('ConfirmDeleteJobModal_SingleDeleteDescription')
    : noOfItemstoBeRemoved > 1
    ? t('ConfirmDeleteFile_MultipleDeleteDescription')
    : t('ConfirmDeleteFile_SingleDeleteDescription');

  return (
    <Modal
      className="confirm-delete-files-modal"
      {...modalProps}
      onClose={onCancel}
    >
      <>
        {isLoading ? (
          <FullPageLoader>
            {t('ConfirmDeleteFileModal_Loader_Text')}
          </FullPageLoader>
        ) : doesJobHasSyncs ? (
          <>
            <Text variant="body" as="p">
              {t(
                isDeleteJob
                  ? 'ConfirmDeleteModal_Question'
                  : 'ConfirmDeleteFilesModal_Question'
              )}
            </Text>
            <Radio
              label={t(
                isDeleteJob
                  ? 'ConfirmDeleteFileModal_QuestionOption2'
                  : 'ConfirmDeleteFilesModal_QuestionOption1'
              )}
              name="schedule-sync"
              disabled={false}
              checked={shouldDelete}
              onChange={() => {
                setShouldDelete(!shouldDelete);
              }}
            />
            <Radio
              label={t('ConfirmDeleteFileModal_QuestionOption1')}
              name="schedule-sync"
              disabled={false}
              checked={!shouldDelete}
              onChange={() => {
                setShouldDelete(!shouldDelete);
              }}
            />
          </>
        ) : (
          <Text variant="body" as="p">
            {confirmDeleteMessageForEmptyConnections}
          </Text>
        )}
        <ModalButtonBar>
          <Button
            onClick={async () => {
              setIsLoading(true);
              await onConfirm(doesJobHasSyncs ? shouldDelete : !shouldDelete);
              setIsLoading(false);
            }}
            styleType="high-visibility"
            disabled={isLoading}
          >
            {t(
              isDeleteJob || !doesJobHasSyncs
                ? 'DeleteJobBtn_Label'
                : shouldDelete
                ? 'DeleteJobBtn_Label'
                : 'UnmapJobBtn_Label'
            )}
          </Button>
          <Button onClick={onCancel}>{t('CancelBtn_Label')}</Button>
        </ModalButtonBar>
      </>
    </Modal>
  );
};

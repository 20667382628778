import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { useDeleteJobSchedule } from '../useDeleteJobSchedule/useDeleteJobSchedule';
import { getJobTokenType } from '../../services/bridgeLogic/bridgeLogic';
import { getJobPageAuthenticationCallbackUrl } from '../../services/uiLogic/urlLogic';
import { useCreateJobSchedule } from '../useCreateJobSchedule/useCreateJobSchedule';
import { useOrchestratorAuthenticateUser } from '../useOrchestratorAuthenticateUser/useOrchestratorAuthenticateUser';
import { useUserContext } from '../../context/userContext/userContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useUpdateScheduleV1 = (
  projectId: string,
  iModelId: string
): [
  any,
  boolean,
  any,
  (
    jobDefinition: JobDefinition,
    newSchedule: string,
    schedule: string
  ) => Promise<ResponseData<null>>
] => {
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();
  const { logFeature } = useUsageLogger();

  const useDataSettings = { initialIsLoading: false, initialData: null };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const userId = user!.profile.sub;
  const schedulerUrl = buddiUrls.iModelBridgeSchedulerUrl;

  const [, , , createSchedule] = useCreateJobSchedule(projectId, iModelId);
  const [, , , deleteJobSchedule] = useDeleteJobSchedule(projectId, iModelId);
  const [
    ,
    ,
    ,
    orchestratorAuthenticateUser,
  ] = useOrchestratorAuthenticateUser();

  const updateScheduleV1 = useCallback(
    (jobDefinition: JobDefinition, schedule: string) => {
      const url = `${schedulerUrl}/api/JobSchedule`;
      const body = getUpdateJobScheduleBody(
        projectId,
        iModelId,
        jobDefinition.id,
        userId,
        schedule
      );
      const requestData: RequestData<any> = {
        url: url,
        map: (x: any) => x,
        method: 'PUT',
        body: body,
      };

      logFeature(Features.EditJobSchedule);

      return fetchData(requestData);
    },
    [userId, schedulerUrl, projectId, iModelId, logFeature, fetchData]
  );

  const getScheduleEditFunction = (
    jobDefinition: JobDefinition,
    newSchedule: string,
    oldSchedule: string
  ) => {
    const hasNewSchedule = !!newSchedule;
    const hasScheduleCurrently = !!oldSchedule;
    const createNeeded = !hasScheduleCurrently && hasNewSchedule;
    if (createNeeded) {
      return async () => {
        const response = await createSchedule(jobDefinition.id, newSchedule);
        if (response.ok) {
          const jobTokenType = getJobTokenType(jobDefinition.repositoryType);
          const redirectUrl = getJobPageAuthenticationCallbackUrl(
            projectId,
            iModelId,
            jobDefinition.id
          );
          await orchestratorAuthenticateUser(jobTokenType, redirectUrl);
          return Promise.resolve(response);
        }
      };
    }

    const deleteNeeded = hasScheduleCurrently && !hasNewSchedule;
    if (deleteNeeded) {
      return async () => await deleteJobSchedule(jobDefinition.id);
    }

    const isUpdateNeeded =
      hasScheduleCurrently && hasNewSchedule && newSchedule !== oldSchedule;

    if (isUpdateNeeded) {
      return async () => await updateScheduleV1(jobDefinition, newSchedule);
    }

    return null;
  };

  const updateSchedule = async (
    jobDefinition: JobDefinition,
    newSchedule: string,
    schedule: string
  ) => {
    const fn = getScheduleEditFunction(jobDefinition, newSchedule, schedule);
    return fn != null && (fn() as any);
  };

  return [data, isLoading, error, updateSchedule];
};

export const getUpdateJobScheduleBody = (
  projectId: string,
  iModelId: string,
  jobDefinitionId: string,
  userId: string,
  schedule: string
) => {
  return JSON.stringify({
    jobdefId: jobDefinitionId,
    userId: userId,
    iModelId: iModelId,
    contextId: projectId,
    schedule: schedule,
  });
};

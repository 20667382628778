import React, { useEffect, useState } from 'react';
import { IModelPermissions } from '../../entities/rbacPermissions/rbacPermissions';
import { useRBACPermissionContext } from '../rbacPermissionContext/rbacPermissionContext';
import { useIModelPermissionContext } from '../iModelLevelPermissionContext/iModelLevelPermissionContext';
import { useUserContext } from '../userContext/userContext';
import { useBentleyContext } from '../bentleyContext/bentleyContext';

export const IModelPermissionContext = React.createContext<IModelPermissions>({
  canModifyiModel: false,
  canManageiModel: false,
});

export const IModelPermissionContextProvider = (props: any) => {
  const { iModelId } = useBentleyContext();
  const { user } = useUserContext();

  const [permissions, setPermissions] = useState<IModelPermissions>({
    canModifyiModel: false,
    canManageiModel: false,
  });

  const { canModifyProjectLevelPermission, canManageProjectLevelPermission } = useRBACPermissionContext();
  const IModelPermissions = useIModelPermissionContext();

  const managePermissions = async () => {
    return Object.keys(IModelPermissions).length !== 0
      ? {
        canModifyiModel: IModelPermissions.canManageIModelLevelPermission
            ? IModelPermissions.canManageIModelLevelPermission
            : IModelPermissions.canModifyIModelLevelPermission,
        canManageiModel: IModelPermissions.canManageIModelLevelPermission,
        }
      : {
        canModifyiModel: canManageProjectLevelPermission ? canManageProjectLevelPermission : canModifyProjectLevelPermission,
        canManageiModel: canManageProjectLevelPermission,
        };
  };

  useEffect(() => {
    if (iModelId && user?.access_token) {
      managePermissions().then(response => setPermissions(response));
    }
  }, [iModelId, user, IModelPermissions, canModifyProjectLevelPermission, canManageProjectLevelPermission]);

  return <IModelPermissionContext.Provider value={{ ...permissions }} {...props} />;
};

export const usePermissionContext = () => {
  const context = React.useContext(IModelPermissionContext);
  if (context == null) {
    throw new Error('permissionContext must be used inside provider');
  }
  return context;
};

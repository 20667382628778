import {
  embeddedFileBasenameBridgeArgs,
  embeddedFileBasenameRegexV2,
  fileBasenameRegexV2,
} from '../../constants/constants';
import { getBridgeRepositoryType } from '../../services/bridgeLogic/bridgeLogic';
import { assertUnreachable } from '../../services/uiUtils/assertUnreachable';
import { BridgeRepositoryType, BridgeType } from '../bridge';
import {
  ScheduleInterval,
  getScheduleIntervalFromCronString,
} from '../cronSchedule';
import { Item } from '../item';
import { mapV2JobStatus } from '../jobRunStatus/jobRunStatusMapping';
import {
  RepositoryType,
  bridgeRepositoryTypeToRepositoryTypeMap,
} from '../repository';
import {
  ApiVersion,
  GetInputFileDto,
  GetJobDefinitionsV2Dto,
  InputFileWithNextDataLink,
  JobDefinition,
  JobDefinitionFile,
  JobFiles,
} from './jobDefinition';
import { RepositoryTypeV2 } from './jobDefinitionDTOv2';
import {
  JobDefBridgeAndAssignmentsDto,
  JobDefinitionDto,
  JobDefinitionPropertiesDto,
  JobFileDto,
} from './jobDefintionDTO';

export const responseToJobDefinitionsMap = (
  response: JobDefinitionDto[]
): JobDefinition[] => {
  return response.map(x => {
    const mappedFiles = mapDTOFilesToFiles(x);

    const bridgeArgs = mappedFiles.find(
      f =>
        f.bridgeArgs != null &&
        f.bridgeArgs[embeddedFileBasenameBridgeArgs] != null
    )?.bridgeArgs;

    return {
      id: x.id,
      uniqueSettingName: x.name,
      name: x.properties.name,
      files: mapDTOFilesToFiles(x),
      repositoryType: bridgeRepositoryTypeToRepositoryTypeMap(
        x.properties.repositoryType as BridgeRepositoryType
      ),
      repositoryUrl: x.properties.repositoryUrl,
      datasourceUrl: x.properties.datasourceUrl,
      iModelId: x.properties.iModelId,
      lastModified: x.properties.lastModified,
      userId: x.properties.userId,
      briefcaseId: x.properties.briefcaseId,
      clientId: x.properties.clientId,
      hostName: x.properties.hostName,
      bridgeParameters: bridgeArgs != null ? bridgeArgs : {},
      scheduleInfo: null,
      scheduledTimeUtc: null,
      scheduleStartTimeUtc: null,
      lastRunDetails: null,
      apiVersion: ApiVersion.v1,
      isDeletePending: false,
      version: '1.0',
      isActive: x.properties.isActive != null ? x.properties.isActive : true,
    };
  });
};

export const mapDTOFilesToFiles = (
  dto: JobDefinitionDto
): JobDefinitionFile[] => {
  return dto.properties.files.map((dtoFile: JobFileDto, index: number) => {
    return {
      file: { id: dtoFile.guid, name: dtoFile.name } as Item,
      isSpatialRoot: dto.properties.spatialRootId === index,
      bridgeType: dto.properties.bridges[0].bridge as BridgeType,
      bridgeArgs: dtoFile.bridgeArgs,
    };
  });
};

export const responseToBridgeMap = (
  response: any
): JobDefBridgeAndAssignmentsDto =>
  ({
    bridge: response.bridge,
    fileBaseNames: response.fileBaseNames,
  } as JobDefBridgeAndAssignmentsDto);

export const jobDefinitionToJobDefinitionPropertiesDtoMap = (
  job: JobDefinition
): JobDefinitionPropertiesDto => {
  const spatialRootIndex = job.files.findIndex(
    (f: JobDefinitionFile) => f.isSpatialRoot
  );
  return {
    name: job.name,
    iModelId: job.iModelId,
    files: mapJobDefinitionToJobFilesDto(job.files, job.bridgeParameters),
    spatialRootId: spatialRootIndex,
    bridges: mapJobDefinitionFileToBridgesDto(job.files),
    repositoryType: getBridgeRepositoryType(job.repositoryType),
    repositoryUrl: job.repositoryUrl,
    datasourceUrl: job.datasourceUrl,
    lastModified: job.lastModified,
    userId: job.userId,
    briefcaseId: job.briefcaseId,
    clientId: job.clientId,
    hostName: job.hostName,
  };
};

export const mapJobDefinitionFileToBridgesDto = (
  fileBridgeMap: JobDefinitionFile[]
): JobDefBridgeAndAssignmentsDto[] => {
  const bridges = fileBridgeMap
    .map(pair => pair.bridgeType)
    .filter((bridge, i, list) => list.indexOf(bridge) === i);

  return bridges.map(bridge => {
    return {
      bridge: bridge,
      fileBaseNames: fileBridgeMap
        .filter(pair => pair.bridgeType === bridge)
        .map(pair => pair.file.name),
    };
  });
};

export const mapJobDefinitionToJobFilesDto = (
  jdFiles: JobDefinitionFile[],
  bridgeArguments: any
): JobFileDto[] => {
  return jdFiles.map(jdFile => {
    return {
      name: jdFile.file.name,
      guid: jdFile.file.id,
      transform: null,
      bridgeArgs: bridgeArguments,
    };
  });
};

// v2
export const responseV2ToJobDefinitionsMap = (
  response: GetJobDefinitionsV2Dto,
  enableSyncErrorTotals: boolean = false
): JobDefinition[] => {
  return response.value
    .map(x => ({
      id: x.id,
      uniqueSettingName: '',
      name: x.name,
      files: [],
      repositoryType: mapV2RepositoryToRepositoryType(x.repositoryType),
      repositoryUrl:
        x.repositoryType === RepositoryTypeV2.ProjectShare
          ? `${x?.projectShareLocation?.projectId}/folderId/FileId`
          : x.repositoryType === RepositoryTypeV2.Manifest
          ? 'null'
          : `pw:\\\\${x.projectWiseLocation?.fullDatasourceName}\\Documents\\D{fileId}`,
      datasourceUrl: (x.repositoryType === RepositoryTypeV2.ProjectShare
        ? x?.projectShareLocation?.projectId
        : x?.projectWiseLocation?.wsgProjectUrl) as string,
      iModelId: x.iModelId,
      lastModified: x.modifiedTimestamp,
      userId: x.ownerId,
      isScheduled: x.isScheduled,
      isSchedulePaused: x.isSchedulePaused,
      clientId: x.gprId,
      scheduleInfo: x.scheduleLoopIntervalInSeconds,
      scheduledTimeUtc: x.scheduledTimeUtc
        ? new Date(x.scheduledTimeUtc)
        : null,
      scheduleStartTimeUtc: x.scheduleStartTimeUtc
        ? new Date(x.scheduleStartTimeUtc)
        : null,
      lastRunDetails: x.lastRunDetails
        ? {
            startTimestamp: x.lastRunDetails.startTimestamp,
            processingStartTimeStamp : x.lastRunDetails.processingStartTimeStamp,
            state: mapV2JobStatus(
              x.lastRunDetails.state,
              x.lastRunDetails.result,
              x.lastRunDetails.taskDetails?.some(detail =>
                enableSyncErrorTotals && detail.totalErrorsCount
                  ? detail.totalErrorsCount > 0
                  : detail.type === 'BadgersReportReferenceError'
              ) || false
            ),
          }
        : null,
      isDeletePending: x.isDeletePending,
      apiVersion: ApiVersion.v2,
      version: '1.0',
      executionStrategy: x.executionStrategy,
      createdTimeStamp: x.createdTimestamp,
      bridgeParameters: x.bridgeParameters,
      isActive: true,
    }))
    .sort(sortJobsByCreatedTimeStamp);
};

export const responseV2ToJobDefinitionsCount = (response: any): any => {
  return response['@odata.count'];
};

export const mapV1BridgeArgsToV2BridgeArgs = (bridgeArgs: any): any => {
  return {
    [embeddedFileBasenameRegexV2]:
      bridgeArgs.iModelBridge_MatchOnEmbeddedFileBasename,
    ...(bridgeArgs.iModelBridge_MatchOnFileBasename
      ? { [fileBasenameRegexV2]: bridgeArgs.iModelBridge_MatchOnFileBasename }
      : {}),
  };
};

export const mapJobDefinitionFilesToJobFiles = (
  jdFiles: JobDefinitionFile[]
): JobFiles => {
  const spatialMaster = jdFiles.find((file: any) => file.isSpatialRoot);
  return {
    spatialMaster: spatialMaster
      ? ({
          parentId: spatialMaster.id,
          name: spatialMaster.file.name,
          id: spatialMaster.file.id,
        } as Item)
      : undefined,
    masters: jdFiles
      .filter(conn => spatialMaster?.file.id !== conn.file.id)
      .map(conn => {
        return {
          parentId: conn.id,
          name: conn.file.name,
          id: conn.file.id,
        } as Item;
      }),
    sheets: [],
  } as JobFiles;
};

export const mapInputFileDtoToJobDefinitionFile = (
  response: GetInputFileDto
): JobDefinitionFile[] => {
  return response.value.map((x, i) => ({
    id: x.id,
    file: { id: x.fileId, name: x.lastKnownFileName } as Item,
    isSpatialRoot: x.isSpatialRoot,
    bridgeType: x.iModelBridgeType,
    bridgeArgs: {} as any,
    executionAction: x.executionAction,
  }));
};

export const mapInputFileDtoToJobDefinitionFileWithNextDataLink = (
  response: any
): InputFileWithNextDataLink => {
  const file = response.value.map((x: any) => ({
    id: x.id,
    file: { id: x.fileId, name: x.lastKnownFileName } as Item,
    isSpatialRoot: x.isSpatialRoot,
    bridgeType: x.iModelBridgeType,
    bridgeArgs: {} as any,
    executionAction: x.executionAction,
  }));
  return { files: file, nextDataLink: response['@odata.nextLink'] };
};

export const mapV2RepositoryToRepositoryType = (
  repoV2: RepositoryTypeV2
): RepositoryType => {
  switch (repoV2) {
    case RepositoryTypeV2.ProjectShare:
      return RepositoryType.PROJECTSHARE;
    case RepositoryTypeV2.ProjectWiseWsg:
      return RepositoryType.PWDI;
    case RepositoryTypeV2.ProjectWiseLegacy:
      return RepositoryType.PWDI_LEGACY;
    case RepositoryTypeV2.PW365:
      return RepositoryType.PW365;
    case RepositoryTypeV2.Manifest:
      return RepositoryType.MANIFEST;
    default:
      assertUnreachable(repoV2);
      return RepositoryType.PROJECTSHARE;
  }
};

export const mapV1ScheduleToV2Schedule = (scheduleCron: string): string => {
  const schedule = getScheduleIntervalFromCronString(scheduleCron);
  switch (schedule) {
    case ScheduleInterval.Every4Hours:
      return '14400';
    case ScheduleInterval.Daily:
      return '86400';
    case ScheduleInterval.Weekly:
      return '604800';
    default:
      return '';
  }
};

export const mapV1RepositoryTypeToV2Repository = (
  repositoryType: string
): RepositoryTypeV2 => {
  switch (repositoryType) {
    case RepositoryType.PROJECTSHARE:
      return RepositoryTypeV2.ProjectShare;
    case RepositoryType.PWDI:
      return RepositoryTypeV2.ProjectWiseWsg;
    case RepositoryType.PWDI_LEGACY:
      return RepositoryTypeV2.ProjectWiseLegacy;
    default:
      return RepositoryTypeV2.ProjectShare;
  }
};

export const sortJobsByCreatedTimeStamp = (
  job1: JobDefinition,
  job2: JobDefinition
) => {
  job2.createdTimeStamp =
    job2.createdTimeStamp != null ? job2.createdTimeStamp : '';
  job1.createdTimeStamp =
    job1.createdTimeStamp != null ? job1.createdTimeStamp : '';
  return job1.createdTimeStamp.localeCompare(job2.createdTimeStamp);
};

import { ModalProps } from '@itwin/itwinui-react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { FeedbackIntroductionModal } from '../../externalComponents/components/modals/feedbackIntroductionModal/feedbackIntroductionModal';
import { FeedbackModal } from '../../externalComponents/components/modals/feedbackModal/feedbackModal';
import { IssueArticleFeedbackModal } from '../../externalComponents/components/modals/issueArticleFeedbackModal/issueArticleFeedbackModal';
import { SynchronizationReport } from '../../externalComponents/components/synchronizationReport/synchronizationReport';
import { useFeatureToggleContext } from '../../externalComponents/context/featureToggleContext/featureToggleContext';
import { useBadgersReport } from '../../externalComponents/hooks/useBadgers/useBadgerReport';
import './synchronizationReportPage.scss';
import {
  FileRunState,
  JobRunState,
} from '../../externalComponents/entities/jobRunStatus/jobRunStatus';
import {
  issueArticleOpenTelemetryDataType,
  syncReportOpenTelemetryDataType,
} from '@itwin/synchronization-report-react-dev';
import { useUserContext } from '../../externalComponents/context/userContext/userContext';

interface StatusDetails {
  jobRunStatus?: JobRunState;
  fileRunStatus?: FileRunState;
  runId?: string;
}

interface TotalIssueCount {
  issueCount?: number;
  linkedIssueCount?: number;
}
type SynchronizationReportPageRoutedProps = ModalProps & {
  projectId: string;
  iModelId: string;
  synchronizationReportId: string;
  connectionId: string;
};

export const SynchronizationReportPage = (
  props: RouteComponentProps<SynchronizationReportPageRoutedProps>
) => {
  const {
    projectId,
    iModelId,
    path,
    synchronizationReportId,
    connectionId,
    ...modalProps
  } = props;
  const { syncReportUIv3Feedback, enableIssueArticleFeedbackModal } =
    useFeatureToggleContext();
  const [report, isLoading, , getDiagnosticsReport] = useBadgersReport(
    projectId!,
    iModelId!
  );
  const { user } = useUserContext();
  const [issueId, setIssueId] = useState('');
  const [isIssueFeedbackOpen, setIsIssueFeedbackOpen] = useState(false);
  const [showFeedbackIntroduction, setShowFeedbackIntroduction] =
    useState(true);
  const location = useLocation();
  const state = location?.state as StatusDetails;

  const [syncReportOpenEventTelemtry, setSyncReportOpenEventTelemtry] =
    useState<syncReportOpenTelemetryDataType>();
  const [issueArticleEventTelemtry, setIssueArticleEventTelemtry] =
    useState<issueArticleOpenTelemetryDataType>();

  useEffect(() => {
    const showFeedbackIntroductionValue = localStorage.getItem(
      'showFeedbackIntroduction'
    );
    if (showFeedbackIntroductionValue) {
      setShowFeedbackIntroduction(JSON.parse(showFeedbackIntroductionValue));
    }
  }, []);

  useEffect(() => {
    if (report != null) {
      setSyncReportOpenEventTelemtry({
        iModelId: iModelId || '',
        projectId: report?.context?.contextid || '',
        userEmail: user?.profile.email,
        runStatus: state?.jobRunStatus || '',
        taskStatus: state?.fileRunStatus || '',
        connectionId: connectionId || '',
        runId: state?.runId || '',
        reportId: synchronizationReportId || '',
        ultimateId: user?.profile.ultimate_site,
      });

      setIssueArticleEventTelemtry({
        iModelId: iModelId || '',
        projectId: report?.context?.contextid || '',
        userEmail: user?.profile.email,
        connectionId: connectionId || '',
        runId: state?.runId || '',
        reportId: synchronizationReportId || '',
        ultimateId: user?.profile.ultimate_site,
      });
    }
  }, [report]);

  useEffect(() => {
    getDiagnosticsReport(
      location.pathname.slice(
        location.pathname.lastIndexOf('/'),
        location.pathname.length
      )
    );
  }, [location.pathname, getDiagnosticsReport]);

  return syncReportUIv3Feedback && showFeedbackIntroduction ? (
    <FeedbackIntroductionModal
      onGotItClick={() => {
        localStorage.setItem('showFeedbackIntroduction', 'false');
        setShowFeedbackIntroduction(false);
      }}
      isOpen={true}
      onClose={modalProps.onClose!}
    />
  ) : (
    <div className="synchronization-page">
      {!isLoading ? (
        <SynchronizationReport
          report={report}
          syncReportOpenTelemtry={syncReportOpenEventTelemtry}
          issueArticleTelemtry={issueArticleEventTelemtry}
          setIsIssueFeedbackOpen={setIsIssueFeedbackOpen}
          setIssueId={setIssueId}
        />
      ) : (
        <FullPageLoader />
      )}
      <>
        <FeedbackModal
          isOpen={true}
          {...modalProps}
          title={''}
          children={undefined}
        />
        {enableIssueArticleFeedbackModal && (
          <IssueArticleFeedbackModal
            issueId={issueId}
            report={report}
            isOpen={isIssueFeedbackOpen}
            onClose={() => {
              setIsIssueFeedbackOpen(false);
              setIssueId('');
            }}
          />
        )}
      </>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import {
  getDailyCronExpressionForHour,
  getHourFromScheduleCronString,
  getWeekFromScheduleCronString,
  CRON_IGNORE,
} from '../../../../entities/cronSchedule';
import { TimePicker } from '../../../timePicker/timePicker';
import { useTranslation } from 'react-i18next';
import { Radio } from '@itwin/itwinui-react';

interface dailyJobScheduleSelectionProps {
  isSelected: boolean;
  setSchedule: (schedule: string) => void;
  schedule: string;
}

export const DailyJobScheduleSelection = (
  props: dailyJobScheduleSelectionProps
) => {
  const { isSelected, setSchedule, schedule } = props;
  const [hourIn24HrFormat, setHourIn24HrFormat] = useState('0');
  const { t } = useTranslation();

  useEffect(() => {
    if (!isSelected) {
      return;
    }
    const newHour = getHourFromScheduleCronString(schedule);
    const isWeekly = getWeekFromScheduleCronString(schedule) !== CRON_IGNORE;
    if (newHour !== CRON_IGNORE && !isWeekly) {
      setHourIn24HrFormat(newHour);
    }
  }, [isSelected, schedule]);

  const onTimeChanged = (timeIn24HrFormat: string) => {
    setHourIn24HrFormat(timeIn24HrFormat);
    setSchedule(getDailyCronExpressionForHour(timeIn24HrFormat));
  };

  return (
    <div className="schedule-option">
      <Radio
        label={t('JobScheduleDaily_Label')}
        name="schedule-sync"
        onChange={() => {
          setSchedule(getDailyCronExpressionForHour(hourIn24HrFormat));
        }}
        checked={isSelected}
      />
      <TimePicker
        disable={!isSelected}
        onTimeChanged={onTimeChanged}
        hourIn24HrFormat={hourIn24HrFormat}
      />
    </div>
  );
};

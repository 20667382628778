import { useCallback } from 'react';
import {
  embeddedFileBasenameBridgeArgs,
  embeddedFileBasenameRegexV2,
  fileBasenameBridgeArgs,
  fileBasenameRegexV2,
} from '../../externalComponents/constants/constants';
import { useAppContext } from '../../context/appContext/appContext';
import {
  ApiVersion,
  JobDefinition,
} from '../../externalComponents/entities/jobDefinition/jobDefinition';
import {
  RequestData,
  ResponseData,
} from '../../externalComponents/hooks/useDataApi/requestData';
import { useDataApi } from '../../externalComponents/hooks/useDataApi/useDataApi';
import { useUpdateJobDefinition } from '../../externalComponents/hooks/useUpdateJobDefinition/useUpdateJobDefinition';

export const useUpdateFileRegex = (
  projectId: string,
  iModelId: string,
  job: JobDefinition
): [
  boolean,
  any,
  (updatedRegex: string | null) => Promise<ResponseData<null>>
] => {
  const [, isLoadingV2, errorV2, updateDataV2] = useDataApi<null>({
    initialData: null,
    initialIsLoading: false,
  });
  const [, isLoadingV1, errorV1, updateDataV1] = useUpdateJobDefinition(
    projectId,
    iModelId
  );
  const { configuration } = useAppContext();

  const updateFileRegexV1 = useCallback(
    (updatedRegex: string | null) => {
      const updatedJob: JobDefinition = {
        ...job,
        bridgeParameters:
          updatedRegex === null
            ? {}
            : {
                [embeddedFileBasenameBridgeArgs]: updatedRegex,
                [fileBasenameBridgeArgs]: updatedRegex,
              },
      };
      return updateDataV1(updatedJob);
    },
    [updateDataV1, job]
  );

  const updateFileRegexV2 = useCallback(
    (updatedRegex: string | null) => {
      const updateBody =
        updatedRegex === null
          ? [
              ...([
                    {
                      path: '/bridgeParameters/fileBasenameRegex',
                      op: 'remove',
                    },
                  ]),
              {
                path: '/bridgeParameters/embeddedFileBasenameRegex',
                op: 'remove',
              },
            ]
          : job.bridgeParameters == null
          ? [
              {
                value: {
                  [embeddedFileBasenameRegexV2]: updatedRegex,
                  ...({ [fileBasenameRegexV2]: updatedRegex }),
                },
                path: '/bridgeParameters',
                op: 'replace',
              },
            ]
          : [
              ...([
                    {
                      value: updatedRegex,
                      path: '/bridgeParameters/fileBasenameRegex',
                      op: 'replace',
                    },
                  ]),
              {
                value: updatedRegex,
                path: '/bridgeParameters/embeddedFileBasenameRegex',
                op: 'replace',
              },
            ];
      const requestData: RequestData<any> = {
        method: 'PATCH',
        url: `${configuration.buddiUrls.iModelBridgeService}/api/ConnectionDefinitions('${job.id}')?contextId=${projectId}&iModelId=${iModelId}`,
        body: JSON.stringify(updateBody),
        map: x => x,
      };

      return updateDataV2(requestData);
    },
    [
      configuration.buddiUrls.iModelBridgeService,
      job.id,
      projectId,
      iModelId,
      updateDataV2,
    ]
  );

  return job.apiVersion === ApiVersion.v2
    ? [isLoadingV2, errorV2, updateFileRegexV2]
    : [isLoadingV1, errorV1, updateFileRegexV1];
};

import {
  JobDefinitionFile,
  JobFiles,
  LastRunDetails,
} from '../../entities/jobDefinition/jobDefinition';
import { Repository, RepositoryType } from '../../entities/repository';
import { Item } from '../../entities/item';
import { useTranslation } from 'react-i18next';
import { NavigatableCellIUI } from '../navigatableCell/navigatableCell';
import { getItemIcon } from '../itemIcon/itemIcon';
import {
  getColumnHeaderIUI,
  getTextColumnIUI,
} from '../../services/uiUtils/tableColumnWrappers';
import { SpatialRoot } from '../../entities/spatialRootSetting';
import './jobDefinitionFilesTable.scss';
import { FlexTableWrapper } from '../flexTableWrapper/flexTableWrapper';
import { SpatialRootIcon } from '../spatialRootIcon/spatialRootIcon';
import { SvgDeletePendingIcon } from '../icons/SvgDeletePending';
import { IconButton, Tooltip, Table } from '@itwin/itwinui-react';
import { Icon } from '../icons/icon';
import { SvgUnlink } from '@itwin/itwinui-icons-react';
import { CellProps, Column } from 'react-table';
import React, { useMemo } from 'react';

export interface JobDefinitionFilesTableProps {
  existingSpatial?: SpatialRoot;
  jobFiles: JobFiles;
  repository: Repository;
  editable: boolean;
  itemsToBeRemoved?: { [id: string]: string };
  isTreeLoading: boolean;
  onRemoveCompositeModelClick?: (item: Item) => void;
  inputFiles?: JobDefinitionFile[];
  isV2Job?: boolean;
  lastRunDetails?: LastRunDetails | null;
}

export const JobDefinitionFilesTable = (
  props: JobDefinitionFilesTableProps
) => {
  const {
    existingSpatial,
    jobFiles,
    editable,
    itemsToBeRemoved,
    isTreeLoading = false,
    onRemoveCompositeModelClick,
    inputFiles,
    repository,
    isV2Job,
    lastRunDetails,
  } = props;

  const { t } = useTranslation();

  const files = [
    ...(jobFiles.spatialMaster ? [jobFiles.spatialMaster] : []),
    ...jobFiles.masters,
  ];

  const getCompositeModelsColumns = useMemo(
    (): Column<Item>[] => [
      {
        ...getColumnHeaderIUI<Item, 'name'>(
          t('JobDefinitionTableHeader_CompositeModel'),
          'name'
        ),
        Cell: ({ row: { original } }: CellProps<Item, any>) => (
          <NavigatableCellIUI
            name={original.name}
            leftIcon={getItemIcon(original)}
            isItemDeleted={original.isDeleted}
          />
        ),
        minWidth: 150,
      },
      ...((repository?.type === RepositoryType.PWDI ||
        repository?.type === RepositoryType.PWDI_LEGACY) &&
      isV2Job
        ? [
            {
              ...getTextColumnIUI<any, 'version'>(
                t('VersionColumnHeader_Label'),
                'version'
              ),
              Cell: ({ row: { original } }: CellProps<Item, any>) => (
                <NavigatableCellIUI name={original.version} />
              ),
              sortDescFirst: true,
              minWidth: 150,
            },
          ]
        : []),
      {
        id: 'spatialRoot',
        cellClassName: 'iui-slot',
        columnClassName: 'iui-slot',
        Cell: ({ row: { original } }: CellProps<Item, any>) =>
          original.id === existingSpatial?.id ? (
            <SpatialRootIcon
              toolTipText={t('SpatialIcon_BridgAssigment_Tooltip')}
            />
          ) : null,
        minWidth: 40,
        maxWidth: 40,
      },
      ...(editable
        ? [
            {
              id: 'chang-mappings-actions',
              cellClassName: 'iui-slot',
              columnClassName: 'iui-slot',
              Cell: ({ row: { original } }: CellProps<Item, any>) => {
                const inputFileIds = inputFiles!.map(y => y.file.id);
                const isFileMapped = inputFileIds.includes(original.id);
                return itemsToBeRemoved &&
                  itemsToBeRemoved[original.id] != null ? (
                  <Tooltip content={t('DeleteFilePending_Title')}>
                    <div>
                      <Icon
                        icon={SvgDeletePendingIcon}
                        color="default"
                        className="job-files-delete-file-flag"
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip content={t('UnmapFile_Tooltip')}>
                    <div>
                      <IconButton
                        onClick={() => onRemoveCompositeModelClick!(original)}
                        styleType="borderless"
                        size="small"
                        disabled={
                          (isV2Job &&
                            original.id !== existingSpatial?.id &&
                            !isFileMapped) ||
                          (isV2Job &&
                            original.id === existingSpatial?.id &&
                            !lastRunDetails)
                        }
                        className="job-files-unlink-mapping-button"
                      >
                        <SvgUnlink data-testid="svg-unmap-icon" />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
              },
              maxWidth: 64,
              minWidth: 64,
            },
          ]
        : []),
    ],
    []
  );

  return (
    <>
      <div className={'job-files-table'}>
        <FlexTableWrapper>
          {
            <Table<{ [P in keyof Item]: Item[P] }>
              className="iui-condensed"
              data={files}
              columns={getCompositeModelsColumns}
              isLoading={isTreeLoading}
              emptyTableContent={
                isTreeLoading
                  ? ''
                  : inputFiles?.length === 0
                  ? t('NoFilesSelected_ChangeMapping_Message')
                  : t('FailedToLoadReferencesCanSkip')
              }
            />
          }
        </FlexTableWrapper>
      </div>
    </>
  );
};

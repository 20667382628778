import { useMemo, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { parse } from 'query-string';
import { useRunJob } from '../../externalComponents/hooks/useRunJob/useRunJob';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { useTrackFeature } from '../../externalComponents/hooks/useTrackFeature/useTrackFeature';
import { Features } from '../../externalComponents/context/usageLoggerContext/featureConstants';
import { JobDefinition } from '../../externalComponents/entities/jobDefinition/jobDefinition';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../externalComponents/services/uiUtils/navigation';

export interface RunJobAuthenticationCallbackPageRoutedProps {}

export interface RunJobAuthenticationCallbackPageProps {
  projectId: string;
  iModelId: string;
  jobs: JobDefinition[];
  areJobsFetched: boolean;
}

export const RunJobAuthenticationCallbackPage = (
  props: RouteComponentProps<RunJobAuthenticationCallbackPageProps> &
    RunJobAuthenticationCallbackPageProps
) => {
  useTrackFeature(Features.JobRunAuthenticationRedirect);
  const { t } = useTranslation();
  const { projectId, iModelId, location, jobs, areJobsFetched } = props;
  const queryString = location!.search;
  const { jobId } = useMemo(
    () => parse(queryString) as { jobId: string },
    [queryString]
  );
  const redirectUrl = `/${projectId}/${iModelId}/${jobId}`;
  const [, , , runJob] = useRunJob(projectId, iModelId);

  useEffect(
    () => {
      if (!areJobsFetched) {
        return;
      }
      const runJobAndRedirect = async () => {
        const job = jobs.find(j => j.id === jobId);
        if (job) {
          await runJob(job);
        }
        navigate(redirectUrl, true, props.navigate!);
      };

      runJobAndRedirect();
    },
    // don't add props.navigate to deps as it causes rerenders
    // https://github.com/reach/router/issues/265
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areJobsFetched]
  );

  return (
    <FullPageLoader>
      {t('FullPage_StartSynchronization_Message')}
    </FullPageLoader>
  );
};

import './emptyIModelsList.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@itwin/itwinui-react';
import { SvgImodel } from '@itwin/itwinui-icons-react';
import { Icon } from '../../externalComponents/components/icons/icon';

interface EmptyIModelsListProps {
  onCreateIModelClick: () => any;
}

export const EmptyIModelsList = (props: EmptyIModelsListProps) => {
  const { onCreateIModelClick: onCreateIModel } = props;
  const { t } = useTranslation();

  return (
    <div className="empty-imodel-list fit-to-parent">
      <Icon
        icon={SvgImodel}
        color="default"
        className="imodel-icon"
        data-testid="svg-imodel"
      />

      <div className="create-new-imodel-text">
        <p className="bold-text">{t('ProjectWithNoIModels_Text')}</p>
        <p>{t('CreateNewIModelSuggestion_Text')}</p>
      </div>

      <div className="create-imodel-button">
        <Button
          styleType="high-visibility"
          onClick={onCreateIModel}
          title={t('CreateIModelBtn_Tooltip')}
        >
          {t('CreateIModelBtn_Label')}
        </Button>
      </div>
    </div>
  );
};

import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Layout from '../layout/layout';
import { useAppContext } from '../../context/appContext/appContext';
import { AuthenticateUserHandler } from '../authenticateUserHandler/authenticateUserHandler';

export const PrivateRoute = (props: RouteComponentProps<any>) => {
  const { component, ...rest } = props;
  const { isIFramed } = useAppContext();

  return (
    <>
      <Layout projectId={props.projectId} isIFramed={isIFramed}>
        {React.createElement(component, { ...rest })}
      </Layout>

      <AuthenticateUserHandler {...props} />
    </>
  );
};

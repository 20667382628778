import { Entity } from '../entity';
import { BridgeType } from '../bridge';
import { Item } from '../item';
import { RepositoryType } from '../repository';
import { BridgeArg } from './jobDefintionDTO';
import { ExecutionStrategy, RepositoryTypeV2 } from './jobDefinitionDTOv2';
import {
  DetailsDtoV2,
  JobRunStateDtoV2,
  ResultDtoV2,
} from '../jobRunStatus/jobRunStatusDTOv2';
import { JobRunDtoTriggerType } from '../jobRunStatus/jobRunStatusDTO';
import { JobRunState } from '../jobRunStatus/jobRunStatus';

export const productSettingsJobsNamespace = 'iModelJobs';
export const productSettingsSpatialRootNamespace = 'spatialRoot';
// from https://bentleycs.visualstudio.com/beconnect/_git/ORCSV?path=%2Fsrc%2FiModelBridgeScheduler%2Fcommon%2FJobDefConfiguration.ts&version=GBmaster&_a=contents
export const filterV2Jobs =
  "repositoryType+ne+'Manifest'+and+repositoryType+ne+'Url'";

export interface JobDefinition extends Entity {
  name: string;
  files: JobDefinitionFile[];
  repositoryType: RepositoryType;
  repositoryUrl: string;
  iModelId: string;
  datasourceUrl: string;
  lastModified: string;
  clientId: number;
  userId?: string;
  isScheduled?: boolean;
  isSchedulePaused?: boolean;
  hostName?: string;
  briefcaseId?: string;
  scheduleInfo: string | null;
  scheduledTimeUtc: Date | null;
  scheduleStartTimeUtc: Date | null;
  lastRunDetails: LastRunDetails | null;
  lastRunStatus?: any;
  apiVersion: ApiVersion;
  version: string; // legacy property from PSS
  uniqueSettingName: string; // legacy property from PSS
  isDeletePending: boolean;
  executionStrategy?: ExecutionStrategy;
  createdTimeStamp?: string;
  bridgeParameters?: any;
  isActive: boolean;
}

export interface LastRunDetails {
  startTimestamp: string;
  processingStartTimeStamp: string;
  state: JobRunState;
}

export interface InputFileWithNextDataLink {
  files: JobDefinitionFile[];
  nextDataLink: string;
}

export interface JobDefinitionFile {
  file: Item;
  id?: string;
  isSpatialRoot: boolean;
  bridgeType: BridgeType;
  bridgeArgs: any;
  executionAction?: ExecutionAction;
}

export enum ExecutionAction {
  Bridge = 'Bridge',
  UnMap = 'UnMap',
}

export interface JobFiles {
  spatialMaster: Item;
  masters: Item[];
  sheets: Item[];
}

export enum ApiVersion {
  v1 = 'v1',
  v2 = 'v2',
}

//v2
export interface GetJobDefinitionsV2Dto {
  value: JobDefinitionsValueDto[];
}

export interface JobDefinitionsValueDto {
  id: string;
  name: string;
  contextId: string;
  createdBy: string;
  createdTimestamp: string;
  iModelId: string;
  isScheduled: boolean;
  isSchedulePaused: boolean;
  modifiedBy: string;
  modifiedTimestamp: string;
  scheduleLoopIntervalInSeconds: string | null;
  scheduledTimeUtc: Date | null;
  scheduleStartTimeUtc: Date | null;
  gprId: number;
  ownerId: string;
  priorityLevel: string;
  repositoryType: RepositoryTypeV2;
  lastRunDetails: LastRunDetailsDto | null;
  projectShareLocation?: ProjectShareLocation;
  projectWiseLocation?: ProjectWiseLocation;
  isDeletePending: boolean;
  executionStrategy?: ExecutionStrategy;
  bridgeParameters?: BridgeArg;
}

export interface GetInputFileDto {
  value: InputFileDto[];
}

export interface InputFileDto {
  id: string;
  isSpatialRoot: boolean;
  lastAddedToSyncRunTimestamp: string;
  iModelBridgeType: BridgeType;
  fileId: string;
  lastKnownFileName: string;
  executionAction: ExecutionAction;
}

export interface ProjectShareLocation {
  projectId: string;
}

export interface ProjectWiseLocation {
  fullDatasourceName: string;
  wsgProjectUrl: string;
}

export interface LastRunDetailsDto {
  endTimeStamp: string;
  phase: string;
  result: ResultDtoV2;
  runId: string;
  startTimestamp: string;
  processingStartTimeStamp: string;
  state: JobRunStateDtoV2;
  triggerType: JobRunDtoTriggerType;
  triggeredBy: string;
  taskDetails?: DetailsDtoV2[];
}

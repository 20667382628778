import { SvgChevronRight, SvgFolder } from '@itwin/itwinui-icons-react';
import {
  Button,
  DropdownMenu,
  IconButton,
  MenuItem,
} from '@itwin/itwinui-react';
import * as React from 'react';
import { MenuAction } from '../jobView/toolbarActions';
import './breadcrumbs.scss';
import { Icon } from '../icons/icon';

export type IBreadCrumbs = {
  /**
   * Current layer of the file tree, top is 0
   */
  depth: number;
  /**
   * Labels of each layer
   */
  labels: string[];
  /**
   * Value passed to jumpTo when label is clicked
   */
  values: any[];
  /**
   * Function to call when a label is clicked
   */
  jumpTo: (...args: any[]) => any;
  /**
   * Disable Breadcrumbs navigation
   */
  disableBreadcrumbsNavigation: boolean;
} & React.HTMLAttributes<HTMLElement>;

/**
 * Rendered to the top left of a Table when history is set to true
 */
export const BreadCrumbs = (props: IBreadCrumbs) => {
  const { depth, jumpTo, labels, values, disableBreadcrumbsNavigation, ...rest } = props;

  if (depth > 0) {
    const breadCrumbs: MenuAction[] = [];

    for (let i: number = depth - 1; i >= 0; i--) {
      breadCrumbs.push({
        onClick: jumpTo(values[i], i),
        title: labels[i],
      });
    }
    return (
      <div {...rest}>
        <div className="tables-breadcrumbs">
          <DropdownMenu
            disabled={disableBreadcrumbsNavigation}
            menuItems={close =>
              breadCrumbs.map(bc => (
                <MenuItem
                  key={bc.title}
                  onClick={() => {
                    close();
                    bc.onClick();
                  }}
                >
                  {bc.title}
                </MenuItem>
              ))
            }
          >
            <IconButton styleType="borderless" size="small" aria-disabled={disableBreadcrumbsNavigation}>
              <SvgFolder />
            </IconButton>
          </DropdownMenu>
          <Button
            onClick={jumpTo(values[depth - 1], depth - 1)}
            role="button"
            tabIndex={0}
            data-testid="breadCrumbs-parent"
            title={labels[depth - 1]}
            styleType="borderless"
            size="small"
            disabled={disableBreadcrumbsNavigation}
          >
            <span className={disableBreadcrumbsNavigation ? "": "iui-anchor"}>{labels[depth - 1]}</span>
          </Button>
          <Icon icon={SvgChevronRight} size="small" className="chevron-icon" />
          <div data-testid="breadCrumbs-current">{labels[depth]}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div {...rest}>
        <div data-testid="breadCrumbs-current">{labels[0]}</div>
      </div>
    );
  }
};

export default BreadCrumbs;

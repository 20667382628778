import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-text-to-clipboard';
import { SvgCopy, SvgIssueReport } from '@itwin/itwinui-icons-react';
import { JobRun, JobRunState } from '../../entities/jobRunStatus/jobRunStatus';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { JobSchedule } from '../../entities/jobSchedule';
import { useFormatters } from '../../services/uiUtils/jobStatusFormatters';
import { useModal } from '../../hooks/useModal/useModal';
import { useToast } from '../../context/toastContext/toastContext';
import { ReportIssueModal } from '../reportIssueModal/reportIssueModal';
import { FlexTableWrapper } from '../flexTableWrapper/flexTableWrapper';
import {
  getWrappedTextCell,
  getColumnHeaderIUI,
  getTextColumnIUI,
} from '../../services/uiUtils/tableColumnWrappers';
import { getJobDetails } from '../connectionDefinitionRunStatusTable/getJobDetails/getJobDetails';
import {
  getTotalTimeDifferenceOfFromStringValues,
  utcTimeStringToFormattedDateTime,
} from '../../services/uiUtils/dateFormatter';
import {
  humanizedTimeFormatFunction,
  humanizedTitleForTimeFunction,
} from '../../services/uiUtils/timeFormatter';
import { ContextMenu } from '../contextMenu/contextMenu';
import { FileRunStatusTable } from '../fileRunTable/fileRunTable';
import { TruncatedText } from '../truncatedText/TruncatedText';
import { CollectDiagnosticsModal } from '../collectDiagnosticsModal/collectDiagnosticsModal';
import './runInformationStatusTable.scss';
import { useAppContext } from '../../../context/appContext/appContext';
import { Table } from '@itwin/itwinui-react';
import { CellProps, Column, Row } from 'react-table';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';

export const RunInformationStatusTable = (props: {
  projectId: string;
  iModelId: string;
  currentProjectName?: string;
  currentiModelName?: string;
  jobStatuses: JobRun[];
  jobDefinition?: JobDefinition;
  jobSchedule?: JobSchedule;
  showSpinner?: boolean;
  enableDownloadLogsOption?: boolean;
  runJobAndHandle: () => Promise<void>;
}) => {
  const {
    projectId,
    iModelId,
    currentProjectName,
    currentiModelName,
    jobStatuses,
    jobDefinition,
    jobSchedule,
    showSpinner,
    enableDownloadLogsOption,
    runJobAndHandle,
  } = props;

  const { t } = useTranslation();
  const { toastError, toastSuccess } = useToast();
  const [jobStatusDataForTable, setJobStatusDataForTable] =
    useState<JobRun[]>();

  const [, setIsJobRunning] = useState(false);
  const { modalProps: collectDiagnosticsModalProps } = useModal();
  const { shouldHideQueuedTime } = useFeatureToggleContext();

  const getContextMenuOptions = (jobRun: JobRun) => {
    const actions = [
      {
        title: t('ReportIssueBtn_Label'),
        onClick: () => {
          openReportJobModal(jobRun);
        },
        icon: <SvgIssueReport />,
      },
      {
        title: t('CopyIdBtn_Label'),
        onClick: () => copyIdToClipboard(jobRun.jobRunId),
        icon: <SvgCopy />,
      },
    ];

    return actions;
  };

  const [selectedJobRunToReport, setSelectedJobRunToReport] =
    useState<JobRun | null>();

  const openReportJobModal = (jobRun: JobRun) => {
    setSelectedJobRunToReport(jobRun);
    showReportJobModal();
  };

  const closeReportJobModal = () => {
    setSelectedJobRunToReport({} as any);
    hideReportJobModal();
  };
  const {
    modalProps,
    openModal: showReportJobModal,
    closeModal: hideReportJobModal,
  } = useModal();

  const copyIdToClipboard = (value: string) => {
    const copyToClipboardSuccessful = copy(value);
    if (!copyToClipboardSuccessful) {
      toastError(t('CopyIdToClipboardFailure_Toast'));
      return;
    }
    toastSuccess(t('CopyIdToClipboardSuccess_Toast'));
  };

  const { configuration } = useAppContext();

  const mapBuddiRegionToEnviromentName = (buddiRegion: string) => {
    switch (buddiRegion) {
      case '103':
        return 'DEV';
      case '102':
        return 'QA';
      case '0':
        return 'PROD';
      default:
        return '';
    }
  };

  const reportIssueModalData = {
    projectDetails: {
      projectName: currentProjectName,
      iModelName: currentiModelName,
      enviroment: mapBuddiRegionToEnviromentName(configuration.buddiRegion),
    },
    reportedJobRun: selectedJobRunToReport,
    jobDefinition: jobDefinition,
    jobSchedule: jobSchedule,
  };

  const {
    jobRunStatusToFormattedText,
    jobRunStatusToFormattedTitle,
    jobRunTriggerTypeToFormattedText,
  } = useFormatters();

  useEffect(() => {
    setJobStatusDataForTable(jobStatuses);
  }, [jobStatuses]);

  useEffect(() => {
    const jobStatusValues = jobStatuses.map(x => x.jobStatus);
    const isJobNotFinished =
      jobStatusValues.includes(JobRunState.Queued) ||
      jobStatusValues.includes(JobRunState.InProgress) ||
      jobStatusValues.includes(JobRunState.NotStarted);

    setIsJobRunning(isJobNotFinished);
  }, [jobStatuses]);

  const columns = useMemo(
    (): Column<JobRun>[] => [
      getTextColumnIUI<JobRun, 'jobStatus'>(
        t('JobRunStatusColumnHeader_Label'),
        'jobStatus',
        jobRunStatusToFormattedText,
        jobRunStatusToFormattedTitle,
        10
      ),
      {
        ...getColumnHeaderIUI<JobRun, 'details'>(
          t('JobRunDetailsColumnHeader_Label'),
          'details'
        ),
        Cell: ({ row: { original } }: CellProps<JobRun>) => {
          const jobDetails = getJobDetails(original);
          return (
            <>
              <TruncatedText>{jobDetails}</TruncatedText>
              {original.jobStatus !== JobRunState.InProgress &&
                original.knownIssueArticleLink && (
                  <a
                    className="jobStatusMore iui-anchor"
                    href={original.knownIssueArticleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('ConnectionStatusMoreInfo_Label')}
                  </a>
                )}
            </>
          );
        },
        minWidth: 10,
      },
      {
        ...getColumnHeaderIUI<any, ''>(
          t('JobRunDurationColumnHeader_Label'),
          ''
        ),
        Cell: ({ row: { original } }: CellProps<JobRun>) => {
          let totalTime;
          if (shouldHideQueuedTime && original.processingStartTime != null) {
            totalTime = getTotalTimeDifferenceOfFromStringValues(
              original.processingStartTime,
              original.endTime
            );
          } else {
            totalTime = getTotalTimeDifferenceOfFromStringValues(
              original.startTime,
              original.endTime
            );
          }
          if (!totalTime) return getWrappedTextCell('', '');
          return getWrappedTextCell(
            humanizedTimeFormatFunction(totalTime),
            humanizedTitleForTimeFunction(totalTime)
          );
        },
        minWidth: 10,
        maxWidth: 200,
      },
      {
        ...getColumnHeaderIUI<any, ''>(
          t('JobRunStartTimeColumnHeader_Label'),
          ''
        ),
        Cell: ({ row: { original } }: CellProps<JobRun>) => {
          let startTime = null;
          if (shouldHideQueuedTime)
          {
            if (original.jobStatus != JobRunState.Queued) {
              if (original.processingStartTime)
                startTime = original.processingStartTime;

              else if (!original.processingStartTime)
                startTime = original.startTime;
            }
          }
          else 
            startTime = original.startTime;

          return <TruncatedText>{utcTimeStringToFormattedDateTime(startTime)}</TruncatedText>
        },
        minWidth: 10,
        maxWidth: 200,
      },
      getTextColumnIUI<JobRun, 'endTime'>(
        t('JobRunEndTimeColumnHeader_Label'),
        'endTime',
        utcTimeStringToFormattedDateTime,
        utcTimeStringToFormattedDateTime,
        10,
        200
      ),
      getTextColumnIUI<JobRun, 'triggerType'>(
        t('JobTriggerTypeColumnHeader_Label'),
        'triggerType',
        value => (value ? jobRunTriggerTypeToFormattedText(value, t) : ''),
        value => (value ? jobRunTriggerTypeToFormattedText(value, t) : ''),
        10,
        200
      ),
      {
        accessor: 'jobRunId',
        cellClassName: 'iui-slot',
        columnClassName: 'iui-slot',
        maxWidth: 42,
        minWidth: 42,
        Cell: ({ row: { original } }: CellProps<JobRun>) => {
          return <ContextMenu menuOptions={getContextMenuOptions(original)} />;
        },
      },
      {
        id: 'space',
        cellClassName: 'iui-slot',
        columnClassName: 'iui-slot',
        maxWidth: 8,
        minWidth: 8,
      },
    ],
    []
  );

  return (
    <>
      <CollectDiagnosticsModal
        {...collectDiagnosticsModalProps}
        runJobAndHandle={runJobAndHandle}
      />
      <ReportIssueModal
        modalProps={{ ...modalProps, onClose: closeReportJobModal }}
        automaticallyCollectedIssueData={reportIssueModalData}
      />
      <FlexTableWrapper>
        <Table<{ [P in keyof JobRun]: JobRun[P] }>
          columns={columns}
          className="run-information-status-table"
          data={jobStatusDataForTable ? jobStatusDataForTable : []}
          isLoading={showSpinner && jobStatusDataForTable?.length === 0}
          emptyTableContent={showSpinner ? '' : t('NoJobRunsFound_Label')}
          autoResetExpanded={false}
          density="condensed"
          subComponent={(row: Row<JobRun>) => {
            const jobStatus = row.original.jobStatus;
            const isJobRunning =
              jobStatus === JobRunState.InProgress ||
              jobStatus === JobRunState.NotStarted;
            const fileRuns = row.original.fileRuns;
            const jobRunStatus = row.original.jobStatus;
            return fileRuns.length > 0 ? (
              <FileRunStatusTable
                projectId={projectId}
                iModelId={iModelId}
                projectName={currentProjectName}
                iModelName={currentiModelName}
                fileRuns={fileRuns}
                jobDefinition={jobDefinition}
                jobRunId={row.original.jobRunId}
                isJobRunning={isJobRunning}
                enableDownloadLogsOption={enableDownloadLogsOption}
                jobRunStatus={jobRunStatus}
              />
            ) : null;
          }}
        />
      </FlexTableWrapper>
    </>
  );
};

import { Entity } from './entity';

export interface SpatialRootSettingDto {
  id: string;
  name: string;
  namespace: string;
  properties: SpatialRootPropertiesDto;
}

export interface SpatialRootPropertiesDto {
  id: string;
  name: string;
  jobDefinitionId: string;
}

export interface SpatialRoot extends Entity {
  name: string;
  jobDefinitionId: string;
  parentName: string;
}

export const spatialRootSettingDtoToSpatialSettingMap = (
  response: SpatialRootSettingDto[]
) =>
  response.map(
    x =>
      ({
        parentName: x.name,
        id: x.properties.id,
        name: x.properties.name,
        jobDefinitionId: x.properties.jobDefinitionId,
      } as SpatialRoot)
  );

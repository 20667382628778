import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { Repository, RepositoryType } from '../../entities/repository';

export const getRepository = (
  jobDefinition: JobDefinition,
  contextId: string
): Repository => {
  if (jobDefinition.repositoryType === RepositoryType.PROJECTSHARE) {
    return {
      id: contextId,
      location: contextId,
      type: jobDefinition.repositoryType,
    } as Repository;
  } else if (
    (jobDefinition.repositoryType === RepositoryType.PWDI ||
      jobDefinition.repositoryType === RepositoryType.PWDI_LEGACY) &&
    jobDefinition.datasourceUrl !== ''
  ) {
    const dataSourceId = jobDefinition.datasourceUrl.split('/').pop();
    return {
      id: dataSourceId,
      location: jobDefinition.datasourceUrl,
      type: jobDefinition.repositoryType,
    } as Repository;
  }

  return {} as Repository;
};

import { iModel } from '../../externalComponents/entities/iModel';
import { RouteComponentProps } from '@reach/router';
import { EmptyIModelsList } from './emptyIModelsList';
import { IModelsList } from './iModelsList';
import { CreateNewIModelModal } from '../../components/createNewIModelModal/createNewIModelModal';
import { useModal } from '../../externalComponents/hooks/useModal/useModal';
import { useCreateIModel } from '../../hooks/useCreateIModel/useCreateIModel';
import { useToast } from '../../externalComponents/context/toastContext/toastContext';
import { useTrackFeature } from '../../externalComponents/hooks/useTrackFeature/useTrackFeature';
import { useTranslation } from 'react-i18next';
import { Features } from '../../externalComponents/context/usageLoggerContext/featureConstants';
import { navigate } from '../../externalComponents/services/uiUtils/navigation';

interface IModelHubProps {
  iModels: iModel[];
  projectId: string;
  refetchIModels: () => any;
  setAllIModels: (iModels: iModel[]) => void;
  error: any;
}

export const IModelHubView = (
  props: RouteComponentProps<{}> & IModelHubProps
) => {
  useTrackFeature(Features.ViewIModelsPage);

  const { iModels, projectId, refetchIModels, error } = props;
  const { toastError } = useToast();
  const { t } = useTranslation();

  const onNavigate = (iModelId: string) => {
    navigate(iModelId, true, props.navigate!);
  };

  const { modalProps, openModal, closeModal } = useModal();

  const [, , , createIModel] = useCreateIModel(projectId!);

  const onCreateIModel = async (name: string, description: string) => {
    const createResult = await createIModel(name, description);
    if (!createResult.ok) {
      toastError(
        t('iModelCreationFailure_Toast', { error: createResult.error })
      );
    }

    const refetchedIModels = await refetchIModels();
    if(refetchedIModels.length > iModels.length){
      
      props.setAllIModels(refetchedIModels);
    }
    closeModal();
  };

  return (
    <div className="imodels-page fit-to-parent">
      <CreateNewIModelModal
        {...modalProps}
        iModels={iModels}
        onCreateIModel={onCreateIModel}
      />
      {iModels.length > 0 ? (
        <IModelsList
          iModels={iModels}
          onNavigate={onNavigate}
          onCreateIModelClick={openModal}
        />
      ) : error ? (
        <div>{t('iModel_Fetch_Error')}</div>
      ) : (
        <EmptyIModelsList onCreateIModelClick={openModal} />
      )}
    </div>
  );
};

import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { iModelFileHierarchy } from '../../entities/jobRunStatus/jobRunStatusDTO';
import { MasterContext } from '../useGetMasterReferencesTree/useGetMasterReferencesTree';
import {
  AffinityFileType,
  AffinityReportEntry,
  mapBridgeTypeToProductStamp,
} from '../../entities/affinityReport';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import { ItemType } from '../../entities/item';

export const useCreateAffinityReport = (): [
  boolean,
  (
    jobDefId: string,
    mastersContext: MasterContext[]
  ) => Promise<ResponseData<any>>
] => {
  const { logFeature } = useUsageLogger();
  const useDataSettings = { initialData: {}, initialIsLoading: false };
  const [, creatingReport, , fetchData] =
    useDataApi<iModelFileHierarchy>(useDataSettings);

  const createReport = useCallback(
    (jobDefId: string, mastersContext: MasterContext[]) => {
      reportDataSetSize(mastersContext, jobDefId);

      const requestData: RequestData<iModelFileHierarchy> = {
        method: 'POST',
        url: `/api/AffinityReport/PostAffinity`,
        body: JSON.stringify({
          id: jobDefId,
          jsonString: getReportJson(mastersContext),
        }),
      };
      return fetchData(requestData);
    },
    [fetchData]
  );

  const reportDataSetSize = (
    masterContexts: MasterContext[],
    jobDefId: string
  ) => {
    let totalSize = 0;

    for (const masterContext of masterContexts) {
      Object.entries(masterContext.mastersMap).forEach(([, item]) => {
        totalSize += item.masterItem.fileSize;

        if (item.masterItem.references) {
          for (const reference of item.masterItem.references) {
            if (reference.itemType !== ItemType.LogicalSet) {
              totalSize += reference.fileSize;
            }
          }
        }
      });
    }

    logFeature(Features.TotalAffinityDataSetSize, [
      {
        name: 'totalSize',
        value: totalSize.toString(),
      },
      {
        name: 'jobDefId',
        value: jobDefId,
      },
    ]);
  };

  return [creatingReport, createReport];
};

export const getReportJson = (mastersContext: MasterContext[]) => {
  const results: AffinityReportEntry[] = [];

  mastersContext.forEach(rootMaster => {
    results.push({
      Affinity: {
        AuthoringProductStamp: mapBridgeTypeToProductStamp(
          rootMaster.mastersMap[rootMaster.master.id].bridgeSelection[
            rootMaster.master.id
          ].selectedBridge
        ),
        File: rootMaster.master.name,
        FileId: rootMaster.master.id,
        FileType: AffinityFileType.Master,
        MasterAffinity: '',
        MasterId: '',
      },
      Details: {
        ConfigurationVersion: '1.0.0',
        ErrorMessage: '',
        ExistingStampInFile: mapBridgeTypeToProductStamp(
          rootMaster.mastersMap[rootMaster.master.id].bridgeSelection[
            rootMaster.master.id
          ].selectedBridge
        ),
        FilePath: '',
        Processed: false,
        ReStamp: false,
        RuleType: '',
        Status: '',
        TimeStamp: '',
      },
    });

    Object.entries(rootMaster.mastersMap).forEach(([key, item]) => {
      item.masterItem.references?.forEach(ref => {
        results.push({
          Affinity: {
            AuthoringProductStamp: mapBridgeTypeToProductStamp(
              rootMaster.mastersMap[item.masterItem.id].bridgeSelection[ref.id]
                .selectedBridge
            ),
            File: ref.name,
            FileId: ref.id,
            FileType: AffinityFileType.Reference,
            MasterAffinity: mapBridgeTypeToProductStamp(
              rootMaster.mastersMap[rootMaster.master.id].bridgeSelection[
                rootMaster.master.id
              ].selectedBridge
            ),
            MasterId: rootMaster.master.id,
          },
          Details: {
            ConfigurationVersion: '1.0.0',
            ErrorMessage: '',
            ExistingStampInFile: mapBridgeTypeToProductStamp(
              rootMaster.mastersMap[item.masterItem.id].bridgeSelection[ref.id]
                .selectedBridge
            ),
            FilePath: '',
            Processed: false,
            ReStamp: false,
            RuleType: '',
            Status: '',
            TimeStamp: '',
          },
        });
      });
    });
  });

  return JSON.stringify({
    Results: results,
  });
};

import { useCallback } from 'react';
import {
  IModelRolesDto,
  UserRoles,
} from '../../entities/rbacPermissions/rbacPermissions';
import { RequestData } from '../useDataApi/requestData';
import { useDataApi } from '../useDataApi/useDataApi';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useUserContext } from '../../context/userContext/userContext';

export const useGetRbacUserRoles = (): [
  (projectId: string) => Promise<UserRoles[] | null>
] => {
  const { buddiUrls } = useBuddiContext();
  const { user } = useUserContext();
  const useDataSettings = {
    initialIsLoading: true,
    initialData: [],
  };
  const [, , , fetchData] = useDataApi<UserRoles[]>(useDataSettings);

  const getRbacUserRoles = useCallback(
    async (projectId: string) => {
      const requestData: RequestData<UserRoles[]> = {
        url: `${buddiUrls.rbacServiceUrl}/v2.5/Repositories/BentleyCONNECT--Main/RBAC/Project/${projectId}/User?$filter=$id+eq+'${user?.profile.sub}'&$select=*,Role.*`,
        method: 'GET',
        map: response =>
          response.instances.map((instance: IModelRolesDto) => {
            return {
              instanceId:
                instance.relationshipInstances[0].relatedInstance?.instanceId,
              name:
                instance.relationshipInstances[0].relatedInstance?.properties
                  .Name,
            };
          }),
      };
      const result = await fetchData(requestData);
      return result.data;
    },
    [fetchData]
  );

  return [getRbacUserRoles];
};

import React from 'react';
import {
  Svg401,
  Svg403,
  Svg404,
  Svg500,
  Svg502,
  Svg503,
  SvgError,
} from '@itwin/itwinui-illustrations-react';
import { NonIdealStateProps } from '@itwin/itwinui-react';

export const getNonIdealStateProps = (errorType: string) => {
  switch (errorType) {
    case '401':
      return {
        svg: <Svg401 />,
        heading: 'Unauthorized',
      } as NonIdealStateProps;
    case '403':
      return {
        svg: <Svg403 />,
        heading: 'Forbidden',
      } as NonIdealStateProps;
    case '404':
      return {
        svg: <Svg404 />,
        heading: 'Page not found',
      } as NonIdealStateProps;
    case '500':
      return {
        svg: <Svg500 />,
        heading: 'Internal server error',
      } as NonIdealStateProps;
    case '502':
      return {
        svg: <Svg502 />,
        heading: 'Bad gateway',
      } as NonIdealStateProps;
    case '503':
      return {
        svg: <Svg503 />,
        heading: 'Service unavailable',
      } as NonIdealStateProps;
    default:
      return {
        svg: <SvgError />,
        heading: 'An unknown error occurred',
      } as NonIdealStateProps;
  }
};

import { useGraphQl } from '../useGraphQL/useGraphQL';
import { responseToItemsMap, Item } from '../../entities/item';
import { Repository } from '../../entities/repository';
import { ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { getDocumentServiceRepositoryType } from '../../services/bridgeLogic/bridgeLogic';

export const useGetItems = (
  repository: Repository
): [
  Item[],
  boolean,
  any,
  (parentId: string) => Promise<ResponseData<Item[]>>
] => {
  const [data, isLoading, error, fetchData] = useGraphQl(responseToItemsMap, {
    initialData: [],
    initialIsLoading: true,
  });

  const fetchFolderItems = useCallback(
    (parentId: string) => {
      const query = getItemsGraphQLQuery(parentId, repository);
      return fetchData(query);
    },
    [repository, fetchData]
  );

  return [data, isLoading, error, fetchFolderItems];
};

const getItemsGraphQLQuery = (parentId: string, repository: Repository) => {
  const repoType = getDocumentServiceRepositoryType(repository.type);
  return `{item(input: {id: "${parentId}", type: ${repoType}, location: "${repository.location}"}) {id, name, children {name, id, isFolder, itemType, downloadUrl, parentId, version, description }} }`;
};

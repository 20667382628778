import { Entity } from './entity';

export enum ItemType {
  LogicalSet = 'LogicalSet',
  FlatSet = 'FlatSet',
  Document = 'Document',
  File = 'File',
  Folder = 'Folder',
  Project = 'Project',
}

export interface Item extends Entity {
  id: string;
  name: string;
  itemType: ItemType;
  isFolder: boolean;
  downloadUrl: string;
  parentId: string;
  description: string;
  version: string;
  fileSize: number;
  isDeleted?: boolean;
}

export const responseToItemsMap = (response: any): Item[] =>
  response.data.item.children;

export const responseSearchToItemsMap = (response: any): Item[] =>
  response.data.deepSearch == null ? [] : response.data.deepSearch;

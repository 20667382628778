import React, { useState, useEffect } from 'react';
import { TextLink } from '../../components/textLink/textLink';
import { ProgressRadial } from '@itwin/itwinui-react/cjs/core/ProgressIndicators';
import './transferOwnershipModal.scss';
import { IMSUserNameBadge } from '../../components/iModelUserNameBadge/iModelUserNameBadge';
import { useTranslation } from 'react-i18next';
import {
  ApiVersion,
  JobDefinition,
} from '../../entities/jobDefinition/jobDefinition';
import { useUpdateJobDefinition } from '../../hooks/useUpdateJobDefinition/useUpdateJobDefinition';
import { useToast } from '../../context/toastContext/toastContext';
import { COMMUNITIES_TRANSFER_OWNERSHIP } from '../../entities/repository';
import { useDeleteBriefcasesByDocumentMapping } from '../../hooks/useDeleteBriefcasesByDocumentMapping/useDeleteBriefcasesByDocumentMapping';
import { useGetInputFiles } from '../../hooks/useGetInputFiles/useGetInputFiles';
import { Button, Modal, ModalButtonBar } from '@itwin/itwinui-react';
import { useGetUsersWithPermission } from '../../hooks/useGetUserPermission/useGetUserWithPermission';
import { ModalProps } from '../../hooks/useModal/useModal';
import {
  iModelUser,
  useFetchiModelUsers,
} from '../../hooks/useFetchiModelUsers/useFetchiModelUsers';
import Typeahead from '../typehead/Typeahead';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';

interface TypeaheadOption {
  value: iModelUser;
  label: string;
}

interface TransferOwnershipModalProps extends ModalProps {
  iModelId: string;
  projectId: string;
  closeModal: () => void;
  onUserChange: () => void;
  jobDefinition: JobDefinition;
}

export const TransferOwnershipModal: React.FC<TransferOwnershipModalProps> = (
  props: TransferOwnershipModalProps
) => {
  const {
    iModelId,
    projectId,
    closeModal,
    onUserChange,
    jobDefinition,
    ...modalProps
  } = props;
  const currentOwnerId = jobDefinition.userId;

  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();
  const { logFeature } = useUsageLogger();

  const [users, isLoading, , fetchIModelUsers] = useFetchiModelUsers();
  const [, , , updateJobDefinition] = useUpdateJobDefinition(
    projectId,
    iModelId
  );
  const [cleanUpBriefcases] = useDeleteBriefcasesByDocumentMapping(
    projectId,
    iModelId
  );
  const [inputFiles, , , fetchInputFiles] = useGetInputFiles(projectId);
  const [getUserPermission] = useGetUsersWithPermission();

  const [filteredUsers, setFilteredUsers] = useState<iModelUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<iModelUser | undefined>();
  const [userLoading, setUserLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchIModelUsers(iModelId);
  }, [fetchIModelUsers, iModelId]);

  useEffect(() => {
    setSelectedUser(users.find(u => u.Id === currentOwnerId));
  }, [currentOwnerId, users]);

  useEffect(() => {
    if (jobDefinition.apiVersion === ApiVersion.v2) {
      fetchInputFiles(jobDefinition);
    }
  }, [fetchInputFiles, jobDefinition]);

  useEffect(() => {
    const filterUsersWithPermission = async () => {
      if (users.length === 0) {
        return;
      }

      setUserLoading(true);
      const usersWithManageIModelPermission = await getUserPermission(
        'IMHS_Manage_iModel'
      );
      const usersWithManageResourcesPermission = await getUserPermission(
        'IMHS_ManageResources'
      );
      const usersWithPermission = [
        ...new Set([
          ...usersWithManageIModelPermission,
          ...usersWithManageResourcesPermission,
        ]),
      ];
      setFilteredUsers(
        users.filter(user => {
          return (
            usersWithPermission.find(
              (userWithPermissionId: string) => userWithPermissionId === user.Id
            ) != null
          );
        })
      );
      setUserLoading(false);
    };
    filterUsersWithPermission();
  }, [users, getUserPermission]);

  const assignNewUser = async (
    newUser: iModelUser | undefined,
    jobDefinition: JobDefinition
  ): Promise<boolean> => {
    if (newUser == null || newUser.Id == null || newUser.Id === '') {
      return false;
    }

    jobDefinition.userId = newUser.Id;
    const result = await updateJobDefinition(jobDefinition);
    return result.ok;
  };

  const finishTransfer = async (success: boolean) => {
    success
      ? toastSuccess(t('TransferOwnership_Success_Toast'))
      : toastError(t('TransferOwnership_Failure_Toast'));

    closeModal();
    onUserChange();
  };

  const transferOwnership = async () => {
    const v1Files = jobDefinition.files.map(f => f.file.id);
    const v2Files = inputFiles.map(f => f.file.id);
    const fileIds: string[] = [...v1Files, ...v2Files];
    const previousOwnerId = jobDefinition.userId;

    await cleanUpBriefcases(fileIds, jobDefinition.userId as string);

    const assigned = await assignNewUser(selectedUser, jobDefinition);
    if (!assigned) {
      finishTransfer(false);
      return;
    }

    finishTransfer(true);
    logFeature(Features.ChangeOwner, [
      {
        name: 'previousOwnerId',
        value: previousOwnerId!,
      },
      {
        name: 'newOwnerId',
        value: selectedUser!.Id,
      },
    ]);
  };

  return (
    <Modal
      title={t('TransferOwnship_Model_Title')}
      className="transfer-ownership-modal"
      {...modalProps}
    >
      <>
        <div className="transfer-ownership-modal-message">
          <span>{t('TransferOwnship_Model_Message')}</span>
          <TextLink url={COMMUNITIES_TRANSFER_OWNERSHIP}>
            {t('TransferOwnship_Model_MessageLink')}
          </TextLink>
        </div>

        <Typeahead<TypeaheadOption>
          label={t('TransferOwnership_Label')!}
          options={filteredUsers.map(u => {
            return {
              label: u.Name + ' ' + u.Surname,
              value: u,
            };
          })}
          value={
            selectedUser && {
              value: selectedUser as iModelUser,
              label: selectedUser.Name + ' ' + selectedUser.Surname,
            }
          }
          placeholder={
            isLoading || userLoading
              ? t('TransferOwnship_Model_LoadingUsers')
              : t('TransferOwnship_Model_SelectUser')
          }
          onChange={(selected: any) => {
            setSelectedUser(selected?.value);
          }}
          closeMenuOnSelect
          isSearchable={!selectedUser}
          isClearable={!!selectedUser}
          isLoading={isLoading || userLoading}
          noOptionsMessage={(_: any) => {
            return t('TransferOwnship_Model_NoUsers');
          }}
          styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 1000 }),
          }}
          menuPortalTarget={document.getElementById(
            'bwc-react-portal-container'
          )}
          components={{
            LoadingIndicator: () => {
              return (
                <ProgressRadial
                  style={{ width: 20, height: 20 }}
                  indeterminate
                />
              );
            },
            Option: (props: any) => {
              const option = props.data as TypeaheadOption;
              return (
                <div
                  className="typeahead-option-container option"
                  onClick={() => {
                    props.selectOption(option);
                  }}
                >
                  <div className="user-name-container">
                    <IMSUserNameBadge
                      userInitials={
                        option.value.Name[0] + option.value.Surname[0]
                      }
                      email={option.value.Email}
                    />

                    <div className="transfer-ownership-add-user-container">
                      <span className="transfer-ownership-add-user-name">
                        {option.label}
                      </span>
                      <span className="transfer-ownership-add-user-email">
                        {option.value.Email}
                      </span>
                    </div>
                  </div>
                </div>
              );
            },
            SingleValue: (props: any) => {
              const option = props.data as TypeaheadOption;
              if (option.value) {
                return (
                  <div className="typeahead-option-container">
                    <div className="user-name-container">
                      <IMSUserNameBadge
                        userInitials={
                          option.value.Name[0] + option.value.Surname[0]
                        }
                        email={option.value.Email}
                      />

                      <div className="transfer-ownership-add-user-container">
                        <span className="transfer-ownership-add-user-name">
                          {option.label}
                        </span>
                        <span className="transfer-ownership-add-user-email">
                          {option.value.Email}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <>option.label</>;
              }
            },
          }}
        />
        <ModalButtonBar>
          <Button
            onClick={transferOwnership}
            styleType="high-visibility"
            disabled={!selectedUser || selectedUser.Id === currentOwnerId}
          >
            {t('ConfirmBtn_Label')}
          </Button>
          <Button onClick={closeModal}>{t('CancelBtn_Label')}</Button>
        </ModalButtonBar>
      </>
    </Modal>
  );
};

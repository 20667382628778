import { Entity } from '../../entities/entity';
import { useSelection } from '../useSelection/useSelection';

export const useItemListWithToggle = <T extends Entity>() => {
  const result = useSelection();
  const { isSelected, setSelected, selection } = result;

  const toggleIsSelected = (item: T) => {
    if (isSelected(item)) {
      const withoutToggledItem = selection.filter(x => x.id !== item.id);

      setSelected(withoutToggledItem);
      return;
    }

    const updatedSelectedItems = [...selection, item];
    setSelected(updatedSelectedItems);
  };

  return { ...result, toggleIsSelected };
};

import {
  JobRun,
  JobRunState,
  FileRunState,
} from '../../../entities/jobRunStatus/jobRunStatus';
import i18n from '../../../i18n/i18n';

export const getJobDetails = (original: JobRun) => {
  const totalFiles = original.fileRuns.length;
  let updatedJobDetails = '';

  if (original.jobStatus === JobRunState.PartiallyCompleted) {
    const failedFiles = sortFilesByStatus(original, FileRunState.Fail);
    const canceledFiles = sortFilesByStatus(original, FileRunState.Canceled);
    const succeededFiles = sortFilesByStatus(original, FileRunState.Succeeded);

    const failedFilesDetails =
      failedFiles.length === 1
        ? `1 of ${totalFiles} file${totalFiles === 1 ? '' : 's'} has failed.`
        : `${failedFiles.length} of ${totalFiles} files have failed.`;

    const canceledFilesDetails =
      canceledFiles.length === 1
        ? `1 file was canceled, ${succeededFiles.length} succeeded.`
        : `${canceledFiles.length} files were canceled, ${succeededFiles.length} succeeded.`;

    if (failedFiles.length > 0 && canceledFiles.length > 0) {
      updatedJobDetails =
        failedFilesDetails.replace('.', '') + ', ' + canceledFilesDetails;
    } else if (failedFiles.length > 0) {
      updatedJobDetails = failedFilesDetails;
    } else if (canceledFiles.length > 0) {
      updatedJobDetails = canceledFilesDetails;
    }
    return updatedJobDetails;
  } else if (original.jobStatus === JobRunState.Completed) {
    const skippedFiles = sortFilesByStatus(original, FileRunState.Skipped);
    const modifiedFiles = sortFilesByStatus(original, FileRunState.Succeeded);

    updatedJobDetails =
      modifiedFiles.length > 0 && skippedFiles.length > 0
        ? modifiedFiles.length === 1
          ? `1 modified file has been processed successfully.`
          : `${modifiedFiles.length} modified files of ${totalFiles} have been processed successfully.`
        : original.details;
    return updatedJobDetails;
  } else if (original.jobStatus === JobRunState.NotStarted) {
    const checkSuccededTasks = sortFilesByStatus(
      original,
      FileRunState.Succeeded
    );
    updatedJobDetails =
      checkSuccededTasks.length > 0 ? `In progress` : original.details;
    return updatedJobDetails;
  } else if (original.jobStatus === JobRunState.Failed) {
    const failedFiles = sortFilesByStatus(original, FileRunState.Fail);
    updatedJobDetails =
      failedFiles.length > 0 && failedFiles.length < totalFiles
        ? `${failedFiles.length} files of ${totalFiles} have failed.`
        : original.details;
    return updatedJobDetails;
  } else if (original.jobStatus === JobRunState.Skipped) {
    return totalFiles === 1
      ? i18n.t('FileSkipped_Message')
      : i18n.t('MultipleFilesSkip_Message');
  } else {
    return original.details;
  }
};

const sortFilesByStatus = (original: JobRun, status: FileRunState) => {
  return original.fileRuns
    .map(file => file.status)
    .filter(state => state === status);
};

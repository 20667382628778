import { useDataApi } from '../useDataApi/useDataApi';
import {
  UseDataSettings,
  RequestData,
  ResponseData,
} from '../useDataApi/requestData';
import { useCallback } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useGraphQl = <T>(
  map: (response: any) => T,
  requestInit: UseDataSettings<T>
): [
  T,
  boolean,
  any,
  (query: object | string, retry?: boolean) => Promise<ResponseData<T>>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings: UseDataSettings<T> = {
    initialData: requestInit.initialData,
    initialIsLoading: requestInit.initialIsLoading,
  };
  const [data, isLoading, error, fetchData] = useDataApi<T>(useDataSettings);

  const fetchGraphQl = useCallback(
    async (query: object | string, retry: boolean = true) => {
      const requestData: RequestData<T> = {
        url: `${buddiUrls.documentServiceUrl}graphql`,
        method: 'POST',
        body: JSON.stringify({ query }),
        map: map,
      };
      let response = await fetchData(requestData);

      if (retry) {
        for (
          let retryCount = 0;
          (!response.ok || response.error) && retryCount < 3;
          retryCount++
        ) {
          response = await fetchData(requestData);
        }
      }

      return response;
    },
    [map, buddiUrls, fetchData]
  );

  return [data, isLoading, error, fetchGraphQl];
};

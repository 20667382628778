import { useEffect, useState } from 'react';
import { AuthenticateContent } from './authenticateContent';
import { useOrchestratorAuthenticateUser } from '../../externalComponents/hooks/useOrchestratorAuthenticateUser/useOrchestratorAuthenticateUser';
import { TokenType } from '../../externalComponents/entities/repository';
import { RouteComponentProps } from '@reach/router';
import { AuthenticateUserHandler } from '../../components/authenticateUserHandler/authenticateUserHandler';
import { useAppContext } from '../../context/appContext/appContext';
import './authenticate.scss';

export enum AuthenticationState {
  Authenticating,
  Success,
  Redirected,
}

export const Authenticate = (props: RouteComponentProps) => {
  const [authenticationState, setAuthenticationState] = useState(
    AuthenticationState.Authenticating
  );
  const { user } = useAppContext();
  const [, , , authenticate] = useOrchestratorAuthenticateUser();

  useEffect(() => {
    const processAuthentication = async () => {
      setAuthenticationState(AuthenticationState.Authenticating);
      const isSuccess = await authenticate(
        TokenType.OIDC,
        window.location.href
      );
      setAuthenticationState(
        isSuccess ? AuthenticationState.Success : AuthenticationState.Redirected
      );
    };

    processAuthentication();
  }, [authenticate]);

  return (
    <>
      <AuthenticateUserHandler {...props} />
      {user && (
        <div className="authenticate-page">
          <AuthenticateContent authenticationState={authenticationState} />
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import range from 'lodash/range';
import './timePicker.scss';
import { Select } from '@itwin/itwinui-react';

interface timePickerProps {
  hourIn24HrFormat: string;
  disable: boolean;
  onTimeChanged: (timeIn24Hr: string) => void;
}

export const TimePicker = (props: timePickerProps) => {
  const { hourIn24HrFormat, disable, onTimeChanged } = props;
  const [hour, setHour] = useState(hourIn24HrFormat);

  useEffect(() => {
    const hourInt = Number(hourIn24HrFormat);
    setHour(hourInt.toString());
  }, [hourIn24HrFormat]);

  return (
    <div className="time-period-picker">
      <Select<string>
        data-testid="hour-select"
        className="time-select-short"
        onChange={value => {
          setHour(value);
          onTimeChanged(value);
        }}
        disabled={disable}
        value={hour}
        options={range(0, 23 + 1).map(x => ({
          label:
            x.toString().length === 1 ? '0'.concat(x.toString()) : x.toString(),
          value: x.toString(),
        }))}
      />
    </div>
  );
};

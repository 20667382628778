import { ModalProps } from '@itwin/itwinui-react';
import { useState } from 'react';

const defaultProps: UseModalProps = {
  initialIsOpen: false,
  extraModalProps: {},
};

interface UseModalProps {
  initialIsOpen: boolean;
  extraModalProps?: Partial<ModalProps>;
}

type ExtendedModalProps = Omit<ModalProps, 'title' | 'children'> &
  Required<Pick<ModalProps, 'isOpen' | 'onClose'>>;
export type { ExtendedModalProps as ModalProps };

export const useModal = (props: UseModalProps = defaultProps) => {
  const { initialIsOpen, extraModalProps } = props;
  const [isModalOpen, setIsModalOpen] = useState(initialIsOpen);

  const toggle = () => setIsModalOpen(!isModalOpen);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const modalProps: ExtendedModalProps = {
    modalRootId: 'modal-root',
    isOpen: isModalOpen,
    onClose: closeModal,
    closeOnEsc: true,
    closeOnExternalClick: true,
    isDismissible: true,
    ...extraModalProps,
  };

  return {
    modalProps,
    toggle,
    setIsModalOpen,
    isModalOpen,
    openModal,
    closeModal,
  };
};

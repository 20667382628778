import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useFlagConnectionForUnmap = (
  projectId: string,
  iModelId: string
): [boolean, (jobId: string) => Promise<ResponseData<null>>] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialData: null, initialIsLoading: true };
  const [, isLoading, , fetchData] = useDataApi<null>(useDataSettings);

  const flagConnectionForUnmap = useCallback(
    async (jobId: string) => {
      const requestData: RequestData<null> = {
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${jobId}')?contextId=${projectId}&iModelId=${iModelId}`,
        method: 'PATCH',
        body: JSON.stringify([
          {
            value: 'true',
            path: '/isDeletePending',
            op: 'replace',
          },
        ]),
      };

      return fetchData(requestData);
    },
    [buddiUrls, fetchData, iModelId, projectId]
  );

  return [isLoading, flagConnectionForUnmap];
};

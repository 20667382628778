import { useCallback } from 'react';
import { countOfJobStatusesToBeFetched } from '../../constants/constants';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import {
  ApiVersion,
  JobDefinition,
} from '../../entities/jobDefinition/jobDefinition';
import { JobRun } from '../../entities/jobRunStatus/jobRunStatus';
import {
  mapJobRunStatusDtoToJobRun,
  mapJobRunStatusV2DtoToJobRun,
} from '../../entities/jobRunStatus/jobRunStatusMapping';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useDataApi } from '../useDataApi/useDataApi';

export const useGetJobStatus = (): [
  JobRun[],
  boolean,
  any,
  (jobDefinition: JobDefinition) => Promise<ResponseData<JobRun[]>>
] => {
  const { projectId, iModelId } = useBentleyContext();
  const { buddiUrls } = useBuddiContext();
  const {
    showPreprocessorJob,
    enableSyncErrorTotals,
    showNewJobStatusLabel,
  } = useFeatureToggleContext();
  const useDataSettings = { initialData: [], initialIsLoading: true };
  const [data, isLoading, error, fetchData] =
    useDataApi<JobRun[]>(useDataSettings);

  const fetchJobStatusV1 = useCallback(
    (jobDefinition: JobDefinition) => {
      const requestData: RequestData<JobRun[]> = {
        map: mapJobRunStatusDtoToJobRun,
        method: 'GET',
        url: `${buddiUrls.orchestrationServiceUrl}/api/schedulerjobrunstatus?jobDefinitionId=${jobDefinition.id}&limit=${countOfJobStatusesToBeFetched}`,
      };
      return fetchData(requestData);
    },
    [fetchData]
  );

  const fetchJobStatusV2 = useCallback(
    (jobDefinition: JobDefinition) => {
      const requestData: RequestData<JobRun[]> = {
        map: response =>
          mapJobRunStatusV2DtoToJobRun(
            response,
            showPreprocessorJob,
            enableSyncErrorTotals,
            showNewJobStatusLabel
          ),
        method: 'GET',
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${jobDefinition.id}')/Runs?contextId=${projectId}&iModelId=${iModelId}&top=50&$orderby=startTimestamp desc`,
      };
      return fetchData(requestData);
    },
    [fetchData]
  );

  const fetchJobStatus = useCallback(
    (jobDefinition: JobDefinition) => {
      return jobDefinition.apiVersion === ApiVersion.v2
        ? fetchJobStatusV2(jobDefinition)
        : fetchJobStatusV1(jobDefinition);
    },
    [fetchJobStatusV1, fetchJobStatusV2]
  );

  return [data, isLoading, error, fetchJobStatus];
};

import React, { useEffect } from 'react';
import {
  IModelProjectLevelPermissions,
  repsonseToRBACPermissionsMap,
} from '../../entities/rbacPermissions/rbacPermissions';
import { RequestData } from '../../hooks/useDataApi/requestData';
import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { useBentleyContext } from '../bentleyContext/bentleyContext';
import { useBuddiContext } from '../buddiContext/buddiContext';
import { useUserContext } from '../userContext/userContext';

export const RBACPermissionContext =
  React.createContext<IModelProjectLevelPermissions>({
    canReadProjectLevelPermission: false,
    canModifyProjectLevelPermission: false,
    canDeleteProjectLevelPermission: false,
    canManageProjectLevelPermission: false,
  });

export const RBACPermissionContextProvider = (props: any) => {
  const { projectId } = useBentleyContext();
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = {
    initialIsLoading: true,
    initialData: {} as IModelProjectLevelPermissions,
  };
  const [iModelHubPermissions, , , fetchData] =
    useDataApi<IModelProjectLevelPermissions>(useDataSettings);

  const readRBACPermissions = async () => {
    const requestData = {
      url: `${
        buddiUrls.rbacServiceUrl
      }/v2.4/Repositories/BentleyCONNECT--Main/RBAC/User/${
        user?.profile.sub
      }/Project?$filter=$id eq '${projectId}' and Permission.ServiceGPRId eq ${2485}&$select=*,Permission.*`,
      method: 'GET',
      map: repsonseToRBACPermissionsMap,
    } as RequestData<IModelProjectLevelPermissions>;

    await fetchData(requestData);
  };

  useEffect(() => {
    if (projectId && user?.access_token) {
      readRBACPermissions();
    }
  }, [projectId, user]);

  return (
    <RBACPermissionContext.Provider
      value={{ ...iModelHubPermissions }}
      {...props}
    />
  );
};

export const useRBACPermissionContext = () => {
  const context = React.useContext(RBACPermissionContext);
  if (context == null) {
    throw new Error('permissionContext must be used inside provider');
  }
  return context;
};

import {
  SvgClose,
  SvgFeedback,
} from '@itwin/itwinui-icons-react';
import {
  Button,
  Input,
  LabeledSelect,
  Modal,
  ModalProps,
} from '@itwin/itwinui-react';
import { InputContainer } from '@itwin/itwinui-react/cjs/core/utils';
import { ReportData } from '@itwin/synchronization-report-react';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../context/toastContext/toastContext';
import { Events } from '../../../context/usageLoggerContext/eventConstants';
import { useUsageLogger } from '../../../context/usageLoggerContext/usageLoggerContext';
import './issueArticleFeedbackModal.scss';

// add issue id to the end to get the full key. Changing this prefix will reset feedback wait times.
const LOCAL_STORAGE_FEEDBACK_PREFIX = "issue-feedback-submitted-";

const DAY_MS_CONVERT = /*day->hr*/ 24 * /*hr->min*/ 60 * /*min->s*/ 60 * /*s->ms*/ 1000;
const HIDE_DURATION_DISMISS = 7 * DAY_MS_CONVERT;
const HIDE_DURATION_SUBMIT = 30 * DAY_MS_CONVERT;

export interface IssueArticleFeedbackModalProps extends Omit<ModalProps, "title" | "children"> {
  issueId: string;
  report: ReportData | null;
}

export const IssueArticleFeedbackModal: React.FC<IssueArticleFeedbackModalProps> = (
  props: IssueArticleFeedbackModalProps
) => {
  const { issueId, onClose, report, ...modalProps } = props;
  const [selectedFeedback, setSelectedFeedback] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const { logEvent } = useUsageLogger();
  const { toastSuccess } = useToast();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (props.isOpen) {
      // bootleg workaround to disable the backdrop
      const disableBackdrop = () => document.getElementsByClassName("iui-backdrop-visible")[0]?.classList.remove("iui-backdrop-visible");
      disableBackdrop();
      setTimeout(disableBackdrop);
    }
  }, [props.isOpen])

  const close = useCallback((feedbackSubmitted: boolean) => {
    const timeToNextFeedback = Date.now() + (feedbackSubmitted ? + HIDE_DURATION_SUBMIT : HIDE_DURATION_DISMISS);
    localStorage.setItem(LOCAL_STORAGE_FEEDBACK_PREFIX + issueId, timeToNextFeedback.toString());
    onClose?.();
  }, [issueId, onClose])

  useLayoutEffect(() => {
    if (!props.isOpen)
      return;
    const nextFeedbackDateString = localStorage.getItem(LOCAL_STORAGE_FEEDBACK_PREFIX + issueId);
    if (!nextFeedbackDateString)
      return;
    const nextFeedbackTime = Number(nextFeedbackDateString);
    if (Date.now() < nextFeedbackTime) {
      onClose?.();
      return;
    }
  }, [issueId, onClose, props.isOpen]);

  const submitFeedback = useCallback(() => {
    logEvent(Events.IssueArticleFeedback, [
      { name: 'issueId',          value: issueId },
      { name: 'selectedFeedback', value: selectedFeedback },
      { name: 'feedbackMessage',  value: feedbackMessage },
      { name: 'contactEmail',     value: contactEmail },
      { name: 'iModelId',         value: report?.context?.imodelid ?? '' },
      { name: 'jobId',            value: report?.context?.jobid ?? '' },
    ]);
    close(true);
    setSelectedFeedback('Select an option');
    setFeedbackMessage("");
    toastSuccess(t('Received_Feedback_Label'));
  }, [issueId, selectedFeedback, feedbackMessage, contactEmail, close, setSelectedFeedback, setFeedbackMessage, logEvent, t, toastSuccess, report]);

  return <Modal
      {...modalProps}
      title={""}
      className={'issue-article-feedback-modal'}
    >
      <Button
        className={'feedback-header'}
        styleType="high-visibility"
        onClick={() => { close(false) }}
        startIcon={<SvgFeedback />}
        endIcon={<SvgClose />}
      >
        {t('Give_Us_Feedback')}
      </Button>
      <>
        <div
          className="feedback-subtext"
          style={{ paddingTop: '12px' }}
        >
          {t("How_Helpful_Is_Issue_Article", { issueId })}
          <span style={{ color: 'red' }}>*</span>
        </div>
        <LabeledSelect
          className="feedback-labeled-select"
          placeholder={t("Select_Option_Label")}
          value={selectedFeedback}
          role="menu"
          options={[
            {
              label: t('Very_Helpful'),
              value: 'Very helpful',
            },
            {
              label: t('Somewhat_Helpful'),
              value: 'Somewhat helpful',
            },
            {
              label: t('Somewhat_Unhelpful'),
              value: 'Somewhat unhelpful',
            },
            {
              label: t('Very_Unhelpful'),
              value: 'Very unhelpful',
            },
          ]}
          onChange={value => {
            setSelectedFeedback(value);
          }}
        />
        <div className="feedback-subtext">{t('Feedback')}</div>
        <label className="feedback-input-container">
          <textarea
            rows={10}
            maxLength={1000}
            className="feedback-input"
            onChange={value => setFeedbackMessage(value.target.value)}
          ></textarea>
        </label>
        <div className="feedback-subtext">{t('Add_Your_Email')}</div>
        <InputContainer className="feedback-email-input">
          <Input
            placeholder="address@email.com"
            maxLength={50}
            onChange={value => setContactEmail(value.target.value)}
          ></Input>
        </InputContainer>
        <Button
          className="feedback-submit-button"
          styleType="high-visibility"
          onClick={() => submitFeedback()}
          disabled={selectedFeedback.length === 0 || !issueId}
        >
          {t('Submit_Feedback')}
        </Button>
      </>
  </Modal>
};

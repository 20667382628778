import { ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { useDataApi } from '../useDataApi/useDataApi';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useIsConnectionEnabled = (): [
  (contextId: string, connectionId: string) => Promise<ResponseData<any>>
] => {
  const { buddiUrls } = useBuddiContext();
  const useDataSettings = { initialData: [], initialIsLoading: false };
  const [, , , fetchData] = useDataApi<any>(useDataSettings);

  const isConnectionEnabled = useCallback(
    async (contextId: string, connectionId: string) => {
      const requestData: any = {
        method: 'GET',
        url: `${buddiUrls.projectWise365Url}/api/v1/context/${contextId}/connections/${connectionId}/accessibility`,
      };

      return fetchData(requestData);
    },
    []
  );

  return [isConnectionEnabled];
};

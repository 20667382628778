import { useCallback } from 'react';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { RequestData } from '../../hooks/useDataApi/requestData';
import { useDataApi } from '../../hooks/useDataApi/useDataApi';

export const useGetUsersWithPermission = (): [
  (permission: string) => Promise<string[]>
] => {
  const { projectId } = useBentleyContext();
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = {
    initialIsLoading: false,
    initialData: [],
  };
  const [, , , fetchData] = useDataApi<any>(useDataSettings);

  const getUsersWithPermission = useCallback(
    async (permission: string) => {
      const requestData: RequestData<string[]> = {
        url: `${buddiUrls.rbacServiceUrl}/v2.4/Repositories/BentleyCONNECT--Main/RBAC/Project/${projectId}/User?$filter=Permission.$id+eq+'${permission}'`,
        method: 'GET',
        map: response =>
          response.instances.map((instance: any) => instance.instanceId),
      };
      const result = await fetchData(requestData);
      return result.data;
    },
    [fetchData]
  );
  return [getUsersWithPermission];
};

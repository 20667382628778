import React, { useEffect, useState } from 'react';
import {
  COMMUNITIES_ONE_CONNECTOR_PER_CONNECTION,
  Repository,
} from '../../../entities/repository';
import {
  WizardButtonRow,
  WizardButtonRowProps,
} from '../wizardButtonRow/wizardButtonRow';
import { JobFiles } from '../../../entities/jobDefinition/jobDefinition';
import { useTranslation } from 'react-i18next';
import { SpatialRoot } from '../../../entities/spatialRootSetting';
import './defineBridgeStep.scss';
import {
  SelectDataAdapter,
  useBridgeForFile,
} from '../../../services/bridgeLogic/bridgeLogic';
import { BridgeType, getBridgeTypeDisplayName } from '../../../entities/bridge';
import { useModal } from '../../../hooks/useModal/useModal';
import { RegexModal } from '../../regexModal/regexModal';
import { useFeatureToggleContext } from '../../../context/featureToggleContext/featureToggleContext';
import { BridgeSelectionModal } from '../../modals/bridgeSelectionModal/bridgeSelectionModal';
import { MasterContext } from '../../../hooks/useGetMasterReferencesTree/useGetMasterReferencesTree';
import { BridgeAssignmentTable } from '../../jobDefinitionFilesTable/bridgeAssignmentTable';
import { Item } from '../../../entities/item';
import { Searchbar } from '../../searchBar/searchBar';
import { ExecutionStrategy } from '../../../entities/jobDefinition/jobDefinitionDTOv2';
import {
  ButtonGroup,
  DropdownMenu,
  IconButton,
  MenuItem,
  Tooltip,
  LabeledSelect,
  Alert,
} from '@itwin/itwinui-react';
import {
  embeddedFileBasenameBridgeArgs,
  embeddedFileBasenameRegexV2,
  fileBasenameBridgeArgs,
  fileBasenameRegexV2,
} from '../../../constants/constants';
import { SvgEdit, SvgSettings } from '@itwin/itwinui-icons-react';
import { TextLink } from '../../textLink/textLink';
import { BridgeSelection } from '../../../hooks/useBridgeSelection/useBridgeSelection';

export interface DefineBridgeStepProps {
  existingSpatial?: SpatialRoot;
  jobFiles: JobFiles;
  repository: Repository;
  mastersContext: MasterContext[];
  isMastersContextLoading: boolean;
  mastersContextError: any;
  selectedItems: Item[];
  filterMasterContexts: (filterString: string) => MasterContext[];
  setBridgeSelection: (
    mastersContext: MasterContext,
    masterId: string,
    selectedId: string,
    bridgeSelection: BridgeSelection
  ) => void;
  setSelectedItems: (items: Item[]) => void;
  setBridgeForSelected: (
    mastersContext: MasterContext[],
    bridge: BridgeType
  ) => void;
  fetchMastersContext: (
    rootItems: Item[],
    selectedMastersContext?: MasterContext[],
    jobDefId?: string | undefined,
    isAffinityConnection?: boolean | undefined,
  ) => Promise<MasterContext[]>;
  isAnyFileSelected: () => boolean;
  setBridgeArgs: (options: any) => any;
  executionStrategy: ExecutionStrategy;
  shouldShowIndividualConnectorsWithoutAffinity: boolean;
}
export const DefineBridgeStep = (
  props: DefineBridgeStepProps & WizardButtonRowProps
) => {
  const {
    existingSpatial,
    jobFiles,
    repository,
    mastersContext,
    isMastersContextLoading,
    mastersContextError,
    selectedItems,
    filterMasterContexts,
    setBridgeSelection,
    setSelectedItems,
    setBridgeForSelected,
    fetchMastersContext,
    isAnyFileSelected,
    backHandle,
    cancelHandle,
    primaryButtonLabel,
    primaryButtonHandle,
    setBridgeArgs,
    executionStrategy,
    shouldShowIndividualConnectorsWithoutAffinity,
  } = props;
  const { t } = useTranslation();
  const { isregexInputEnabled, createV2Connection } = useFeatureToggleContext();
  const isAffinityUIEnabled =
    executionStrategy === ExecutionStrategy.ExistingAffinity ||
    shouldShowIndividualConnectorsWithoutAffinity;
  const spatialRoot: Item = existingSpatial
    ? ({ id: existingSpatial.id, name: existingSpatial.name } as Item)
    : jobFiles.spatialMaster;

  const [files] = useState([
    ...(jobFiles.spatialMaster ? [jobFiles.spatialMaster] : []),
    ...jobFiles.masters,
  ]);

  useEffect(() => {
    fetchMastersContext(files, mastersContext, undefined, false);
  }, []);

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [filterString, setFilterString] = useState('');
  const [filteredMastersContext, setFilteredMastersContext] = useState<
    MasterContext[]
  >([]);

  useEffect(() => {
    setFilteredMastersContext(filterMasterContexts(filterString));
    setIsFilterApplied(filterString !== '');
  }, [filterString]);

  const { getAllowedBridgeTypesForFile, getBridgeSelectOptions } =
    useBridgeForFile();

  const [bridge, setBridge] = useState(
    getAllowedBridgeTypesForFile(files[0].name, repository)[0]
  );
  const {
    closeModal: closeRegexModal,
    openModal: openRegexModal,
    modalProps: regexProps,
  } = useModal();
  const {
    openModal: openBridgeSelectionModal,
    modalProps: bridgeSelectionModalProps,
  } = useModal();

  const [regexSavedValue, setRegexSavedValue] = useState<string | null>(null);

  const submitRegex = (regex: string | null) => {
    if (regex) {
      const regexArgs = createV2Connection
        ? {
            [embeddedFileBasenameRegexV2]: regex,
            ...{ [fileBasenameRegexV2]: regex },
          }
        : {
            [embeddedFileBasenameBridgeArgs]: regex,
            [fileBasenameBridgeArgs]: regex,
          };
      setBridgeArgs(regexArgs);
      setRegexSavedValue(regex);
    }
    closeRegexModal();
  };

  const [isSelectedBridgeSupported, setIsSelectedBridgeSupported] = useState(
    executionStrategy === ExecutionStrategy.NonAffinity ? false : true
  );

  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const getWarningMessage = (): string | null => {
    const selectedFiles = files.map(mc => {
      const allowedBridgeTypes = getAllowedBridgeTypesForFile(
        mc.name,
        repository
      );
      if (!allowedBridgeTypes.includes(bridge)) {
        return mc.name;
      }
      return '';
    });
    const filtered = selectedFiles.filter(x => x?.length !== 0);

    if (filtered.length !== 0) {
      setIsSelectedBridgeSupported(false);
      const message = `The files ${filtered.join(
        ', '
      )} cannot be processed by ${getBridgeTypeDisplayName(
        bridge
      )} connector. `;
      return filtered.length > 1 ? message : message.replace('files', 'file');
    }
    setIsSelectedBridgeSupported(true);
    return null;
  };

  useEffect(() => {
    const message = getWarningMessage();
    message ? setWarningMessage(message) : setWarningMessage(null);
  }, [mastersContext, bridge]);

  return (
    <>
      {isregexInputEnabled && (
        <RegexModal
          {...regexProps}
          closeModal={closeRegexModal}
          submitRegex={submitRegex}
          regexInitialValue={regexSavedValue ?? ''}
        />
      )}
      {bridgeSelectionModalProps.isOpen && isAffinityUIEnabled && (
        <BridgeSelectionModal
          {...bridgeSelectionModalProps}
          selectedItems={selectedItems}
          masterContexts={mastersContext}
          setBridgeForSelected={setBridgeForSelected}
        />
      )}
      <div className="filepicker-wrapper define-bridge-step-wrapper">
        {executionStrategy === ExecutionStrategy.NonAffinity &&
          !shouldShowIndividualConnectorsWithoutAffinity && (
            <div className="flex-rows">
              {warningMessage ? (
                <Alert
                  type="warning"
                  data-testid="unsupported-files-alert"
                  className="non-supported-files-alert"
                >
                  <span>
                    {warningMessage}
                    <TextLink url={COMMUNITIES_ONE_CONNECTOR_PER_CONNECTION}>
                      {t('ReadMore_Link')}
                    </TextLink>
                  </span>
                </Alert>
              ) : null}
            </div>
          )}
        <div className="table-toolbar">
          {isAffinityUIEnabled ? (
            <ButtonGroup data-testid="edit-connector-svg">
              <Tooltip content={t('DropDownOption_BatchConnectorSelection')}>
                <IconButton
                  onClick={openBridgeSelectionModal}
                  disabled={!isAnyFileSelected()}
                  data-testid="affinity-bridge-edit-button"
                >
                  <SvgEdit />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          ) : executionStrategy !== ExecutionStrategy.Affinity ? (
            <div className="flex-rows  center-content">
              <LabeledSelect<BridgeType>
                data-testid="bridge-type-select"
                label={t('ConnectorTypeDropDownInput_Label')}
                options={getBridgeSelectOptions(
                  files[0].name,
                  repository,
                  SelectDataAdapter.iTwinUI
                )}
                value={bridge}
                onChange={(value: BridgeType) => {
                  setBridge(value);
                }}
              />
            </div>
          ) : null}
          <div className="flex-spacer"></div>
          <div className="flex-rows center-content">
            {isAffinityUIEnabled && (
              <div className="define-bridgesteps-searchbar">
                <Searchbar debounce onChange={setFilterString} />
              </div>
            )}
            {isregexInputEnabled && (
              <ButtonGroup>
                <DropdownMenu
                  placement="bottom-end"
                  role="menu"
                  menuItems={(close: () => void) => [
                    <MenuItem
                      icon={<SvgSettings />}
                      title={t('DropDownOption_RegexExpression')!}
                      onClick={() => {
                        close();
                        openRegexModal();
                      }}
                    >
                      {t('DropDownOption_RegexExpression')}
                    </MenuItem>,
                  ]}
                >
                  <IconButton>
                    <SvgSettings />
                  </IconButton>
                </DropdownMenu>
              </ButtonGroup>
            )}
          </div>
        </div>
        <BridgeAssignmentTable
          spatialRootId={!existingSpatial ? spatialRoot?.id : ''}
          editable={false}
          mastersContext={
            filterString ? filteredMastersContext : mastersContext
          }
          isFilterApplied={isFilterApplied}
          isTreeLoading={isMastersContextLoading}
          setBridgeSelection={setBridgeSelection}
          setSelectedItems={setSelectedItems}
          isAffinityUIEnabled={isAffinityUIEnabled}
          mastersContextError={mastersContextError}
          selectedRepository={repository}
        />
      </div>
      <WizardButtonRow
        backHandle={backHandle}
        cancelHandle={cancelHandle}
        primaryButtonHandle={() => primaryButtonHandle(bridge, mastersContext)}
        primaryButtonLabel={primaryButtonLabel}
        primaryDisabled={
          isAffinityUIEnabled
            ? isMastersContextLoading
            : !isSelectedBridgeSupported
        }
      />
    </>
  );
};

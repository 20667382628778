import React, { useState, useEffect, useLayoutEffect } from 'react';
import './jobScheduleSelection.scss';
import {
  getHourlyCronExpression,
  ScheduleInterval,
  getEach4HoursCronExpression,
  getScheduleIntervalFromCronString,
} from '../../../../entities/cronSchedule';
import { DailyJobScheduleSelection } from '../../common/dailyJobScheduleSelection/dailyJobScheduleSelection';
import { WeeklyJobScheduleSelection } from '../weeklyJobScheduleSelection/weeklyJobScheduleSelection';
import { MinuteJobScheduleSelection } from '../../common/minuteJobScheduleSelection/minuteJobScheduleSelection';
import { useFeatureToggleContext } from '../../../../context/featureToggleContext/featureToggleContext';
import { useTranslation } from 'react-i18next';
import { InputGroup, Radio } from '@itwin/itwinui-react';

interface JobScheduleSelectionProps {
  onScheduleChange: (schedule: string) => void;
  initialSchedule?: string;
}

export const JobScheduleSelection = (props: JobScheduleSelectionProps) => {
  const { onScheduleChange, initialSchedule = '' } = props;

  const [schedule, setSchedule] = useState(initialSchedule);
  const [scheduleInterval, setScheduleInterval] = useState<ScheduleInterval>(
    getScheduleIntervalFromCronString(initialSchedule)
  );

  useEffect(() => {
    onScheduleChange(schedule);
  }, [onScheduleChange, schedule]);

  useLayoutEffect(() => {
    const newScheduleInterval = getScheduleIntervalFromCronString(schedule);
    setScheduleInterval(newScheduleInterval);
  }, [schedule]);

  const { t } = useTranslation();
  const { minuteSchedule } = useFeatureToggleContext();

  return (
    <div className="schedule-sync-container" data-testid="schedule-v1">
      <InputGroup label={t('JobIModelUpdateSchedule_Title')}>
        <div className="schedule-option">
          <Radio
            label={t('JobScheduleInitiatedManually_Label')}
            name="schedule-sync"
            onChange={() => {
              setSchedule('');
            }}
            checked={scheduleInterval === ScheduleInterval.InitiatedManually}
          />
        </div>

        <div className="schedule-option">
          <Radio
            label={t('JobScheduleEach4Hours_Label')}
            name="schedule-sync"
            onChange={() => {
              setSchedule(getEach4HoursCronExpression());
              onScheduleChange(getEach4HoursCronExpression());
            }}
            checked={scheduleInterval === ScheduleInterval.Every4Hours}
          />
        </div>

        {minuteSchedule && (
          <>
            <div className="schedule-option">
              <Radio
                label={t('JobScheduleHourly_Label')}
                name="schedule-sync"
                onChange={() => {
                  setSchedule(getHourlyCronExpression());
                }}
                checked={scheduleInterval === ScheduleInterval.Hourly}
              />
            </div>
            <MinuteJobScheduleSelection
              isSelected={scheduleInterval === ScheduleInterval.EveryMinute}
              schedule={schedule}
              setSchedule={setSchedule}
            />
          </>
        )}

        <DailyJobScheduleSelection
          isSelected={scheduleInterval === ScheduleInterval.Daily}
          schedule={schedule}
          setSchedule={setSchedule}
        />

        <WeeklyJobScheduleSelection
          isSelected={scheduleInterval === ScheduleInterval.Weekly}
          schedule={schedule}
          setSchedule={setSchedule}
        />
      </InputGroup>
    </div>
  );
};

import { useCallback, useState } from 'react';
import { iModelHubAppId } from '../../constants/constants';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import {
  GetJobDefinitionsV2Dto,
  JobDefinition,
  filterV2Jobs,
  productSettingsJobsNamespace,
} from '../../entities/jobDefinition/jobDefinition';
import {
  responseToJobDefinitionsMap,
  responseV2ToJobDefinitionsCount,
  responseV2ToJobDefinitionsMap,
} from '../../entities/jobDefinition/jobDefinitionMappings';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useDataApi } from '../useDataApi/useDataApi';

export const useGetJobs = (
  projectId: string,
  iModelId: string
): [
  JobDefinition[],
  boolean,
  any,
  () => Promise<ResponseData<JobDefinition[]>>
] => {
  const { buddiUrls } = useBuddiContext();
  const {
    migrateToV2Api,
    disableSupportForV1,
    filterV2Connections,
    enableSyncErrorTotals,
  } = useFeatureToggleContext();

  const url = `${buddiUrls.productSettingsServiceUrl}/v1.0/Application/${iModelHubAppId}/Context/${projectId}/iModel/${iModelId}/Setting?$filter=namespace+eq+'${productSettingsJobsNamespace}'`;

  const useDataSettings = { initialData: [], initialIsLoading: true };
  const [, , , fetchData] = useDataApi<JobDefinition[]>(useDataSettings);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<JobDefinition[]>([]);

  const getV1Jobs = useCallback(async () => {
    const requestData: RequestData<JobDefinition[]> = {
      method: 'GET',
      url: url,
      map: responseToJobDefinitionsMap,
    };
    let result = await fetchData(requestData);
    if (!result.ok) {
      return result;
    }

    let continuationToken = result.response!.headers.get('ContinuationToken');
    let allData: JobDefinition[] = [...result.data!];

    while (continuationToken) {
      if (!result.ok) {
        break;
      }

      result = await fetchData({
        ...requestData,
        extraHeaders: { ContinuationToken: continuationToken },
      });
      continuationToken = result.response!.headers.get('ContinuationToken');
      allData = [...allData, ...result.data!];
    }
    return { ...result, data: allData };
  }, [url, fetchData]);

  const fetchV1Jobs = useCallback(async () => {
    setIsLoading(true);
    const v1JobsResponse = await getV1Jobs();
    if (!v1JobsResponse.error && v1JobsResponse.data) {
      setData(v1JobsResponse.data.filter(x => x.isActive === true));
    }
    setIsLoading(false);
    return v1JobsResponse;
  }, [getV1Jobs]);

  const getV2JobsCount = useCallback(async () => {
    let requestUrl = `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions?contextId=${projectId}&iModelId=${iModelId}&$count=true`;
    if (filterV2Connections) {
      requestUrl = `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions?contextId=${projectId}&iModelId=${iModelId}&$filter=${filterV2Jobs}&$count=true`;
    }
    const requestData: RequestData<JobDefinition[]> = {
      method: 'GET',
      url: requestUrl,
      map: (response: GetJobDefinitionsV2Dto) =>
        responseV2ToJobDefinitionsCount(response),
    };
    const result = fetchData(requestData);
    return result;
  }, [
    fetchData,
    enableSyncErrorTotals,
    buddiUrls.iModelBridgeServiceUrl,
    filterV2Connections,
    iModelId,
    projectId,
  ]);

  const getV2JobsMoreThanFifty = useCallback(async () => {
    const count = await getV2JobsCount();
    let requestUrl = `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions?contextId=${projectId}&iModelId=${iModelId}&$top=${count.data}`;
    if (filterV2Connections) {
      requestUrl = `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions?contextId=${projectId}&iModelId=${iModelId}&$filter=${filterV2Jobs}&$top=${count.data}`;
    }
    const requestData: RequestData<JobDefinition[]> = {
      method: 'GET',
      url: requestUrl,
      map: (response: GetJobDefinitionsV2Dto) =>
        responseV2ToJobDefinitionsMap(response, enableSyncErrorTotals),
    };
    const result = fetchData(requestData);
    return result;
  }, [
    fetchData,
    enableSyncErrorTotals,
    buddiUrls.iModelBridgeServiceUrl,
    filterV2Connections,
    iModelId,
    projectId,
  ]);

  const getV2Jobs = useCallback(async () => {
    let rowCount;
    let result;
    let requestUrl = `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions?contextId=${projectId}&iModelId=${iModelId}`;
    if (filterV2Connections) {
      requestUrl = `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions?contextId=${projectId}&iModelId=${iModelId}&$filter=${filterV2Jobs}`;
    }
    const requestData: RequestData<JobDefinition[]> = {
      method: 'GET',
      url: requestUrl,
      map: (response: GetJobDefinitionsV2Dto) =>
        responseV2ToJobDefinitionsMap(response, enableSyncErrorTotals),
    };
    result = fetchData(requestData);
    rowCount = (await result).data?.length;
    if (rowCount != undefined && rowCount >= 50)
      result = await getV2JobsMoreThanFifty();
    return result;
  }, [
    fetchData,
    enableSyncErrorTotals,
    buddiUrls.iModelBridgeServiceUrl,
    filterV2Connections,
    iModelId,
    projectId,
  ]);

  const fetchAllJobs = useCallback(async () => {
    setIsLoading(true);
    let activeV1Jobs: JobDefinition[] = [];
    if (!disableSupportForV1) {
      const v1JobsResponse = await getV1Jobs();
      if (v1JobsResponse.error || !v1JobsResponse.data) {
        return v1JobsResponse;
      }
      activeV1Jobs = v1JobsResponse.data.filter(x => x.isActive === true);
    } else {
      activeV1Jobs = [];
    }
    const v2JobsResponse = await getV2Jobs();
    if (v2JobsResponse.error || !v2JobsResponse.data) {
      return v2JobsResponse;
    }

    const v1ActiveJobIds = activeV1Jobs.map(job => job.id);
    const activeV2Jobs = v2JobsResponse.data.filter(
      x => !v1ActiveJobIds.includes(x.id)
    );

    setData([...activeV1Jobs, ...activeV2Jobs]);
    setIsLoading(false);

    return {
      ...v2JobsResponse,
      data: [...activeV1Jobs, ...v2JobsResponse.data],
    };
  }, [getV1Jobs, getV2Jobs]);

  return [data, isLoading, null, migrateToV2Api ? fetchAllJobs : fetchV1Jobs];
};

import { useCallback } from 'react';
import { useAppContext } from '../../context/appContext/appContext';
import { useDataApi } from '../../externalComponents/hooks/useDataApi/useDataApi';
import {
  RequestData,
  ResponseData,
} from '../../externalComponents/hooks/useDataApi/requestData';
import {
  Project,
  responseToProjectMap,
} from '../../externalComponents/entities/project';

export const useCheckUserHasProjectAccess = (
  projectId: string
): [any, boolean, any, () => Promise<ResponseData<Project[]>>] => {
  const { configuration } = useAppContext();
  const project: Project[] = [];
  const useDataSettings = { initialIsLoading: false, initialData: project };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);
  const baseUrl = `${configuration.buddiUrls.connectedContextUrl}`;

  const checkUserHasProjectAccess = useCallback(() => {
    const url =
      baseUrl +
      `/sv1.0/Repositories/BentleyCONNECT--Main/ConnectedContext/Context/${projectId}`;

    const requestData: RequestData<Project[]> = {
      url: url,
      method: 'GET',
      map: responseToProjectMap,
    };
    return fetchData(requestData);
  }, [fetchData, projectId, baseUrl]);
  return [data, isLoading, error, checkUserHasProjectAccess];
};

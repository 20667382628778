import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  WizardButtonRow,
  WizardButtonRowProps,
} from '../wizardButtonRow/wizardButtonRow';
import './existingSpatialStep.scss';
import { SpatialRoot } from '../../../entities/spatialRootSetting';
import { SvgNetwork, SvgStatusSuccess } from '@itwin/itwinui-icons-react/cjs';
import { Text } from '@itwin/itwinui-react';
import { Icon } from '../../icons/icon';

interface ExistingSpatialStepProps {
  existingSpatialRoot: SpatialRoot;
}

export const ExistingSpatialStep = (
  props: ExistingSpatialStepProps & WizardButtonRowProps
) => {
  const {
    existingSpatialRoot,
    backHandle,
    cancelHandle,
    primaryButtonLabel,
    primaryButtonHandle,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="existing-spatial-placeholder">
        <div className="existing-spatial-placeholder-icon">
          <Icon icon={SvgNetwork} color="default" size="xxl" />
          <Icon
            icon={SvgStatusSuccess}
            color="positive"
            size="large"
            className="existing-spatial-placeholder-overlay-icon"
          />
        </div>
        <div
          className="existing-spatial-placeholder-text"
          data-testid="existing-spatial-message"
        >
          <Text variant="body" as="p">
            {t('ExistingGeoCoordinateSystem_MessagePart1')}
            <span className="text-bold">{existingSpatialRoot.name}</span>
            {t('ExistingGeoCoordinateSystem_MessagePart2')}
          </Text>
        </div>
      </div>
      <WizardButtonRow
        backHandle={backHandle}
        cancelHandle={cancelHandle}
        primaryButtonHandle={primaryButtonHandle}
        primaryButtonLabel={primaryButtonLabel}
        primaryDisabled={false}
      />
    </>
  );
};

export interface Datasource {
  id: string;
  displayLabel: string;
  wsgVersion: string;
}
export interface PWProject {
  id: string;
  name: string;
}

export const responseToDataSourceMap = (response: any): Datasource[] =>
  response.data.pwDatasources;
export const responseToProjectMap = (response: any): PWProject[] =>
  response.data.pwProjects;

import { useCallback } from 'react';
import { RequestData } from '../useDataApi/requestData';
import { useDataApi } from '../useDataApi/useDataApi';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
export const useGetImodelObjectType = (): [() => Promise<string>] => {
  const { buddiUrls } = useBuddiContext();
  const useDataSettings = {
    initialIsLoading: true,
    initialData: [],
  };
  const [, , , fetchData] = useDataApi<any>(useDataSettings);
  const getImodelObjectType = useCallback(async () => {
    const requestData: RequestData<string> = {
      url: `${buddiUrls.rbacServiceUrl}/v2.5/Repositories/BentleyCONNECT--Main/RBAC/ObjectType?$filter=Name eq 'IMHS_ObjectType_iModel' and ServiceGPRId eq 2485`,
      method: 'GET',
      map: response =>
        response.instances[0] ? response.instances[0].instanceId : null,
    };
    const result = await fetchData(requestData);
    return result.data;
  }, [fetchData]);
  return [getImodelObjectType];
};

import React from 'react';
import { Datasource, PWProject } from '../../entities/datasource';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { useTranslation } from 'react-i18next';
import { Text } from '@itwin/itwinui-react';
import Typeahead from '../typehead/Typeahead';

export interface RecentPwdiConnection {
  serverUrl: string;
  hostName?: string;
  datasource: Datasource;
  workArea: PWProject;
  onClose?: any;
}

interface TypeaheadOption {
  label: string;
  value: RecentPwdiConnection | undefined;
}

interface RecentPwdiConnectionProps {
  recentConnections: RecentPwdiConnection[];
  selectedConnection: RecentPwdiConnection | undefined;
  onSelect: (selected: RecentPwdiConnection) => void;
  onClose?: any;
}

export const RecentPwdiConnections: React.FC<RecentPwdiConnectionProps> = (
  props: RecentPwdiConnectionProps
) => {
  const { recentConnections, selectedConnection, onSelect } = props;
  const { hostNameInput } = useFeatureToggleContext();
  const { t } = useTranslation();

  const selectedValue = selectedConnection
    ? selectedConnection.datasource.displayLabel +
      ':' +
      selectedConnection.workArea.name
    : t('RecentConnectionDefaultValue');
  return (
    <div title={selectedValue}>
      <Typeahead<TypeaheadOption>
        className="recent-pwdi-connections"
        label={t('RecentConnectionTypeahead_Label')!}
        isSearchable={false}
        isClearable={!!selectedConnection}
        value={{
          label: selectedValue,
          value: selectedConnection,
        }}
        onChange={(selected: any) => {
          !selected && props.onClose
            ? props.onClose()
            : onSelect(selected?.value);
        }}
        options={recentConnections.map(rc => {
          return {
            label: rc.datasource.displayLabel + ':' + rc.workArea.name,
            value: rc,
          };
        })}
        components={{
          Option: (props: any) => {
            const { data, selectOption, value } = props;
            const currentValue = value
              ? value.datasource.displayLabel + ':' + value.workArea.name
              : t('RecentConnectionDefaultValue');
            return (
              <div
                onClick={() => {
                  selectOption(data);
                }}
                title={currentValue}
                className="recent-connection-container flex-columns"
                data-testid={'recent-connection-option'}
              >
                <div className="recent-connection-property flex-rows">
                  <Text variant="subheading" as="h3">
                    {value.datasource.displayLabel}
                  </Text>
                </div>
                <div className="recent-connection-property flex-rows">
                  <Text variant="body" as="p" className="key">
                    ProjectWise Server URL:
                  </Text>
                  <Text variant="body" as="p" className="value" isMuted>
                    {value.serverUrl}
                  </Text>
                </div>
                {hostNameInput && value.hostName != null && (
                  <div className="recent-connection-property flex-rows">
                    <Text variant="body" as="p" className="key">
                      Host Name:
                    </Text>
                    <Text variant="body" as="p" className="value" isMuted>
                      {value.hostName}
                    </Text>
                  </div>
                )}
                <div className="recent-connection-property flex-rows">
                  <Text variant="body" as="p" className="key">
                    Work area:
                  </Text>
                  <Text variant="body" as="p" className="value" isMuted>
                    {value.workArea.name}
                  </Text>
                </div>
              </div>
            );
          },
        }}
      />
    </div>
  );
};

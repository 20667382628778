import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  RepositoryType,
  getDatasourceTypeDisplayName,
  getRepositoryReadMoreLink,
} from '../../entities/repository';
import { getIconForRepositoryTile } from '../itemIcon/itemIcon';
import { RadioTile } from '@itwin/itwinui-react';
import { TextLink } from '../textLink/textLink';

export interface RepositoryTileProps {
  repositoryType: RepositoryType;
  onClick: () => void;
  selectedRepository: RepositoryType | null;
  'data-testid'?: string;
}

export const RepositoryTile = (props: RepositoryTileProps) => {
  const { t } = useTranslation();
  const { repositoryType, selectedRepository } = props;
  const checkIfSelected = selectedRepository === repositoryType;
  return (
    <RadioTile
      name="RepositoryType"
      icon={getIconForRepositoryTile(repositoryType)}
      label={getDatasourceTypeDisplayName(repositoryType)}
      description={
        <>
          {t(`Repository_${repositoryType}_Description`)}
          <br />
          <TextLink url={getRepositoryReadMoreLink(repositoryType)}>
            {t('ReadMore_Link')}
          </TextLink>
        </>
      }
      onClick={() => {
        props.onClick();
      }}
      checked={checkIfSelected}
      data-testid={props['data-testid']}
    />
  );
};

export enum JobStartStatus {
  Started = 1,
  Skipped,
  BlockedByOtherJob,
  AlreadyRunning,
}

export enum FileRunStatusV1 {
  NotFound = 'NotFound',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Succeeded = 'Succeeded',
  Fail = 'Failed',
  Canceled = 'Canceled',
  Timeout = 'Timeout',
}

export enum JobRunDtoStatus {
  None = 0,
  NotStarted = 1,
  InProgress = 2,
  PartiallyCompleted = 3,
  Completed = 4,
  Failed = 5,
  Skipped = 6,
}

export enum JobRunDtoTriggerType {
  Scheduled = 'Scheduled',
  Http = 'HTTP',
}

export interface JobRunStatusDto {
  jobRun: JobRunDto;
  statuses: FileRunDto[];
}

export interface JobRunDto {
  jobRunId: string;
  jobStatus: JobRunDtoStatus;
  startTime: string;
  endTime?: string;
  details?: string;
  triggerType: JobRunDtoTriggerType;
}

export interface FileStatusDto {
  activityId: string;
  state: FileRunStatusV1;
  startTime: string;
  endTime: string;
  details: string;
  inputFile: string;
  inputFileId: string;
}
export interface TaskStatusDto {
  step: string;
  state: string;
  stepsPercentage: string;
  task: string;
  tasksPercentage: string;
  phase: string;
  phasesPercentage: string;
  modifiedTime: string;
}
export interface FileRunDto {
  jobStatus: FileStatusDto;
  taskStatuses: TaskStatusDto[];
}
export interface TaskStatus {
  step: string;
  state: string;
  stepsPercentage: string;
  task: string;
  tasksPercentage: string;
  phase: string;
  phasesPercentage: string;
  modifiedTime: string;
}

export interface iModelFileHierarchy {
  [fileId: string]: string[];
}

import { useState, useCallback } from 'react';
import { Entity } from '../../entities/entity';

interface UseSelectionResult<T> {
  selection: T[];
  selectedIds: string[];
  setSelected: (ids: T[]) => void;
  isSelected: (obj: T) => boolean;
  isSelectedId: (id: string) => boolean;
  resetSelection: () => void;
}

export const useSelection = <T extends Entity>(
  onSelectChange?: (items: T[]) => any
): UseSelectionResult<T> => {
  const [selection, setItemSelection] = useState<T[]>([]);

  const selectedIds = selection.map(selected => selected.id);

  const isSelectedId = useCallback(
    (id: string): boolean => selection.some((obj: T) => obj.id === id),
    [selection]
  );

  const isSelected = useCallback(
    (entity: T): boolean => isSelectedId(entity.id),
    [selection]
  );

  const resetSelection = (): void => {
    setItemSelection([]);
    if (onSelectChange) {
      onSelectChange([] as T[]);
    }
  };
  const setSelected = (ids: T[]) => {
    setItemSelection(ids);
    if (onSelectChange) {
      onSelectChange(ids);
    }
  };

  return {
    selection,
    selectedIds,
    setSelected,
    isSelected,
    isSelectedId,
    resetSelection,
  } as UseSelectionResult<T>;
};

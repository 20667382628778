import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import {
  jobScheduleDtoToJobScheduleMap,
  JobSchedule,
} from '../../entities/jobSchedule';
import { useCallback } from 'react';
import {
  ApiVersion,
  JobDefinition,
} from '../../entities/jobDefinition/jobDefinition';
import { useUserContext } from '../../context/userContext/userContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useGetJobSchedule = (
  projectId: string,
  iModelId: string
): [
  JobSchedule,
  boolean,
  any,
  (
    jobDefinition: JobDefinition
  ) => Promise<ResponseData<JobSchedule>> | Promise<null>
] => {
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialIsLoading: true, initialData: null as any };
  const [jobSchedule, isLoading, error, fetchData] = useDataApi<JobSchedule>(
    useDataSettings
  );

  const fetchJobSchedule = useCallback(
    (jobDefinition: JobDefinition) => {
      if (jobDefinition.apiVersion === ApiVersion.v2) {
        return Promise.resolve(null);
      }
      const userId = user!.profile.sub;
      const url = `${buddiUrls.iModelBridgeSchedulerUrl}/api/JobSchedule?jobdefId=${jobDefinition.id}&userId=${userId}&iModelId=${iModelId}&contextId=${projectId}`;

      const requestData: RequestData<JobSchedule> = {
        url: url,
        method: 'GET',
        map: jobScheduleDtoToJobScheduleMap,
      };
      return fetchData(requestData);
    },
    [user, buddiUrls, iModelId, projectId, fetchData]
  );

  return [jobSchedule, isLoading, error, fetchJobSchedule];
};

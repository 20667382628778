import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { RequestData, ResponseData } from '../../hooks/useDataApi/requestData';
import { useCallback } from 'react';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import { iModelHubAppId } from '../../constants/constants';
import { Item } from '../../entities/item';
import { productSettingsSpatialRootNamespace } from '../../entities/jobDefinition/jobDefinition';
import { v4 as newGuid } from 'uuid';
import {
  SpatialRoot,
  SpatialRootPropertiesDto,
} from '../../entities/spatialRootSetting';

export const useSetSpatialRoot = (
  projectId: string
): [
  null,
  boolean,
  any,
  (
    iModelId: string,
    spatialRoot: Item,
    jobDefinitionId: string,
    spatialMaster?: SpatialRoot
  ) => Promise<ResponseData<any>>
] => {
  const { logFeature } = useUsageLogger();

  const useDataSettings = { initialData: null, initialIsLoading: false };
  const [result, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const setSpatialRoot = useCallback(
    (
      iModelId: string,
      spatialRoot: Item,
      jobDefinitionId: string,
      spatialMaster?: SpatialRoot
    ) => {
      const requestData: RequestData<any> = {
        url: `/api/ProductSettings/SetSpatialRoot`,
        map: (x: any) => x,
        method: 'PUT',
        body: JSON.stringify({
          projectId,
          iModelId,
          applicationId: iModelHubAppId,
          spatialRootSetting: getSpatialRootSetting(
            spatialRoot,
            jobDefinitionId,
            spatialMaster
          ),
        }),
      };
      logFeature(Features.SetSpatial);
      return fetchData(requestData);
    },
    [projectId, logFeature, fetchData]
  );

  return [result, isLoading, error, setSpatialRoot];
};

export const getSpatialRootSetting = (
  spatialRoot: Item,
  jobDefinitionId: string,
  spatialMasters?: SpatialRoot
) => {
  return JSON.stringify({
    id: '',
    name: spatialMasters ? spatialMasters.parentName : newGuid(),
    namespace: productSettingsSpatialRootNamespace,
    properties: {
      name: spatialRoot.name,
      id: spatialRoot.id,
      jobDefinitionId: jobDefinitionId,
    } as SpatialRootPropertiesDto,
  });
};

import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import {
  SpatialRoot,
  spatialRootSettingDtoToSpatialSettingMap,
} from '../../entities/spatialRootSetting';
import { productSettingsSpatialRootNamespace } from '../../entities/jobDefinition/jobDefinition';
import { iModelHubAppId } from '../../constants/constants';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useGetSpatialRoot = (): [
  SpatialRoot[],
  boolean,
  any,
  (projectId: string, iModelId: string) => Promise<ResponseData<SpatialRoot[]>>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialIsLoading: true, initialData: null as any };
  const [result, isLoading, error, fetchData] =
    useDataApi<SpatialRoot[]>(useDataSettings);

  const getSpatialRoot = useCallback(
    (projectId: string, iModelId: string) => {
      const url = `${buddiUrls.productSettingsServiceUrl}/v1.0/Application/${iModelHubAppId}/Context/${projectId}/iModel/${iModelId}/Setting?$filter=namespace+eq+'${productSettingsSpatialRootNamespace}'`;

      const requestData: RequestData<SpatialRoot[]> = {
        url: url,
        method: 'GET',
        map: spatialRootSettingDtoToSpatialSettingMap,
      };
      return fetchData(requestData);
    },
    [buddiUrls, fetchData]
  );

  return [result, isLoading, error, getSpatialRoot];
};

import { EcInstances } from './EcSchema/ecInstances';

export interface Project {
  name: string;
  number: string;
  instanceId: string;
}

export interface ProjectInstance {
  instanceId: string;
  schemaName: string;
  className: string;
  properties: ProjectProperties;
}

interface ProjectProperties {
  ContextTypeId: number;
  Name: string;
  Number: string;
  UltimateRefId: string;
  DataLocationId: string;
  TeamId: string;
  Status: number;
  AllowExternalTeamMembers: boolean;
}

export const responseToProjectMap = (
  response: EcInstances<ProjectInstance>
): Project[] => {
  return response.instances.map(instance => {
    return {
      instanceId: instance.instanceId,
      name: instance.properties.Name,
      number: instance.properties.Number,
    } as Project;
  });
};

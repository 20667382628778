import './emptyJobPage.scss';
import { useTranslation } from 'react-i18next';
import { usePermissionContext } from '../../externalComponents/context/iModelPermissionContext/iModelPermissionContext';
import { Text, Button } from '@itwin/itwinui-react';
import { SvgImodel, SvgSync } from '@itwin/itwinui-icons-react';
import { Icon } from '../../externalComponents/components/icons/icon';
interface emptyJobPageProps {
  iModelName: string;
  onCreateNewJobClick: () => void;
}

export const EmptyJobPage = (props: emptyJobPageProps) => {
  const { onCreateNewJobClick: onCreateJob } = props;
  const { canModifyiModel } = usePermissionContext();
  const { t } = useTranslation();

  return (
    <div className="empty-jobs-page">
      <div className="icons-container">
        <Icon
          icon={SvgImodel}
          color="default"
          className="imodel-icon"
          data-testid="svg-imodel"
        />
        <Icon icon={SvgSync} size="xl" color="default" className="sync-icon" />
      </div>
      <div className="create-new-job-text">
        <Text variant="title" as="h2">
          {t('IModelWithNoJobs_Text')}
        </Text>
        <Text variant="subheading" as="h3">
          {t('CreateNewConnectionSuggestion_Text')}
        </Text>
      </div>
      <div className="create-job-button">
        <Button
          styleType="high-visibility"
          onClick={onCreateJob}
          data-testid={'CreateJob'}
          title={
            !canModifyiModel
              ? t('CreateConnectionBtnNoPermissions_Tooltip')
              : t('CreateConnectionBtn_Tooltip')
          }
          disabled={!canModifyiModel}
        >
          {t('CreateConnectionBtn_Label')}
        </Button>
      </div>
    </div>
  );
};

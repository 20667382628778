import { RouteComponentProps } from '@reach/router';
import Layout from '../layout/layout';
import { NonIdealState } from '@itwin/itwinui-react';
import { getNonIdealStateProps } from '../../externalComponents/services/nonIdealStateUtils/nonIdealStateUtils';
import { ErrorProps } from '../../externalComponents/typedef';

export const PublicRouteForError = (props: RouteComponentProps) => {
  const { location } = props;
  const state = location!.state as ErrorProps;
  const nonIdealStateProps = getNonIdealStateProps(state.errorType)!;

  return (
    <Layout>
      <div className="fit-to-parent">
        <NonIdealState
          {...nonIdealStateProps}
          description={state.errorMessage}
          data-testid="error-page"
        />
      </div>
    </Layout>
  );
};

import { navigate, NavigateFn, NavigateOptions } from '@reach/router';
import { parse, stringifyUrl, parseUrl } from 'query-string';

export const globalQueryParameterKeys = ['application'];

const extendedNavigate = (
  to: string,
  useGlobalQueryParams: boolean = true,
  navigateFn?: NavigateFn,
  options?: NavigateOptions<{}>
) => {
  navigateFn = navigateFn ?? navigate;
  return navigateFn(
    useGlobalQueryParams ? mergeWithGlobalQueryParams(to) : to,
    options
  );
};

export const mergeWithGlobalQueryParams = (to: string) => {
  const { url, query } = parseUrl(to);
  const windowQueryParams = parse(window.location.search);
  const resultQuery = query;
  globalQueryParameterKeys.forEach(qp => {
    if (qp in windowQueryParams && !(qp in query)) {
      resultQuery[qp] = windowQueryParams[qp];
    }
  });
  return stringifyUrl({ url: url, query: resultQuery });
};

export { extendedNavigate as navigate };

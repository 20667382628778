import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useUpdateScheduleV2 = (
  projectId: string,
  iModelId: string
): [
  any,
  boolean,
  any,
  (
    jobDefinition: JobDefinition,
    schedule: string,
    nextSchedule?: Date | null
  ) => Promise<ResponseData<null>>
] => {
  const { buddiUrls } = useBuddiContext();
  const { logFeature } = useUsageLogger();

  const useDataSettings = { initialIsLoading: false, initialData: null };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const updateScheduleV2 = useCallback(
    (
      jobDefinition: JobDefinition,
      schedule: string,
      nextSchedule?: Date | null
    ) => {
      const requestData: RequestData<null> = {
        map: x => x,
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${jobDefinition.id}')?contextId=${projectId}&iModelId=${iModelId}`,
        method: 'PATCH',
        body: JSON.stringify([
          {
            value: Number.parseInt(schedule),
            path: '/scheduleLoopIntervalInSeconds',
            op: 'replace',
          },
          {
            value: Number.parseInt(schedule) > 0,
            path: '/isScheduled',
            op: 'replace',
          },
          ...([
                {
                  value: nextSchedule?.toISOString(),
                  path: '/scheduleStartTimeUtc',
                  op: 'replace',
                },
              ]
            ),
        ]),
      };

      logFeature(Features.EditJobSchedule);
      return fetchData(requestData);
    },
    [
      buddiUrls.iModelBridgeServiceUrl,
      projectId,
      iModelId,
      logFeature,
      fetchData,
    ]
  );
  return [data, isLoading, error, updateScheduleV2];
};

export const getUpdateJobScheduleBody = (
  projectId: string,
  iModelId: string,
  jobDefinitionId: string,
  userId: string,
  schedule: string
) => {
  return JSON.stringify({
    jobdefId: jobDefinitionId,
    userId: userId,
    iModelId: iModelId,
    contextId: projectId,
    schedule: schedule,
  });
};

import { useGraphQl } from '../useGraphQL/useGraphQL';
import { responseSearchToItemsMap, Item } from '../../entities/item';
import { Repository } from '../../entities/repository';
import { ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { getDocumentServiceRepositoryType } from '../../services/bridgeLogic/bridgeLogic';

export const useGetSearchFolderItems = (
  repository: Repository
): [
  Item[],
  boolean,
  any,
  (
    currentFolderId: string,
    searchKeyword: string
  ) => Promise<ResponseData<Item[]>>
] => {
  const useDataSettings = {
    initialData: [],
    initialIsLoading: true,
  };
  const [data, isLoading, error, fetchData] = useGraphQl(
    responseSearchToItemsMap,
    useDataSettings
  );

  const fetchWrapper = useCallback(
    (currentFolderId: string, searchKeyword: string) => {
      const query = getSearchItemsGraphQLQuery(
        currentFolderId,
        repository,
        searchKeyword
      );
      return fetchData(query);
    },
    [repository, fetchData]
  );

  return [data, isLoading, error, fetchWrapper];
};

const getSearchItemsGraphQLQuery = (
  currentFolderId: string,
  repository: Repository,
  searchKeyword: string
) => {
  const repoType = getDocumentServiceRepositoryType(repository.type);
  return `{ deepSearch(input:{ type: ${repoType}, location: "${
    repository.location
  }", folderId:"${currentFolderId}", searchOpts:{name: "${searchKeyword.replace(
    /\"/g,
    '\\"'
  )}"} }) {name, id, isFolder, itemType, downloadUrl, parentId, description, version} }`;
};

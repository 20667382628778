import { UserManager } from 'oidc-client';

const scopes = 'data:read';

export const createForgeUserManager = () => {
  return new UserManager({
    authority: 'https://developer.api.autodesk.com/authentication/v1/',
    metadata: {
      authorization_endpoint:
        'https://developer.api.autodesk.com/authentication/v1/authorize',
    },
    client_id: 'Utand6OB961GOIelqLoPX20LFSgxGrFh',
    redirect_uri: `${window.location.origin}/forge-redirect`,
    response_type: 'token',
    query_status_response_type: 'code',
    scope: scopes,
    post_logout_redirect_uri: `${window.location.origin}/signout-oidc`,
  });
};

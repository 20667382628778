import React, { useState, ProviderProps } from 'react';
import { iModel } from '../../externalComponents/entities/iModel';

export interface IModelContextProps {
  currentIModel: iModel;
  setCurrentIModel: (iModel: iModel) => void;
}

interface IModelContextProviderProps
  extends Omit<ProviderProps<IModelContextProps>, 'value'> {}

export const IModelNameContext = React.createContext<IModelContextProps>({
  currentIModel: {} as iModel,
  setCurrentIModel: () => {},
});

export const IModelContextProvider = (props: IModelContextProviderProps) => {
  const [currentIModel, setCurrentIModel] = useState<iModel>({} as iModel);
  return (
    <IModelNameContext.Provider
      value={{ currentIModel, setCurrentIModel }}
      {...props}
    />
  );
};

export const useIModelContext = () => {
  const context = React.useContext(IModelNameContext);
  if (context == null) {
    throw new Error('navigationContext must be used inside provider');
  }
  return context;
};

import * as React from 'react';
export const SvgPWDILegacy = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <defs>
      <linearGradient
        id="projectwise-designintegration_SVG__a"
        x1={41.298}
        y1={343.877}
        x2={26.31}
        y2={328.857}
        gradientTransform="rotate(180 20.91 172.191)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#8dc63f" />
        <stop offset={0.131} stopColor="#86c43f" />
        <stop offset={0.32} stopColor="#73bd40" />
        <stop offset={0.543} stopColor="#54b241" />
        <stop offset={0.79} stopColor="#29a343" />
        <stop offset={0.995} stopColor="#009444" />
      </linearGradient>
    </defs>
    <rect
      width={16}
      height={16}
      rx={1.746}
      ry={1.746}
      fill="url(#projectwise-designintegration_SVG__a)"
    />
    <path
      d="M9.944 6.285l.015.005-1.497 1.803-.001.002 1.39.4.66.19 3.076-3.706.04-.042 1.44.38-.802-2.612-4.163 1.283 1.41.395-.118.134zM6.64 10.008l-.016-.005 1.48-1.782.018-.022-2.052-.59-3.106 3.744-.064-.011-1.385-.365.802 2.612 4.163-1.283-1.411-.395.12-.134zm6.369-.404l-.067-.016-3.145-.906-1.472-.423-.057.068-1.293 1.578 4.449 1.308.15.038-.943 1.12 4.163-1.283-.807-2.61zM3.573 6.692l.069.014L8.18 8.013l.077.023.04-.05 1.31-1.597L5.16 5.08l-.15-.038.943-1.12L1.79 5.205l.807 2.61z"
      fill="#006838"
    />
    <path
      d="M9.646 5.867l.016.004-1.498 1.803-.001.002 1.391.4.66.19L13.29 4.56l.04-.041 1.44.379-.802-2.612L9.805 3.57l1.41.395-.119.134zM6.343 9.589l-.016-.004 1.48-1.783.019-.022-2.052-.59-3.106 3.745-.064-.012-1.385-.364.802 2.611 4.163-1.282-1.412-.396.12-.133zm6.369-.404l-.067-.016L9.5 8.263 8.028 7.84l-.057.069-1.293 1.577 4.449 1.308.15.038-.943 1.12 4.163-1.282-.807-2.61zM3.275 6.273l.069.014 4.54 1.308.076.022.04-.049L9.31 5.97 4.862 4.662l-.15-.038.943-1.12-4.163 1.282.807 2.61z"
      fill="#fff"
    />
  </svg>
);
export default SvgPWDILegacy;

import i18n from '../../i18n/i18n';
import { assertUnreachable } from '../../services/uiUtils/assertUnreachable';
import { getBridgeTypeDisplayName } from '../bridge';
import {
  FileRun,
  FileRunState,
  JobRun,
  JobRunState,
  JobRunTriggerType,
  TaskStatus,
} from './jobRunStatus';
import {
  FileRunDto,
  FileRunStatusV1,
  JobRunDtoStatus,
  JobRunDtoTriggerType,
  JobRunStatusDto,
  TaskStatusDto,
} from './jobRunStatusDTO';
import {
  DetailsDtoV2,
  ErrorDtoV2,
  GetRunsDtoV2,
  JobDtoV2,
  JobRunStateDtoV2,
  JobRunTriggerTypeDtoV2,
  ResultDtoV2,
  RunDtoV2,
  TaskDtoV2,
  TaskStateDtoV2,
} from './jobRunStatusDTOv2';

export const mapJobRunStatusDtoToJobRun = (x: JobRunStatusDto[]): JobRun[] => {
  return x.map((s: JobRunStatusDto) => {
    const unique: FileRunDto[] = [];
    s.statuses.forEach(run => {
      if (
        !unique.find(u => u.jobStatus.inputFile === run.jobStatus.inputFile)
      ) {
        const statusToBeAdded = s.statuses
          .filter(r => r.jobStatus.inputFile === run.jobStatus.inputFile)
          .sort(
            (a, b) =>
              new Date(b.jobStatus.startTime).getTime() -
              new Date(a.jobStatus.startTime).getTime()
          )[0];
        unique.push(statusToBeAdded);
      }
    });
    return {
      jobRunId: s.jobRun.jobRunId,
      jobStatus: mapV1JobStatus(s.jobRun.jobStatus),
      startTime: s.jobRun.startTime,
      endTime: s.jobRun.endTime,
      details: s.jobRun.details,
      triggerType: mapV1TriggerType(s.jobRun.triggerType),
      fileRuns: mapV1FileRunDtoToFileRun(unique),
    } as JobRun;
  });
};

const mapV1TriggerType = (
  dtoTriggerType: JobRunDtoTriggerType
): JobRunTriggerType => {
  switch (dtoTriggerType) {
    case JobRunDtoTriggerType.Scheduled:
      return JobRunTriggerType.Scheduled;
    case JobRunDtoTriggerType.Http:
      return JobRunTriggerType.Manual;
    default:
      assertUnreachable(dtoTriggerType);
      return JobRunTriggerType.Manual;
  }
};

const mapV1JobStatus = (dtoStatus: JobRunDtoStatus): JobRunState => {
  switch (dtoStatus) {
    case JobRunDtoStatus.None:
      return JobRunState.None;
    case JobRunDtoStatus.NotStarted:
      return JobRunState.NotStarted;
    case JobRunDtoStatus.InProgress:
      return JobRunState.InProgress;
    case JobRunDtoStatus.PartiallyCompleted:
      return JobRunState.PartiallyCompleted;
    case JobRunDtoStatus.Completed:
      return JobRunState.Completed;
    case JobRunDtoStatus.Failed:
      return JobRunState.Failed;
    case JobRunDtoStatus.Skipped:
      return JobRunState.Skipped;
    default:
      assertUnreachable(dtoStatus);
      return JobRunState.None;
  }
};

const mapV1FileRunDtoToFileRun = (fileRunDto: FileRunDto[]): FileRun[] => {
  return fileRunDto.map(dto => {
    return {
      id: dto.jobStatus.activityId,
      status: mapV1FileStatus(dto.jobStatus.state),
      totalTime: null,
      startTime: dto.jobStatus.startTime,
      endTime: dto.jobStatus.endTime,
      errorCode: undefined,
      details: dto.jobStatus.details,
      inputFile: dto.jobStatus.inputFile,
      inputFileId: dto.jobStatus.inputFileId,
      taskStatuses: taskStatusDtoToTaskStatusMap(dto.taskStatuses),
      bridgeType: '',
    } as FileRun;
  });
};

const mapV1FileStatus = (dtoStatus: FileRunStatusV1): FileRunState => {
  switch (dtoStatus) {
    case FileRunStatusV1.NotFound:
      return FileRunState.NotFound;
    case FileRunStatusV1.NotStarted:
      return FileRunState.NotStarted;
    case FileRunStatusV1.InProgress:
      return FileRunState.InProgress;
    case FileRunStatusV1.Succeeded:
      return FileRunState.Succeeded;
    case FileRunStatusV1.Fail:
      return FileRunState.Fail;
    case FileRunStatusV1.Canceled:
      return FileRunState.Canceled;
    case FileRunStatusV1.Timeout:
      return FileRunState.Timeout;
    default:
      assertUnreachable(dtoStatus);
      return FileRunState.NotFound;
  }
};

export const taskStatusDtoToTaskStatusMap = (
  tasks: TaskStatusDto[]
): TaskStatus[] => {
  return tasks.map(task => task as TaskStatus);
};
// v2
export const mapJobRunStatusV2DtoToJobRun = (
  x: GetRunsDtoV2,
  showPreprocessor: boolean,
  enableSyncErrorTotals: boolean,
  showNewJobStateCompleteDetailsLabel?: boolean
): JobRun[] => {
  return x.value.map((s: RunDtoV2) => {
    return mapSingleJobRunStatusV2DtoToJobRun(
      s,
      showPreprocessor,
      enableSyncErrorTotals,
      showNewJobStateCompleteDetailsLabel
    );
  });
};

export const mapSingleJobRunStatusV2DtoToJobRun = (
  x: RunDtoV2,
  showPreprocessorJob: boolean,
  enableSyncErrorTotals: boolean,
  showNewJobStateCompleteDetailsLabel?: boolean
): JobRun => {
  const anyFinishedWithIssues = x.jobs.some(job =>
    job.tasks.some(
      task =>
        task?.details != null &&
        task.details.some(detail => {
          const oldErrorCheck = detail.type === 'BadgersReportReferenceError';
          return enableSyncErrorTotals
            ? (detail.totalErrorsCount ?? +oldErrorCheck) > 0
            : oldErrorCheck;
        })
    )
  );

  const jobStatus = mapV2JobStatus(x.state, x.result, anyFinishedWithIssues);
  return {
    jobRunId: x.id,
    jobStatus: jobStatus,
    startTime:
      x.startTimestamp === '0001-01-01T00:00:00Z' ? null : x.startTimestamp,
    processingStartTime: 
      x.processingStartTimeStamp === '0001-01-01T00:00:00Z' ? null : x.processingStartTimeStamp,
    endTime: x.endTimeStamp === '0001-01-01T00:00:00Z' ? null : x.endTimeStamp,
    details: mapV2Details(
      jobStatus,
      x.jobs.length === 1 && x.jobs[0].jobType === 'Preprocessor',
      x.error,
      showNewJobStateCompleteDetailsLabel
    ),
    triggerType: mapV2TriggerType(x.triggerType),
    fileRuns: mapV2JobsDtoToFileRun(
      x.jobs,
      showPreprocessorJob,
      enableSyncErrorTotals
    ),
    errorCode: x.error != null ? x.error.errorCode : undefined,
    knownIssueArticleLink:
      x.error != null &&
      x.error.kbArticleLink != null &&
      x.error.kbArticleLink.length !== 0
        ? x.error.kbArticleLink
        : undefined,
    projectId: x.contextId,
    iModelId: x.iModelId,
    connectionDefinitionId: x.connectionDefinitionId,
    dataLocationId: x.dataLocationId,
  } as JobRun;
};

export const mapV2JobStatus = (
  dtoStatus: JobRunStateDtoV2,
  dtoResult: ResultDtoV2,
  anyFinishedWithIssues: boolean
): JobRunState => {
  switch (dtoStatus) {
    case JobRunStateDtoV2.NotFound:
      return JobRunState.None;
    case JobRunStateDtoV2.Completed:
      if (
        dtoResult === ResultDtoV2.Error ||
        dtoResult === ResultDtoV2.Undetermined ||
        dtoResult === ResultDtoV2.TimedOut
      )
        return JobRunState.Failed;
      if (dtoResult === ResultDtoV2.Paused) return JobRunState.Paused;
      if (dtoResult === ResultDtoV2.Skipped) return JobRunState.Skipped;
      if (dtoResult === ResultDtoV2.Canceled) return JobRunState.Canceled;
      if (dtoResult === ResultDtoV2.PartialSuccess)
        return JobRunState.PartiallyCompleted;
      if (dtoResult === ResultDtoV2.SuccessWithPaused)
        return JobRunState.CompletedWithPaused;
      if (anyFinishedWithIssues) return JobRunState.CompletedWithIssues;
      return JobRunState.Completed;
    case JobRunStateDtoV2.Finalizing:
      return JobRunState.InProgress;
    case JobRunStateDtoV2.Executing:
      return JobRunState.InProgress;
    case JobRunStateDtoV2.Queued:
      return JobRunState.Queued;
    case JobRunStateDtoV2.WaitingToExecute:
      return JobRunState.NotStarted;
    case JobRunStateDtoV2.Idle:
      return JobRunState.NotStarted;
    case JobRunStateDtoV2.WaitingToRetry:
      return JobRunState.InProgress;
    case JobRunStateDtoV2.NotStarted:
      return JobRunState.NotStarted;
    default:
      assertUnreachable(dtoStatus);
      return JobRunState.None;
  }
};

const mapV2TriggerType = (
  dtoTriggerType: JobRunTriggerTypeDtoV2
): JobRunTriggerType => {
  switch (dtoTriggerType) {
    case JobRunTriggerTypeDtoV2.Schedule:
      return JobRunTriggerType.Scheduled;
    case JobRunTriggerTypeDtoV2.OnDemand:
      return JobRunTriggerType.Manual;
    default:
      assertUnreachable(dtoTriggerType);
      return JobRunTriggerType.Manual;
  }
};

const getDetailsMessage = (
  specificBadgersDetails: DetailsDtoV2,
  enableSyncErrorTotals: boolean
): string => {
  const errorCount =
    enableSyncErrorTotals &&
    specificBadgersDetails.totalErrorsCount !== undefined
      ? specificBadgersDetails.totalErrorsCount
      : specificBadgersDetails.count;
  return `${errorCount} ${i18n.t(
    errorCount === 1 ? 'SingleIssue_Label' : 'MultipleIssue_Label'
  )}`;
};

export const mapV2JobsDtoToFileRun = (
  jobs: JobDtoV2[],
  showPreprocessor: boolean,
  enableSyncErrorTotals: boolean
): FileRun[] => {
  const fileRuns: FileRun[] = [];
  const isDummyAffinityJob = (j: JobDtoV2) =>
    showPreprocessor ? true : j.jobType !== 'Preprocessor';
  jobs.filter(isDummyAffinityJob).forEach(job => {
    job.tasks.forEach(taskDto => {
      const badgersDetails = taskDto.details?.find(detail =>
        enableSyncErrorTotals && detail.totalErrorsCount !== undefined
          ? detail.totalErrorsCount
          : detail.type === 'BadgersReportReferenceError'
      );
      fileRuns.push({
        id: taskDto.id,
        status: mapV2FileStatus(
          taskDto.state,
          taskDto.result,
          badgersDetails !== undefined
        ),
        totalTime: null,
        startTime: taskDto.startTimestamp.startsWith('0001')
          ? null
          : taskDto.startTimestamp,
        endTime: taskDto.endTimeStamp.startsWith('0001')
          ? null
          : taskDto.endTimeStamp,
        errorCode: taskDto.error != null ? taskDto.error.errorCode : undefined,
        details: getV2TaskDetails(
          taskDto,
          badgersDetails,
          enableSyncErrorTotals
        ),
        inputFileId: taskDto.fileId,
        inputFile: taskDto.fileName == null ? null : taskDto.fileName,
        taskStatuses: [] as TaskStatus[],
        batchJobRunId: job.id,
        bridgeType: getBridgeTypeDisplayName(job.bridgeType),
        action: taskDto.action,
        knownIssueArticleLink:
          taskDto.error != null &&
          taskDto.error.kbArticleLink != null &&
          taskDto.error.kbArticleLink.length !== 0
            ? taskDto.error.kbArticleLink
            : undefined,
        sasUrl: taskDto.saasUrl,
      } as FileRun);
    });
  });
  return fileRuns;
};

const getV2TaskDetails = (
  taskDto: TaskDtoV2,
  badgersDetails: DetailsDtoV2 | undefined,
  enableSyncErrorTotals: boolean
): string | undefined => {
  const bimReportProcessingError = taskDto.details?.find(i =>
    enableSyncErrorTotals && i.totalErrorsCount !== undefined
      ? i.totalErrorsCount > 0
      : i.type === 'BimReportProcessingError'
  );
  if (taskDto.error?.description != null) return taskDto.error.description;
  if (bimReportProcessingError !== undefined)
    return bimReportProcessingError.message;
  if (taskDto.error != null) return taskDto.error.message;
  if (badgersDetails != null)
    return getDetailsMessage(badgersDetails, enableSyncErrorTotals);
  else return taskDto.result;
};

const mapV2FileStatus = (
  dtoStatus: TaskStateDtoV2,
  dtoResult: ResultDtoV2,
  hasBadgersDetails: boolean = false
): FileRunState => {
  switch (dtoStatus) {
    case TaskStateDtoV2.NotFound:
      return FileRunState.NotFound;
    case TaskStateDtoV2.Completed:
      if (dtoResult === ResultDtoV2.Skipped) return FileRunState.Skipped;
      if (
        dtoResult === ResultDtoV2.Error ||
        dtoResult === ResultDtoV2.Undetermined
      )
        return FileRunState.Fail;
      if (dtoResult === ResultDtoV2.Paused) return FileRunState.Paused;
      if (dtoResult === ResultDtoV2.Canceled) return FileRunState.Canceled;
      if (dtoResult === ResultDtoV2.TimedOut) return FileRunState.Timeout;
      if (hasBadgersDetails) return FileRunState.CompletedWithIssues;
      return FileRunState.Succeeded;
    case TaskStateDtoV2.Executing:
      return FileRunState.InProgress;
    case TaskStateDtoV2.NotStarted:
      return FileRunState.NotStarted;
    case TaskStateDtoV2.WaitingToRetry:
      return FileRunState.WaitingToRetry;
    case TaskStateDtoV2.WaitingToExecute:
      return FileRunState.WaitingToExecute;
    default:
      assertUnreachable(dtoStatus);
      return FileRunState.NotFound;
  }
};

export const mapV2Details = (
  state: JobRunState,
  preprocessorJob: boolean,
  error?: ErrorDtoV2,
  showNewJobStateCompleteDetailsLabel?: boolean
): string => {
  switch (state) {
    case JobRunState.None:
      return '';
    case JobRunState.NotStarted:
      if (!preprocessorJob) {
        return i18n.t('JobRunDetails_NotStarted');
      } else {
        return i18n.t('JobRunDetails_ProcessingConnectors');
      }
    case JobRunState.Queued:
      return i18n.t('JobRunDetails_Queued');
    case JobRunState.InProgress:
      return i18n.t('JobRunDetails_Executing');
    case JobRunState.PartiallyCompleted:
      return i18n.t('JobRunDetails_PartiallyCompleted');
    case JobRunState.Completed:
      return i18n.t(
        showNewJobStateCompleteDetailsLabel
          ? 'NewJobRunDetails_Completed'
          : 'JobRunDetails_Completed'
      );
    case JobRunState.CompletedWithIssues:
      return i18n.t('JobRunDetails_CompletedWithIssues');
    case JobRunState.CompletedWithPaused:
      return i18n.t('JobRunDetails_CompletedWithPaused');
    case JobRunState.Failed: {
      const ignoredErrors: any = {
        RNE_BGS_1001: true, // All processing Jobs of the Run failed.
        RNE_BGS_1002: true, // Some Jobs of the Run failed.
      };
      if (error != null && ignoredErrors[error.errorCode] == null)
        return error.description != null ? error.description : error.message;

      return i18n.t('JobRunDetails_Failed');
    }
    case JobRunState.Skipped:
      return i18n.t('JobRunDetails_Skipped');
    case JobRunState.Paused:
      return i18n.t('JobRunDetails_Paused');
    case JobRunState.Canceled:
      return i18n.t('JobRunDetails_Canceled');
    default:
      assertUnreachable(state);
      return JobRunState.None;
  }
};

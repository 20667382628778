import { useDataApi } from '../useDataApi/useDataApi';
import { iModelHubAppId } from '../../constants/constants';
import { useCallback } from 'react';
import { ResponseData } from '../useDataApi/requestData';

export const useUpdateDocumentMapping = (
  projectId: string,
  iModelId: string
): [
  any,
  boolean,
  any,
  (updatedDocumentMapping: any) => Promise<ResponseData<null>>
] => {
  const useDataSettings = { initialIsLoading: false, initialData: null };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const updateJobDefinition = useCallback(
    (documentMapping: any) => {
      const requestData: any = {
        url: `/api/ProductSettings/UpdateDocumentMapping`,
        map: (x: any) => x,
        method: 'POST',
        body: JSON.stringify({
          projectId,
          iModelId,
          applicationId: iModelHubAppId,
          DocumentMapping: JSON.stringify(documentMapping),
        }),
      };
      return fetchData(requestData);
    },
    [projectId, iModelId, fetchData]
  );

  return [data, isLoading, error, updateJobDefinition];
};

import React, { useState, useEffect } from 'react';
import { Button } from '@itwin/itwinui-react';
import './pwdiConnection.scss';
import { useGraphQl } from '../../hooks/useGraphQL/useGraphQL';
import {
  Datasource,
  PWProject,
  responseToDataSourceMap,
  responseToProjectMap,
} from '../../entities/datasource';
import { LoadingOverlay } from '../loadingOverlay/loadingOverlay';
import {
  useOptionalInputField,
  useInputField,
} from '../../hooks/useInputField/useInputField';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import prependHttp from 'prepend-http';
import isUrl from 'is-url';
// @ts-ignore
import {
  RecentPwdiConnection,
  RecentPwdiConnections,
} from './recentPwdiConnections';
import { useRecentPwdiConnections } from '../../hooks/useRecentPwdiConnections/useRecentPwdiConnections';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { RepositoryType } from '../../entities/repository';
import { Text, LabeledInput, LabeledSelect } from '@itwin/itwinui-react';
import { isValidGuid } from '../../services/uiUtils/uuidUtils';
import { parseUrl } from 'query-string';

interface PwdiConnectionProps {
  repoType: RepositoryType;
  onDataLocationChange: (
    location: string,
    currentConnection?: RecentPwdiConnection,
    hostNameUrl?: string
  ) => void;
  onLoadingStateChange: (isLoading: boolean) => void;
  onRecentConnectionSelection: (
    selectedConnection?: RecentPwdiConnection
  ) => void;
}

export const PwdiConnection = (props: PwdiConnectionProps) => {
  const {
    repoType,
    onDataLocationChange,
    onLoadingStateChange,
    onRecentConnectionSelection,
  } = props;
  const [wsgServerUrl, setWsgServerUrl] = useState('');
  const [hostNameUrl, setHostNameUrl] = useState('');
  const [serverUrl, setServerUrl] = useState('');
  const [workAreaId, setWorkAreaId] = useState('');
  const [selectedDatasource, setSelectedDatasource] = useState<Datasource>();
  const [selectedProject, setSelectedProject] = useState<PWProject>();
  const [dataFullLocation, setDataFullLocation] = useState('');
  const [pastWsgServerUrl, setPastWsgServerUrl] = useState(''); // used to check if datasource fetch has been performed
  const [pastDatasourceUrl, setPastDatasourceUrl] = useState<
    String | undefined
  >(''); // used to check if project fetch has been performed

  const [isClearedFields, setIsClearedFields] = useState<boolean>(true);

  const useDataSettings = { initialIsLoading: false, initialData: null };
  const [dataSources, areDatasourcesLoading, , fetchDataSources] = useGraphQl(
    responseToDataSourceMap,
    useDataSettings
  );

  const [projects, areProjectsLoading, , fetchProjects] = useGraphQl(
    responseToProjectMap,
    useDataSettings
  );

  const [
    wsgServerUrlStatus,
    wsgServerUrlErrorMessage,
    isWsgServerUrlInvalid,
    setWsgServerUrlInputField,
    invalidateWsgServerUrlInputField,
  ] = useOptionalInputField();

  const [
    hostNameUrlStatus,
    hostNameUrlErrorMessage,
    ,
    ,
    invalidatehostNameUrlInputField,
  ] = useOptionalInputField();

  const [workAreaIdStatus, workAreaIdErrorMessage, , setWorkAreaIdField, ,] =
    useOptionalInputField();

  const [
    ,
    dataSourceErrorMessage,
    isDataSourceInvalid,
    setDataSourceField,
    invalidateDataSourceField,
  ] = useInputField();

  const { t } = useTranslation();

  const [
    recentConnections,
    selectedRecentConnection,
    selectRecentConnection,
    ,
  ] = useRecentPwdiConnections();

  const { hostNameInput, workAreaIdInput, workAreaDropdown } =
    useFeatureToggleContext();

  useEffect(() => {
    if (wsgServerUrl === '') {
      selectRecentConnection(undefined);
      setSelectedProject(undefined);
      setSelectedDatasource(undefined);
      setPastWsgServerUrl('');
      setServerUrl('');
      setIsClearedFields(true);
      setHostNameUrl('');
      setProject(undefined);
      onRecentConnectionSelection();
    } else {
      setHostNameUrl(wsgServerUrl.replace(/https?:\/\//g, ''));
    }
  }, [wsgServerUrl]);

  useEffect(() => {
    const currentConnection: RecentPwdiConnection | undefined =
      wsgServerUrl && selectedDatasource && selectedProject
        ? {
            serverUrl: wsgServerUrl,
            hostName: `https://${hostNameUrl}`,
            datasource: selectedDatasource!,
            workArea: selectedProject!,
          }
        : undefined;

    onDataLocationChange(
      dataFullLocation,
      currentConnection,
      `https://${hostNameUrl}` // Add https for bridge fwk
    );
  }, [dataFullLocation]);

  const fetchDatasourceProjects = () => {
    const fetchProjectsAndHandle = async () => {
      if (selectedDatasource != null) {
        const response = await fetchProjects(
          `{ pwProjects( serverUrl: "${serverUrl}" datasourceId: "${selectedDatasource.id}" wsgVersion: "${selectedDatasource.wsgVersion}") { id name} }`
        );
        if (response.data == null) {
          setDataSourceField(
            'negative',
            t('NoDataSourcePermission_ErrorMessage')
          );
          onDataLocationChange('', undefined);
        } else {
          setDataSourceField(undefined, '');
        }
      }
    };
    fetchProjectsAndHandle();
  };

  useEffect(() => {
    if (selectedRecentConnection) {
      setProject(selectedProject);
    }
  }, [projects]);

  useEffect(() => {
      setProject(selectedProject);
  }, [selectedProject]);

  const setProject = (project: PWProject | undefined) => {
    if (selectedDatasource !== undefined && project !== undefined) {
      setDataFullLocation(
        `${serverUrl.replace(/\/+$/, '')}/ws/v${selectedDatasource.wsgVersion}/repositories/${selectedDatasource.id}/PW_WSG/Project/${project.id}`
      );
      setSelectedProject(project);
    } else {
      setDataFullLocation('');
    }
  };

  const onDatasourceChange = (value: string) => {
    setSelectedDatasource(dataSources!.find(ds => ds.id === value));
  };

  const getUrl = () => {
    const urlPrependingHttps = prependHttp(wsgServerUrl).toLocaleLowerCase();
    const { url } = parseUrl(urlPrependingHttps);
    return url;
  };

  const fetchDataSourcesAction = async (url: string, resetFields?: boolean) => {
    setSelectedDatasource(undefined);
    setProject(undefined);
    const response = await fetchDataSources(
      `{ pwDatasources( serverUrl: "${url}") { id displayLabel wsgVersion} }`
    );
    if (response.data == null && !resetFields) {
      setWsgServerUrlInputField(
        'negative',
        t('NoDataSourcesFetched_ErrorMessage')
      );
      setPastWsgServerUrl(url);
      return;
    }

    setPastWsgServerUrl(url);
    setWsgServerUrl(url);
    setServerUrl(url);
  };

  const onGetDatasource = () => {
    if (isDataSourceInvalid) {
      invalidateDataSourceField();
    }
    const url = getUrl();
    if (!isUrl(url)) {
      setWsgServerUrlInputField('negative', 'Please enter a valid URL');
      if (projects != null || dataSources != null) {
        setSelectedDatasource(undefined);
        setProject(undefined);
      }
      return;
    }

    setPastWsgServerUrl(url);
    setWsgServerUrl(url);
    fetchDataSourcesAction(url);
  };

  const onProjectChange = (value: string) => {
    setProject(projects!.find(pr => pr.id === value));
  };

  const areResourcesLoading = areDatasourcesLoading || areProjectsLoading;
  const doShowDataSources =
    (selectedDatasource != null || dataSources != null) &&
    !areDatasourcesLoading;
  const doShowProjects = selectedDatasource != null && !areProjectsLoading;

  useEffect(() => {
    onLoadingStateChange(areResourcesLoading);
  }, [areResourcesLoading]);

  useEffect(() => {
    if (workAreaId === '') {
      return;
    }
    if (isValidGuid(workAreaId)) {
      setProject({ id: workAreaId, name: workAreaId });
      setWorkAreaIdField('positive', t('PwWorkAreaId_Validate_Positive'));
    } else {
      setWorkAreaIdField('negative', t('PwWorkAreaId_Validate_Negative'));
      setProject(undefined);
    }
  }, [workAreaId]);

  const isWorkAreInputDisabled = () =>
    !doShowProjects ||
    !doShowDataSources ||
    dataSourceErrorMessage.length > 0 ||
    isClearedFields ||
    isWsgServerUrlInvalid;

  return (
    <>
      <Text variant="subheading" as="h3">
        {t('SpecifyConnection_Headline')}
      </Text>
      {recentConnections.length > 0 && (
        <RecentPwdiConnections
          recentConnections={recentConnections}
          selectedConnection={selectedRecentConnection}
          onSelect={(selected: RecentPwdiConnection) => {
            setSelectedProject(selected.workArea);
            setProject(selectedProject);
            setWsgServerUrl(selected.serverUrl);
            setSelectedDatasource(selected.datasource);
            setServerUrl(selected.serverUrl);
            selectRecentConnection(selected);
            invalidateWsgServerUrlInputField();
            onRecentConnectionSelection(selected);
            setIsClearedFields(false);
          }}
          onClose={() => {
            setWsgServerUrl('');
          }}
        />
      )}
      <div className="pwdi-connection-inputs">
        <LoadingOverlay loading={areResourcesLoading}>
          <div
            className={cx('server-url-group', {
              'has-message': wsgServerUrlStatus !== undefined,
            })}
          >
            <div className="server-url-labeled-input">
              <LabeledInput
                label={t('ServerWSGUrlInputField_Label')}
                id="server-url-input"
                onChange={(e: any) => {
                  invalidateWsgServerUrlInputField();
                  setWsgServerUrl(e.target.value);
                }}
                value={wsgServerUrl}
                disabled={areResourcesLoading}
                status={wsgServerUrlStatus}
                message={wsgServerUrlErrorMessage}
                data-testid={'pwdi-wsg-url'}
              />
            </div>
            <Button
              styleType="high-visibility"
              className="get-datasources-btn"
              disabled={wsgServerUrl === ''}
              onClick={() => {
                selectRecentConnection(undefined);
                onRecentConnectionSelection();
                setSelectedProject(undefined);
                onGetDatasource();
                isWsgServerUrlInvalid
                  ? setIsClearedFields(true)
                  : setIsClearedFields(false);
              }}
            >
              {t('GetDataSourceBtn_Label')}
            </Button>
          </div>
          {repoType === RepositoryType.PWDI_LEGACY && hostNameInput && (
            <div className="server-url-labeled-input">
              <LabeledInput
                label={t('ServerDatasourceUrlInputField_Label')}
                id="ds-server-url-input"
                onChange={(e: any) => {
                  invalidatehostNameUrlInputField();
                  setHostNameUrl(e.target.value);
                }}
                value={hostNameUrl}
                disabled={areResourcesLoading || !!selectedRecentConnection}
                status={hostNameUrlStatus}
                message={hostNameUrlErrorMessage}
                data-testid={'pwdi-host-name-url'}
              />
            </div>
          )}
          <div className="server-url-labeled-select">
            <LabeledSelect
              data-testid="datasource-select"
              label={t('DatasourceDropdownInput_Label')}
              value={
                !isClearedFields && !isWsgServerUrlInvalid
                  ? selectedDatasource?.id
                  : undefined
              }
              options={
                !isClearedFields && !isWsgServerUrlInvalid && dataSources
                  ? dataSources.map(ds => ({
                      label: ds.displayLabel,
                      value: ds.id,
                    }))
                  : []
              }
              onChange={value => {
                selectRecentConnection(undefined);
                setSelectedProject(undefined);
                onRecentConnectionSelection();
                onDatasourceChange(value);
                setDataSourceField(undefined, '');
                setIsClearedFields(false);
              }}
              onClick={() => {
                if (
                  (wsgServerUrl !== '' && wsgServerUrl !== pastWsgServerUrl) ||
                  dataSources?.length === 0
                ) {
                  fetchDataSourcesAction(getUrl());
                  setSelectedDatasource(selectedDatasource);
                }
              }}
              disabled={
                !doShowDataSources || isClearedFields || isWsgServerUrlInvalid
              }
              message={dataSourceErrorMessage}
              status={dataSourceErrorMessage ? 'negative' : undefined}
              placeholder={
                selectedDatasource
                  ? selectedDatasource.displayLabel
                  : doShowDataSources &&
                    !isClearedFields &&
                    !isWsgServerUrlInvalid
                  ? t('PwSelectDropdown_SelectDatasource_Placeholder')
                  : t('PwSelectDropdown_AddServerFirst_Placeholder')
              }
            />
            {workAreaIdInput && (
              <LabeledInput
                label={t('PwWorkArea_IdInput_Label')}
                id="project-id-input"
                onChange={(e: any) => {
                  setWorkAreaId(e.target.value);
                }}
                disabled={isWorkAreInputDisabled()}
                value={workAreaId}
                status={workAreaIdStatus}
                message={workAreaIdErrorMessage}
                data-testid={'pwdi-work-area-id'}
              />
            )}

            {workAreaDropdown && (
              <LabeledSelect
                data-testid="work-area-select"
                label={t('WorkAreaDropDownInput_Label')}
                value={
                  !isClearedFields || !isWsgServerUrlInvalid
                    ? selectedProject?.id
                    : undefined
                }
                options={
                  !isClearedFields &&
                  !isWsgServerUrlInvalid &&
                  projects &&
                  doShowProjects &&
                  doShowDataSources
                    ? projects.map(project =>
                        projects.filter(item => item.name === project.name)
                          .length > 1
                          ? {
                              label: project.name + ' (' + project.id + ')',
                              value: project.id,
                            }
                          : { label: project.name, value: project.id }
                      )
                    : []
                }
                onChange={value => {
                  selectRecentConnection(undefined);
                  onRecentConnectionSelection();
                  onProjectChange(value);
                }}
                onClick={() => {
                  if (selectedDatasource?.displayLabel !== pastDatasourceUrl) {
                    fetchDatasourceProjects();
                    setPastDatasourceUrl(selectedDatasource?.displayLabel);
                  }
                }}
                disabled={isWorkAreInputDisabled() || workAreaId !== ''}
                placeholder={
                  selectedProject
                    ? selectedProject.name
                    : doShowDataSources &&
                      !isClearedFields &&
                      !isWsgServerUrlInvalid
                    ? t('PwSelectDropdown_SelectWorkArea_Placeholder')
                    : t('PwSelectDropdown_AddServerFirst_Placeholder')
                }
              />
            )}
          </div>
        </LoadingOverlay>
      </div>
    </>
  );
};

import {
  ApiVersion,
  JobDefinition,
  productSettingsJobsNamespace,
} from '../../entities/jobDefinition/jobDefinition';
import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { iModelHubAppId } from '../../constants/constants';
import { useCallback } from 'react';
import { ResponseData, RequestData } from '../../hooks/useDataApi/requestData';
import {
  JobDefinitionDto,
  JobDefinitionPropertiesDto,
} from '../../entities/jobDefinition/jobDefintionDTO';
import { jobDefinitionToJobDefinitionPropertiesDtoMap } from '../../entities/jobDefinition/jobDefinitionMappings';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
export const useUpdateJobDefinition = (
  projectId: string,
  iModelId: string
): [
  any,
  boolean,
  any,
  (updatedJob: JobDefinition) => Promise<ResponseData<null>>
] => {
  const { buddiUrls } = useBuddiContext();
  const useDataSettings = { initialIsLoading: false, initialData: null };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const v1UpdateJobDefinition = useCallback(
    (updatedJob: JobDefinition) => {
      const jobDef = getUpdatJobDefinitionBody(updatedJob);
      const requestData: RequestData<any> = {
        url: `/api/ProductSettings/UpdateJobDefinition`,
        map: (x: any) => x,
        method: 'POST',
        body: JSON.stringify({
          projectId,
          iModelId,
          applicationId: iModelHubAppId,
          jobDefinition: jobDef,
        }),
      };

      return fetchData(requestData);
    },

    [projectId, iModelId, fetchData]
  );
  const v2UpdateDefinition = useCallback(
    (updatedJob: JobDefinition) => {
      const requestData: RequestData<any> = {
        map: x => x,
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${updatedJob.id}')?contextId=${projectId}&iModelId=${iModelId}`,
        method: 'PATCH',
        body: JSON.stringify([
          {
            value: updatedJob.userId,
            path: '/ownerId',
            op: 'replace',
          },
        ]),
      };
      return fetchData(requestData);
    },
    [buddiUrls, projectId, iModelId, fetchData]
  );
  const updateJobDefinition = (job: JobDefinition) =>
    job.apiVersion === ApiVersion.v1
      ? v1UpdateJobDefinition(job)
      : v2UpdateDefinition(job);

  return [data, isLoading, error, updateJobDefinition];
};

const getUpdatJobDefinitionBody = (updatedJob: JobDefinition) => {
  return JSON.stringify({
    id: '',
    name: updatedJob.uniqueSettingName,
    namespace: productSettingsJobsNamespace,
    properties: {
      ...jobDefinitionToJobDefinitionPropertiesDtoMap(updatedJob),
    } as JobDefinitionPropertiesDto,
  } as JobDefinitionDto);
};

import { useDataApi } from '../../externalComponents/hooks/useDataApi/useDataApi';
import {
  RequestData,
  ResponseData,
} from '../../externalComponents/hooks/useDataApi/requestData';
import {
  useAppContext,
  Configuration,
} from '../../context/appContext/appContext';
import { useCallback } from 'react';
import { useUsageLogger } from '../../externalComponents/context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../externalComponents/context/usageLoggerContext/featureConstants';

export const useCreateIModel = (
  projectId: string
): [
  null,
  boolean,
  any,
  (name: string, description: string) => Promise<ResponseData<null>>
] => {
  const { configuration } = useAppContext();
  const { logFeature } = useUsageLogger();

  const useDataSettings = { initialData: null, initialIsLoading: false };
  const [result, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const createIModel = useCallback(
    (name: string, description: string) => {
      const url = getCreateIModelUrl(configuration);
      const body = getCreateIModelBody(projectId,name, description);
      const requestData: RequestData<any> = {
        url: url,
        map: x => x,
        method: 'POST',
        body: body,
      };

      logFeature(Features.CreateIModel);
      return fetchData(requestData);
    },
    [configuration, projectId, logFeature, fetchData]
  );

  return [result, isLoading, error, createIModel];
};

const getCreateIModelUrl = (
  configuration: Configuration
) => {
  const iModelsAPIsUrl = configuration.buddiUrls.iModelsAPIsUrl;
  return `${iModelsAPIsUrl}`;
};

const getCreateIModelBody = (projectId:string, name: string, description: string) => {
  return JSON.stringify({
    "iTwinId": projectId,
    "name": name,
    "description": description,
  })
};

import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData } from '../useDataApi/requestData';
import { useCallback, useEffect, useState } from 'react';
import { v4 as newGuid } from 'uuid';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import {
  ApiVersion,
  JobDefinition,
} from '../../entities/jobDefinition/jobDefinition';
import { useToast } from '../../context/toastContext/toastContext';
import { useTranslation } from 'react-i18next';
import { JobStartStatus } from '../../entities/jobRunStatus/jobRunStatus';
import { useUserContext } from '../../context/userContext/userContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

interface RunJobResponse {
  jobId: string;
  startStatus: JobStartStatus;
}

export const useRunJob = (
  projectId: string,
  iModelId: string
): [
  RunJobResponse,
  boolean,
  any,
  (
    job: JobDefinition,
    collectDiagnostics?: boolean,
    showToasts?: boolean
  ) => Promise<boolean>
] => {
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();
  const { logFeature } = useUsageLogger();

  const useDataSettings = {
    initialIsLoading: false,
    initialData: undefined as any,
  };
  const [data, isLoadingV1, error, fetchData] =
    useDataApi<RunJobResponse>(useDataSettings);

  const [, isLoadingV2, , fetchDataV2] = useDataApi<any>(useDataSettings);
  const { toastError, toastSuccess } = useToast();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(
    isLoadingV1 || isLoadingV2
  );

  useEffect(() => {
    setIsLoading(isLoadingV2 || isLoadingV1);
  }, [isLoadingV1, isLoadingV2]);

  const runV1Job = useCallback(
    async (
      job: JobDefinition,
      collectDiagnostics: boolean = false,
      showToasts: any
    ) => {
      const url = getRunJobUrl(buddiUrls.iModelBridgeSchedulerUrl);
      const requestSettings: RequestData<any> = getRunJobRequestSettings(
        url,
        projectId,
        iModelId,
        user!.profile.sub,
        job.id,
        collectDiagnostics
      );
      logFeature(Features.JobRunStartClicked);
      const result = await fetchData(requestSettings);
      const startStatus = result.data?.startStatus;
      if (!result.ok) {
        if (showToasts) {
          if (startStatus === JobStartStatus.AlreadyRunning) {
            toastError(t('JobIsAlreadyInProgress_Toast'));
          } else {
            toastError(t('JobRunStartError_Toast'));
          }
        }
        return false;
      }

      if (startStatus === JobStartStatus.BlockedByOtherJob) {
        if (showToasts) {
          toastSuccess(t('JobRunQueued_Toast'));
        }
        return true;
      }
      if (showToasts) {
        toastSuccess(t('JobRunStarted_Toast'));
      }
      return true;
    },
    [
      buddiUrls.iModelBridgeSchedulerUrl,
      projectId,
      iModelId,
      user,
      logFeature,
      fetchData,
      toastError,
      t,
      toastSuccess,
    ]
  );

  const runV2Job = useCallback(
    async (
      job: JobDefinition,
      collectDiagnostics: boolean = false,
      showToasts: any
    ) => {
      logFeature(Features.JobRunStartClicked);
      const result = await fetchDataV2({
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${job.id}')/Services.Run?contextId=${projectId}&iModelId=${iModelId}`,
        method: 'POST',
      });
      if (!result.ok) {
        if (showToasts) {
          result.response?.status === 409
            ? toastError(t('JobStillRunningError_Toast'))
            : toastError(t('JobRunStartError_Toast'));
        }
        return false;
      }
      if (showToasts) {
        toastSuccess(t('JobRunStarted_Toast'));
      }
      return true;
    },
    [
      logFeature,
      fetchDataV2,
      buddiUrls.iModelBridgeServiceUrl,
      projectId,
      iModelId,
      toastError,
      t,
      toastSuccess,
    ]
  );

  const runJob = async (
    job: JobDefinition,
    collectDiagnostics: boolean = false,
    showToasts: boolean = true
  ) => {
    setIsLoading(true);
    const result =
      job.apiVersion === ApiVersion.v2
        ? await runV2Job(job, collectDiagnostics, showToasts)
        : await runV1Job(job, collectDiagnostics, showToasts);
    setIsLoading(false);
    return Promise.resolve(result);
  };

  return [data, isLoading, error, runJob];
};

const getRunJobUrl = (iModelBridgeSchedulerUrl: string) => {
  return `${iModelBridgeSchedulerUrl}/api/RuniModelBridgeJob`;
};

const getRunJobRequestSettings = (
  url: string,
  projectId: string,
  iModelId: string,
  userId: string,
  jobId: string,
  collectDiagnostics: boolean
) => {
  const body = JSON.stringify({
    contextId: projectId,
    iModelId: iModelId,
    userId: userId,
    jobId: jobId,
    activityId: newGuid(),
    collectDiagnostics: collectDiagnostics,
  });

  const requestSettings: RequestData<any> = {
    url: url,
    body: body,
    method: 'POST',
    map: x => x,
  };

  return requestSettings;
};

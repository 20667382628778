import { SvgClose, SvgSearch } from '@itwin/itwinui-icons-react';
import { LabeledInput } from '@itwin/itwinui-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './searchbar.scss';

export interface SearchbarProps {
  onChange: (text: string) => void;
  debounce?: boolean;
  debounceTime?: number;
}

export const Searchbar = (props: SearchbarProps) => {
  const { onChange, debounce, debounceTime = 300 } = props;
  const { t } = useTranslation();
  const [isClearable, setIsClearable] = useState(false);
  const [searchText, setSearchText] = useState('');

  const timeout = React.useRef<number>(-1);
  const triggerChange = (text: string) => {
    setIsClearable(text === '' ? false : true);
    onChange(text);
  };
  const deboucedOnChange = React.useCallback((newSearchTerm: string) => {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(async () => {
      triggerChange(newSearchTerm);
    }, debounceTime);
  }, []);

  return (
    <div className="searchbar">
      <LabeledInput
        data-testid="search-labled-input"
        placeholder={t('Search_Placeholder')!}
        displayStyle="inline"
        svgIcon={
          isClearable ? (
            <SvgClose
              data-testid="svg-clear"
              onClick={() => {
                window.clearTimeout(timeout.current);
                setSearchText('');
                triggerChange('');
              }}
            />
          ) : (
            <SvgSearch />
          )
        }
        onChange={e => {
          const targetValue: string = e.target.value;
          setSearchText(targetValue);
          debounce
            ? deboucedOnChange(targetValue.trim())
            : triggerChange(targetValue.trim());
        }}
        value={searchText}
      />
    </div>
  );
};

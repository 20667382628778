import { Text } from '@itwin/itwinui-react';
import { useTranslation } from 'react-i18next';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { AuthenticationState } from './authenticate';

export interface AuthenticateContentProps {
  authenticationState: AuthenticationState;
}

export const AuthenticateContent = (props: AuthenticateContentProps) => {
  const { authenticationState } = props;

  const { t } = useTranslation();

  switch (authenticationState) {
    case AuthenticationState.Authenticating:
      return <FullPageLoader>{t('Authentication_InProgress')}</FullPageLoader>;
    case AuthenticationState.Success:
      return (
        <>
          <Text variant="headline" as="h1">
            {t('Authentication_Success')}
          </Text>
          <Text variant="leading" as="h3" isMuted>
            {t('Authentication_Success_Desc')}
          </Text>
        </>
      );
    case AuthenticationState.Redirected:
      return <FullPageLoader>{t('Authentication_Redirecting')}</FullPageLoader>;
    default:
      throw Error('Never reached');
  }
};

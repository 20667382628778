import { RouteComponentProps } from '@reach/router';
import { UsageLoggerContextProvider } from '../../externalComponents/context/usageLoggerContext/usageLoggerContext';
import { InstantOnApplication } from './instantOnApplication';
import { LoggerContextProvider } from '../../externalComponents/context/loggerContext/loggerContext';
import { AppPermissionsBoundary } from '../../components/appPermissionsBoundary/appPermissionsBoundary';
import { useIModelContext } from '../../context/navigationContext/iModelNameContext';

export interface InstantOnApplicationWrapperProps extends RouteComponentProps {
  projectId: string;
}

export const InstantOnApplicationWrapper = (
  props: InstantOnApplicationWrapperProps
) => {
  const { projectId } = props;
  const { currentIModel } = useIModelContext();

  return (
    <UsageLoggerContextProvider>
      <LoggerContextProvider
        projectId={projectId}
        imodelId={currentIModel.id}
      >
        <AppPermissionsBoundary projectId={projectId}>
          <InstantOnApplication projectId={projectId} />
        </AppPermissionsBoundary>
      </LoggerContextProvider>
    </UsageLoggerContextProvider>
  );
};

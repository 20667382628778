import React, { useEffect, useState } from 'react';
import {
  IModelLevelPermissions,
  IModelPermissionsDto,
  IModelRoles,
  UserRoles,
} from '../../entities/rbacPermissions/rbacPermissions';
import { useGetImodelObjectType } from '../../hooks/useGetImodelObjectType/useGetImodelObjectType';
import { useGetIModelPermissions } from '../../hooks/useGetIModelPermissions/useGetIModelPermissions';
import { useGetIModelRoles } from '../../hooks/useGetIModelRoles/useGetIModelRoles';
import { useGetRbacUserRoles } from '../../hooks/useGetRbacUserRoles/useGetRbacUserRoles';
import { useBentleyContext } from '../bentleyContext/bentleyContext';
import { useUserContext } from '../userContext/userContext';

export const IModelLevelPermissionContext =
  React.createContext<IModelLevelPermissions | null>(null);

export const IModelLevelPermissionContextProvider = (props: any) => {
  const { user } = useUserContext();
  const { projectId, iModelId } = useBentleyContext();
  const [getIModelPermissions] = useGetIModelPermissions();
  const [getRbacUserRoles] = useGetRbacUserRoles();
  const [getImodelObjectType] = useGetImodelObjectType();
  const [getIModelRoles] = useGetIModelRoles();
  const [iModelHubPermissions, setIModelHubPermissions] =
    useState<IModelLevelPermissions | null>(null);

  const readIModelPermissions = async () => {
    const iModelPermissions = await getIModelPermissions(iModelId);
    if (iModelPermissions == null || iModelPermissions.length === 0) {
      return null;
    }

    const roles = await getRbacUserRoles(projectId);
    const objectType = await getImodelObjectType();
    const imodelRoles = await getIModelRoles(objectType);

    const filteredIModelPermissions = iModelPermissions.filter(
      (permission: IModelPermissionsDto) =>
        roles?.find(
          (role: UserRoles) => role.instanceId === permission.relatedInstanceId
        )
    );
    const filteredIModelRoles = imodelRoles
      ? imodelRoles.filter((role: IModelRoles) =>
          filteredIModelPermissions.find(
            (permission: IModelPermissionsDto) =>
              role.instanceId === permission.objectRoleId
          )
        )
      : [];
    const permissions = filteredIModelRoles.map(
      (role: IModelRoles) => role.relationshipInstances
    );

    const p = {
      canReadIModelLevelPermission: permissions.some((p: [string]) =>
        p.includes('IMHS_Read_iModel')
      ),
      canModifyIModelLevelPermission: permissions.some((p: [string]) =>
        p.includes('IMHS_Modify_iModel')
      ),
      canDeleteIModelLevelPermission: permissions.some((p: [string]) =>
        p.includes('IMHS_Delete_iModel')
      ),
      canManageIModelLevelPermission:
        permissions.some((p: [string]) => p.includes('IMHS_ManageResources')) ||
        permissions.some((p: [string]) => p.includes('IMHS_Manage_iModel')),
    };

    return {
      canReadIModelLevelPermission:
        p.canReadIModelLevelPermission ||
        p.canModifyIModelLevelPermission ||
        p.canDeleteIModelLevelPermission ||
        p.canManageIModelLevelPermission,
      canModifyIModelLevelPermission:
        p.canModifyIModelLevelPermission || p.canManageIModelLevelPermission,
      canDeleteIModelLevelPermission: p.canDeleteIModelLevelPermission,
      canManageIModelLevelPermission: p.canManageIModelLevelPermission,
    };
  };
  useEffect(() => {
    if (iModelId && user?.access_token) {
      readIModelPermissions().then(response => {
        setIModelHubPermissions(response);
      });
    }
  }, [iModelId, user]);

  return (
    <IModelLevelPermissionContext.Provider
      value={{ ...iModelHubPermissions }}
      {...props}
    />
  );
};
export const useIModelPermissionContext = () => {
  const context = React.useContext(IModelLevelPermissionContext);
  if (context == null) {
    throw new Error('permissionContext must be used inside provider');
  }
  return context;
};

import { Item } from '../../entities/item';

export const sortItems = (itemArray: Item[]) => {
  const sorted = [...itemArray].sort(sortItemComparator);
  return sorted;
};

export const sortItemComparator = (a: Item, b: Item, desc?: boolean) => {
  if (a.itemType !== b.itemType) {
    return a.isFolder ? (desc ? 1 : -1) : desc ? -1 : 1;
  }
  if (a.name !== b.name) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  } else {
    return a.version > b.version ? 1 : -1;
  }
};

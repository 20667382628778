import { mergeWithGlobalQueryParams } from '../uiUtils/navigation';

export const getRunJobAuthenticationCallbackUrl = (
  projectId: string,
  iModelId: string,
  jobId: string
) =>
  new URL(
    mergeWithGlobalQueryParams(
      `/${projectId}/${iModelId}/runJobAuthCallback?jobId=${jobId}`
    ),
    document.location.origin
  ).href;

export const getJobPageAuthenticationCallbackUrl = (
  projectId: string,
  iModelId: string,
  jobId: string
) =>
  new URL(
    mergeWithGlobalQueryParams(`/${projectId}/${iModelId}/${jobId}`),
    document.location.origin
  ).href;

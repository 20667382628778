import React, { useEffect, ReactElement, useState } from 'react';
import { useCheckUserHasProjectAccess } from '../../hooks/useCheckUserHasProjectAccess/useCheckUserHasProjectAccess';
import { Redirect } from '@reach/router';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { Project } from '../../externalComponents/entities/project';
import { useProjectContext } from '../../context/projectContext/projectContext';
import { useTranslation } from 'react-i18next';
import { ErrorProps } from '../../externalComponents/typedef';

interface ErrorBoundaryProps extends React.HTMLAttributes<HTMLDivElement> {
  projectId: string;
}

export const AppPermissionsBoundary = (props: ErrorBoundaryProps) => {
  const { projectId, children } = props;
  const [, , , checkUserHasProjectAccess] =
    useCheckUserHasProjectAccess(projectId);
  const [errorState, setErrorState] = useState<ErrorProps | null>(null);
  const [showPage, setShowPage] = useState(false);
  const { t } = useTranslation();
  const { currentProject, setCurrentProject } = useProjectContext();

  useEffect(() => {
    const checkProjectPermission = async () => {
      const result = await checkUserHasProjectAccess();
      if (result && result.data && result?.data![0]) {
        setCurrentProject(result.data![0] as Project);
        setShowPage(true);
      } else {
        setErrorState({
          errorType: '403',
          errorMessage: t('NoProjectPermission_ErrorMessage'),
        });
      }
    };
    checkProjectPermission();
  }, [checkUserHasProjectAccess, t]);

  useEffect(() => {
    if (currentProject == null) {
      setErrorState({
        errorType: '403',
        errorMessage: t('Forbidden_ErrorMessage'),
      });
    }
  }, [currentProject, t]);

  return errorState ? (
    <Redirect to="/errorPage" state={errorState} noThrow />
  ) : showPage ? (
    (children as ReactElement)
  ) : (
    <FullPageLoader>{t('FullPage_Loading_Message')}</FullPageLoader>
  );
};

import React, { useState } from 'react';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { useInputField } from '../../hooks/useInputField/useInputField';
import { useTranslation } from 'react-i18next';
import { useJobNameValidation } from '../../hooks/useJobNameValidation/useJobNameValidation';
import {
  Button,
  Modal,
  ModalButtonBar,
  LabeledInput,
} from '@itwin/itwinui-react';
import { ModalProps } from '../../hooks/useModal/useModal';

type RenameJobModalProps = ModalProps & {
  job: JobDefinition;
  jobs: JobDefinition[];
  renameJobName: (job: JobDefinition, newName: string) => any;
  closeModal: () => void;
};
export const RenameJobModal = (props: RenameJobModalProps) => {
  const { job, jobs, closeModal, renameJobName, ...modalProps } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [jobName, setJobName] = useState(job.name);

  const [
    jobNameStatus,
    jobNameErrorMessage,
    isJobNameInputInvalid,
    setJobNameInputField,
  ] = useInputField();
  const { validate } = useJobNameValidation(
    jobs.map(job => job.name),
    setJobNameInputField
  );
  const { t } = useTranslation();

  const renameJob = async () => {
    setIsLoading(true);
    await renameJobName(job, jobName);
    setIsLoading(false);
  };

  const close = () => {
    setJobNameInputField(undefined, '');
    closeModal();
  };

  return (
    <Modal {...modalProps} onClose={close} title={t('RenameJobModal_Title')}>
      <>
        <LabeledInput
          label={t('JobNameInputField_Label')}
          value={jobName}
          data-testid="jobName-input"
          onChange={(e: any) => {
            validate(e.target.value);
            setJobName(e.target.value);
          }}
          status={jobNameStatus}
          message={jobNameErrorMessage}
        />
        <ModalButtonBar>
          <Button
            onClick={renameJob}
            styleType="high-visibility"
            disabled={isJobNameInputInvalid || isLoading}
          >
            {t('RenameBtn_Label')}
          </Button>
          <Button onClick={close}>{t('CancelBtn_Label')}</Button>
        </ModalButtonBar>
      </>
    </Modal>
  );
};
